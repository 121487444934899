/** @format */

import React, { useState, useEffect } from "react";

const Edit_user = ({ userDetails, onClose }) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/user/getRoles`)
      .then((response) => response.json())
      .then((data) => setRoles(data))
      .catch((error) => console.error("Error fetching roles:", error));
  }, []);

  const [formData, setFormData] = useState({
    id: "",
    employee_no: "",
    username: "",
    employee_status: "",
    role: "",
  });

  useEffect(() => {
    console.log("userdetails:", userDetails);
    if (userDetails) {
      setFormData({
        id: userDetails.id,
        employee_no: userDetails.employee_no,
        username: userDetails.username,
        employee_status: userDetails.employee_status,
        role: userDetails.user_role,
      });
      setSelectedRole(userDetails.user_role);
    }
  }, [userDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setFormData({ ...formData, role: e.target.value }); // Update role in form data
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform update action here
    console.log("Updating user:", formData);
    onClose();
  };

  return (
    <div className="bg-white p-1 rounded-[30px]">
      <div className="mb-8">
        <p className="text-[36px] font-semibold ">Edit User</p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 grid-flow-row gap-x-[60px] gap-y-[15px] ">
          <div>
            <div>
              <label className="font-semibold">ID*</label>
            </div>
            <div>
              <input
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                name="id"
                value={formData.id}
                onChange={handleInputChange}
                disabled
              />
            </div>
          </div>

          <div>
            <div>
              <label className="font-semibold">Employee No*</label>
            </div>
            <div>
              <input
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                type="text"
                name="employee_no"
                value={formData.employee_no}
                disabled
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div>
            <div>
              <label className="font-semibold">Username*</label>
            </div>
            <div>
              <input
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div>
            <div>
              <label className="font-semibold">User Role*</label>
            </div>
            <div>
              <select
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                value={selectedRole}
                onChange={handleRoleChange}
              >
                <option value="" disabled>
                  Select Role
                </option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.role_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-3">
            <button
              className="bg-primary p-2 rounded-[15px] text-white font-semibold mr-4"
              type="submit"
            >
              Update
            </button>
            <button
              type="cancel"
              className="bg-red-500 text-white font-bold p-2 rounded-[20px]"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Edit_user;
