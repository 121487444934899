import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { IoCalendarOutline } from "react-icons/io5";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import cloudiccon from "../../../../Assets/icons/cloud-add.png";

const IdGenerate = (name) => {
  const timestamp = Date.now().toString(); // Get current timestamp
  const random = Math.random().toString().substr(2, 4); // Get 4 random digits
  const uniqueID = timestamp.substr(-4) + random; // Combine timestamp and random digits
  const id = `${name}-${uniqueID}`; // Combine name and unique ID
  return id;
};

const Create_A_Tender = () => {
  const [proIt, setproIt] = useState("");
  const [cat, setcat] = useState("");
  const [TenId, setTenId] = useState(IdGenerate("Tender"));
  const [conId, setconId] = useState("");
  const [tName, settName] = useState("");
  const [DeadName, setDeadName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const { REACT_APP_API_ENDPOINT } = process.env;

  const [files, setFiles] = useState({});
  const addFile = (file) => {
    const newFiles = { ...files };
    newFiles[file.name] = file;
    setFiles(newFiles);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("tender_id", TenId);
      formData.append("procurement_item", proIt);
      formData.append("contract_id", conId);
      formData.append("category", cat);
      formData.append("tender_name", tName);
      formData.append("deadline_name", DeadName);
      formData.append("deadline_date", startDate.toLocaleDateString("en-GB"));
      formData.append("tender_create_user_id", "EMP-0001");

      // Add files to the formData
      Object.values(files).forEach((file) => {
        formData.append("tender_document", file);
      });

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Tender successfully added");
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add tender");
    }
  };
  const handleCancel = () => {
    setFiles({});
  };
  const handleFileInputChange = (e) => {
    const fileList = e.target.files;
    for (const file of fileList) {
      addFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const fileList = e.dataTransfer.files;
    for (const file of fileList) {
      addFile(file);
    }
  };

  // Reset function
  const handleReset = () => {
    setconId("");
    setcat("");
    setTenId("");
    setproIt("");
    settName("");
    setDeadName("");
    setStartDate("");
    setFiles("");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div className="bg-white min-w-full min-h-16 flex justify-end items-center">
        <div className="bg-[#D6E5C6] min-w-[44px] min-h-[44px] rounded-full"></div>
        <p className=" text-[16px] text-[#071C50] font-bold ml-4 mr-4">
          Sahansa
        </p>
      </div>
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Create Tender
        </p>
      </div>
      <div className="square absolute ml-6 mt-[1%] w-full sm:w-4/5 rounded-[46px] border border-black bg-[#F3F8FF]">
        <div className="flex flex-wrap ml-6 mt-6 text-[23px] font-bold">
          <div className="w-1/2 pr-4 mt-6 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Tender ID:
            </label>
            <input
              placeholder="xxxxxx"
              type="text"
              disabled
              value={TenId}
              onChange={(e) => setTenId(e.target.value)}
              className="border border-gray-300 rounded-lg px-2  w-2/6 text-[23px] font-bold "
            />
          </div>

          <div className="w-1/2 pr-4 mt-6 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Procurement Item:
            </label>
            <input
              placeholder="xxxxxxx"
              type="text"
              value={proIt}
              onChange={(e) => setproIt(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 w-2/6"
            />
          </div>

          <div className="w-1/2 pr-4 mt-6 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Contact ID:
            </label>
            <input
              type="text"
              value={conId}
              onChange={(e) => setconId(e.target.value)}
              className="border border-gray-300 rounded-lg px-2  w-2/6"
            />
          </div>

          <div className="w-1/2 pl-4 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Category:
            </label>
            <select
              value={cat}
              onChange={(e) => setcat(e.target.value)}
              className="border border-gray-300 rounded-lg px-2  w-2/6"
            >
              <option value="">Select To</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
              <option value="Option 3">Option 3</option>
            </select>
          </div>

          <div className="w-1/2 pr-4 mt-6 flex items-center ">
            <label className=" mr-32 text-black font-poppins font-bold text-xl min-w-[140px]">
              Tender Name:
            </label>
            <input
              type="text"
              value={tName}
              onChange={(e) => settName(e.target.value)}
              className="border border-gray-300 rounded-lg px-2  w-full "
            />
          </div>
        </div>
      </div>
      <div>
        {/* Add deadline part */}
        <p className="text-[40px] font-sans ml-6 mt-[19%] font-bold text-[#656565] ">
          Add Deadline
        </p>
      </div>
      <div className="square absolute ml-6 mt-[1%] w-full sm:w-4/5 rounded-[46px] min-h-16 border border-black bg-[#F3F8FF]">
        <div className="flex flex-wrap ml-6 mt-2">
          <div className="w-1/2 pr-4 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Deadline Name:
            </label>
            <input
              type="text"
              value={DeadName}
              onChange={(e) => setDeadName(e.target.value)}
              placeholder="Enter Deadline Name"
              className="border border-gray-300 rounded-lg px-2 w-2/6 text-[23px] font-bold"
            />
          </div>
          <div className="w-1/2 pr-4 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Date:
            </label>
            <DatePicker
              showIcon
              icon={<IoCalendarOutline className="right-0" />}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
        </div>
      </div>
      <div className=" absolute right-6">
        <button
          // onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl mt-6 py-2 px-4 rounded-[22px]"
        >
          Add
        </button>
      </div>

      {/* Upload Document part */}
      <p className="text-[40px] font-sans ml-6 mt-[8%] font-bold text-[#656565] ">
        Upload Tender Document
      </p>

      <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[40%] h-[80%] flex-shrink-0 mt-5 ml-6">
        <main className="container mx-auto max-w-screen-lg h-full">
          {/* File upload modal */}
          <article className="relative h-full flex flex-col rounded-md">
            {/* Scroll area */}
            <section className="h-full overflow-auto p-8 w-full flex flex-col">
              <div className="flex items-center">
                <img
                  src={cloudiccon}
                  alt="Cloud Icon"
                  className="h-10 w-10 rounded-full border border-gray-600"
                />

                <div>
                  <p className="text-gray-800 font-inter font-medium text-lg ml-2">
                    Upload files
                  </p>
                  <p className="text-dialogSubmenu-500 text-gray-400 font-inter font-medium text-sm ml-2">
                    Select and upload the files of your choice
                  </p>
                </div>
              </div>
              <hr className="  w-[100%] border-solid border-b-2 border-grayColor mt-2"></hr>

              <header
                className="border-dashed border-2 bg-dialogboxFileup border-gray-400 py-12 flex flex-col justify-center items-center mt-2 rounded-[26px]"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center items-center flex-col">
                  <div>
                    <img
                      src={cloudiccon}
                      alt="Cloud Icon"
                      className="h-10 w-10 rounded-full"
                    />
                  </div>
                  <span>Choose a PDF file or drag & drop it here</span>
                  &nbsp;
                  <span className="text-gray-400">files anywhere or</span>
                </p>
                <input
                  id="hidden-input"
                  type="file"
                  multiple
                  className="hidden"
                  onChange={handleFileInputChange}
                />
                <button
                  id="button"
                  className="mt-2 rounded-[16px] px-3 py-1 bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                  onClick={() =>
                    document.getElementById("hidden-input").click()
                  }
                >
                  Browse File
                </button>
              </header>

              {/* Uploaded files list */}
              <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                Uploaded Files
              </h1>

              <ul className="flex flex-1 flex-wrap -m-1">
                {Object.values(files).map((file, index) => (
                  <li
                    key={index}
                    className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
                  >
                    <article
                      tabIndex="0"
                      className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                    >
                      <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                        <h1 className="flex-1 group-hover:text-blue-800">
                          {file.name}
                        </h1>
                        <div className="flex">
                          <span className="p-1 text-blue-800">
                            <i>
                              <svg
                                className="fill-current w-4 h-4 ml-auto pt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                              </svg>
                            </i>
                          </span>
                          <p className="p-1 size text-xs text-gray-700">
                            {file.size > 1024
                              ? file.size > 1048576
                                ? Math.round(file.size / 1048576) + "mb"
                                : Math.round(file.size / 1024) + "kb"
                              : file.size + "b"}
                          </p>
                        </div>
                      </section>
                    </article>
                  </li>
                ))}
              </ul>
            </section>
          </article>
        </main>
      </div>
      <div className="absolute ml-6 space-x-4 mt-6">
        <button
          onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Create_A_Tender;
