import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PDF from "../../../../src/Assets/icons/png.png";

const LeaveInfo = () => {
  const location = useLocation();
  const { leaveType } = location.state || { leaveType: "" };
  const [leaveData, setLeaveData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  console.log("Received leaveType:", leaveType);

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/leave/getleave`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);
        const normalizedLeaveType = leaveType
          .toLowerCase()
          .replace(/-/g, " ")
          .replace(/leaves?/g, "leave")
          .trim();
        const filteredData = data.filter(
          (item) =>
            item.leave_type
              .toLowerCase()
              .replace(/-/g, " ")
              .replace(/leaves?/g, "leave")
              .trim() === normalizedLeaveType
        );
        console.log("Filtered Data:", filteredData); // Check filtered results
        setLeaveData(filteredData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [leaveType]);

  const downloadLeaveFile = (leave_file_path) => {
    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${leave_file_path}`;
    window.open(downloadUrl, "_blank");
  };

  return (
    <div className="bg-background h-screen overflow-y-auto font-sans">
      <div className="text-[28px] mt-6 ml-6 font-semibold text-headerColor">
        {leaveType} Reports
      </div>

      <div>
        <div className="flex justify-between">
          <div>
            <div>
              <div className="flex flex-col">
                <label
                  htmlFor="nic_number"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                ></label>
                <select
                  id={``}
                  name={``}
                  autoComplete=""
                  className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                >
                  <option className="font-sans-bold ">Department</option>
                  <option>Dep 01</option>
                  <option>Dep 02</option>
                  <option>Dep 03</option>
                </select>
              </div>
            </div>
            <div className="mt-3">
              <div>
                <div className="flex flex-col">
                  <label
                    htmlFor="nic_number"
                    className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                  ></label>
                  <select
                    id={``}
                    name={``}
                    autoComplete=""
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  >
                    <option className="font-sans-bold ">All</option>
                    <option>Training #</option>
                    <option>Training #</option>
                    <option>Training #</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
              <form className="form relative">
                <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                  <svg
                    className="w-5 h-5 text-gray-700"
                    aria-labelledby="search"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    height="16"
                    width="17"
                  >
                    <path
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      strokeWidth="1.333"
                      stroke="currentColor"
                      d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                    ></path>
                  </svg>
                </button>
                <input
                  type="text"
                  required=""
                  placeholder="Search..."
                  className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                />
              </form>
            </div>
          </div>
          <div className="mr-[50px]">
            <div className="bg-white w-[350px] h-[150px] rounded-[30px] ">
              <div>
                <p className="text-headerColor font-semibold text-[30px] ml-6 mt-6">
                  {leaveType}{" "}
                </p>
                <p className="ml-6 mt-1 font-semibold">(per month)</p>
              </div>

              <div className="flex justify-between items-center ">
                <div className="ml-5 mt-3">
                  <div className="bg-primary text-white p-2 w-[50px] flex justify-center items-center rounded-[10px] ">
                    24
                  </div>
                </div>
                <div>
                  <div className="bg-gray-300 p-2 w-[60px] rounded-[20px]  mr-5">
                    View
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%] rounded-2xl border-2">
          <thead>
            <tr className="bg-white rounded-xl">
              <th className="text-primary px-4 py-2 w-[16%]">EMP ID</th>
              <th className="text-primary px-4 py-2 w-[32%] text-start">
                Requested Date
              </th>
              <th className="text-primary px-4 py-2 w-[16%]">
                Submitted Document
              </th>
              <th className="text-primary px-4 py-2 w-[16%]">Approve 01</th>
              <th className="text-primary px-4 py-2 w-[16%]">Approve 02</th>
            </tr>
          </thead>
          <tbody>
            {leaveData.length > 0 ? (
              leaveData.map((item) => (
                <tr key={item.id}>
                  <td className="bg-white px-4 py-2">{item.employee_no}</td>
                  <td className="bg-white px-4 py-2 text-start">
                    {item.requested_date}
                  </td>
                  <td
                    className="bg-white px-4 py-2 flex items-center"
                    onClick={() => downloadLeaveFile(item.leave_file_path)}
                  >
                    {item.leave_file_name ? (
                      <>
                        <img
                          src={PDF}
                          alt="pdf-icon"
                          className="w-8 h-8 mr-2"
                        />
                        {item.leave_file_name}
                      </>
                    ) : (
                      "No document"
                    )}
                  </td>

                  <td className="bg-white px-4 py-2">
                    {item.approved_status_1}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {item.approved_status_2}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaveInfo;
