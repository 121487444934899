import React, { useState, useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";

const PopupBox = ({ onClose, fetchdata }) => {
  const [employee, setEmployee] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [involeemployeeData, setInvoleemployeeData] = useState([]);
  const [deleteIndexEmployee, setDeleteIndexEmployee] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    // Fetch employee list from the server
    async function fetchEmployeeList() {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/performance/examination/getemployee`
        );
        const data = await response.json();
        setEmployeeList(data);
      } catch (error) {
        console.error("Failed to fetch employee list:", error);
      }
    }
    fetchEmployeeList();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleEmployeeChange = (e) => {
    setEmployee(e.target.value);
  };

  const handleAddEmployee = () => {
    const selected = employeeList.find((emp) => emp.employee_no === employee);
    if (selected && !selectedEmployees.includes(selected)) {
      setSelectedEmployees([...selectedEmployees, selected]);
    }
    setEmployee("");
  };

  const handleAssignEmployee = () => {
    setInvoleemployeeData([...involeemployeeData, ...selectedEmployees]);
    setSelectedEmployees([]);
  };

  const handleDeleteEmployee = async (id) => {
    // Remove the employee from the list (placeholder URL)
    try {
      await fetch(`https://api.example.com/employees/${id}`, {
        method: "DELETE",
      });
      setInvoleemployeeData((prevData) =>
        prevData.filter((employee) => employee._id !== id)
      );
      setDeleteIndexEmployee(null);
    } catch (error) {
      console.error("Failed to delete employee:", error);
    }
  };

  const [formData, setFormData] = useState({
    examination_name: "",
    examination_time: "",
    examination_date: "",
    examination_purpose: "",
    examination_candidates: [],
  });

  const handleSubmit = async () => {
    try {
      const examination_candidates = involeemployeeData.map(
        (employee) => employee.employee_no
      );
      const updatedFormData = {
        ...formData,
        examination_candidates,
      };

      console.log("Form Data:", updatedFormData);
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/examination/addexamination`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFormData),
        }
      );

      const responseData = await response.json(); // Parse the JSON response

      if (response.ok) {
        console.log("Examination added successfully!");
        alert("Examination added successfully!");
        onClose(); // Close the popup
      } else {
        console.error("Failed to add Examination:", response.status);
        // Check if the error message is about the examination name already existing
        if (
          responseData.error &&
          responseData.error === "Examination name already exists"
        ) {
          alert("Error: Examination name already exists!");
        } else {
          alert("Failed to add Examination. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error adding Examination:", error);
      alert(
        "An error occurred while adding the examination. Please try again."
      );
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6">
        <p className="font-sans text-left text-[26px] font-bold mb-4 text-[#797C80]">
          Add Examination Details{" "}
          <hr className="line border-t border-gray-300 w-[35%]" />
        </p>

        {/* Form */}
        <div>
          <div className="grid grid-cols-2 gap-[80px]">
            {/* Section 01 */}
            <div>
              <label className="font-sans font-semibold">
                Examination Name
              </label>
              <div className="mb-5">
                <input
                  className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  type="text"
                  id="examination_name"
                  value={formData.examination_name}
                  onChange={handleChange}
                />
              </div>

              <div className="mt-5">
                <label className="font-sans font-semibold">Time</label>
                <div>
                  <input
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    type="time"
                    id="examination_time"
                    value={formData.examination_time}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* Section 02 */}
            <div>
              <div className="gap-3 font-sans font-semibold">
                <div>
                  <p>Dates</p>
                </div>
                <div>
                  <input
                    type="date"
                    id="examination_date"
                    value={formData.examination_date}
                    onChange={handleChange}
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  />
                </div>
              </div>

              <div className="mt-[25px]">
                <label className="font-sans font-semibold">Purpose</label>
                <div>
                  <input
                    className="w-[100%] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    type="text"
                    id="examination_purpose"
                    placeholder="Enter purpose"
                    value={formData.examination_purpose}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Employee Section */}
          <div className="grid grid-flow-row-dense grid-cols-5 overflow-y-auto mt-5">
            <div className="col-span-3">
              <div className="overflow-y-auto max-h-96 mb-4">
                <label className="block mb-2 font-semibold">
                  Add Examination Candidates
                </label>
                <div className="flex flex-row items-center">
                  <select
                    className="border border-gray-300 rounded-md px-3 py-1 w-[50%]"
                    value={employee}
                    onChange={handleEmployeeChange}
                  >
                    <option value="">Select Candidates</option>
                    {employeeList.map((employee) => (
                      <option key={employee.id} value={employee.employee_no}>
                        {employee.employee_no}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={handleAddEmployee}
                    className="bg-primary text-white h-[30px] w-[70px] rounded-md mr-2 ml-5"
                  >
                    Add
                  </button>
                </div>
                <div>
                  {selectedEmployees.length > 0 && (
                    <>
                      <label className="block mb-2 font-semibold">
                        Selected Employees
                      </label>
                      <div>
                        {selectedEmployees.map((selectedEmployee) => (
                          <div
                            key={selectedEmployee.id}
                            className="bg-primary text-white h-[30px] w-[90px] rounded-md mt-1 ml-5"
                          >
                            {" "}
                            {/* Use unique identifier */}
                            <div className="flex items-center justify-center p-1">
                              {selectedEmployee.employee_no}
                              <IoMdCloseCircle
                                onClick={() =>
                                  setSelectedEmployees((prevEmployees) =>
                                    prevEmployees.filter(
                                      (_, i) =>
                                        i !==
                                        selectedEmployees.indexOf(
                                          selectedEmployee
                                        )
                                    )
                                  )
                                }
                                className="text-red-600 cursor-pointer ml-2"
                                size={20}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <button
                  onClick={handleAssignEmployee}
                  className="bg-primary text-white px-4 py-2 rounded-md mr-2 mt-5"
                >
                  Assign Candidates
                </button>
              </div>
            </div>
            <div className="col-span-2">
              <div>
                <label className="block mb-2 font-semibold">
                  Assigned Candidates
                </label>
                <div className="w-full max-w-md rounded-lg p-6">
                  <div>
                    {involeemployeeData.map((employeeData) => (
                      <div
                        key={employeeData.id}
                        className="bg-[#99BC85] mt-1 text-white h-[30px] w-[90px] rounded-md mr-2 ml-5"
                      >
                        <div className="ml-2">{employeeData.employee_no}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {deleteIndexEmployee !== null && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-md shadow-lg">
                <p className="mb-4">
                  Are you sure you want to remove this Employee?
                </p>
                <div className="flex justify-end">
                  <button
                    onClick={() =>
                      handleDeleteEmployee(
                        involeemployeeData[deleteIndexEmployee].id
                      )
                    }
                    className="bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => setDeleteIndexEmployee(null)}
                    className="bg-gray-300 px-4 py-2 rounded-md"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div className="flex justify-end items-center">
          <button
            onClick={onClose}
            className="font-bold py-1 mr-3 px-4 mt-4 rounded-[32px] text-black"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-[#5B6D49] mt-4 p-1 w-[4rem] text-white rounded-[20px]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupBox;
