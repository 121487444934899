import React, { useState } from "react";
import { LuMaximize2 } from "react-icons/lu";

const DialogNewMail = ({ onClose }) => {
  const [maximized, setMaximized] = useState(false);
  const [italic, setItalic] = useState(false);
  const [fontWeight, setFontWeight] = useState("normal");
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const emojis = ["😊", "🔥", "⚡️", "❤️", "🏆", "✨", "🎉", "💰", "💵", "💸"];

  // Define a mapping between file extensions and corresponding icons
  const fileIcons = {
    pdf: "📄",
    doc: "📄",
    docx: "📄",
    xls: "📊",
    xlsx: "📊",
    ppt: "📝",
    pptx: "📝",
    jpg: "🖼️",
    jpeg: "🖼️",
    png: "🖼️",
    gif: "🖼️",
    txt: "📝",
    zip: "📦",
    rar: "📦",
    mp3: "🎵",
    wav: "🎵",
    mp4: "🎥",
    mov: "🎥",
  };

  const handleMaximize = () => {
    setMaximized(!maximized);
  };

  const handleBoldClick = () => {
    setFontWeight(fontWeight === "bold" ? "normal" : "bold");
  };

  const handleItalicClick = () => {
    setItalic(!italic);
  };

  const handleEmojiClick = (emoji) => {
    setSelectedEmoji(emoji);
    setMessage(message + emoji); // Append the selected emoji to the message
    setEmojiPickerOpen(false);
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFileInputChange = (e) => {
    setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)]);
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    return fileIcons[extension] || "📎"; // Default icon for unknown file types
  };

  const inputAreaStyle = {
    height: maximized ? "64%" : "45%",
  };

  const dialogStyle = {
    width: maximized ? "80%" : "40%",
    height: maximized ? "95%" : "65%",
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-55 fixed inset-0"></div>
      <div className="bg-white rounded-lg z-10" style={dialogStyle}>
        <div className="bg-[#424242] min-h-10 rounded-t-lg flex justify-between items-center">
          <div>
            <h1 className="text-white font-poppins font-semibold ml-2">
              New Message
            </h1>
          </div>
          <div className="mr-10 mb-6">
            <div className="flex justify-between items-center">
              <button
                onClick={onClose}
                className="absolute flex justify-center border-none rounded-[22px] w-[30.359px] h-[28px] flex-shrink-0 ml-6 mt-4"
              >
                <span className="text-white text-xl">X</span>
              </button>
              <button onClick={handleMaximize}>
                <LuMaximize2 className="mt-4" color="white" />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-2 ml-2" style={inputAreaStyle}>
          <input
            placeholder="Recipients"
            className="min-w-[99%] px-2 py-1 border rounded focus:outline-none focus:border-blue-400"
          ></input>
          <input
            placeholder="Subject"
            className="min-w-[99%] px-2 py-1 border rounded focus:outline-none focus:border-blue-400 mt-2"
          ></input>
          <p className="font-medium mt-2">Body Text</p>

          <textarea
            placeholder="Type your message here..."
            className="w-full h-full mt-2 p-2 border rounded focus:outline-none focus:border-blue-400"
            value={message}
            onChange={handleInputChange}
            style={{
              fontWeight: fontWeight === "bold" ? "bold" : "normal",
              fontStyle: italic ? "italic" : "normal",
            }}
          ></textarea>
          <div className="flex gap-2 mt-2">
            <button className="bg-[#8E9C7F] rounded-md min-w-[5%] text-white px-2 py-1 mt-2">
              Send
            </button>
            <button
              onClick={handleBoldClick}
              className={`px-3 py-1 border rounded ${
                fontWeight === "bold" ? "bg-blue-600 text-white" : ""
              }`}
            >
              B
            </button>
            <button
              onClick={handleItalicClick}
              className={`px-3 py-1 border rounded ${
                italic ? "bg-blue-600 text-white" : ""
              }`}
            >
              I
            </button>
            <div className="relative inline-block text-left">
              <button
                onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                type="button"
                className="w-[2.5rem] h-[2.5rem] rounded-full flex items-center justify-center bg-gray-200 hover:bg-gray-300 focus:outline-none"
                id="emoji-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                {"😊"}
              </button>
              {emojiPickerOpen && (
                <div className="origin-bottom-right absolute right-0 bottom-full mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="grid grid-cols-5 gap-2 p-4">
                    {emojis.map((emoji, index) => (
                      <button
                        key={index}
                        onClick={() => handleEmojiClick(emoji)}
                        className="text-lg text-center text-gray-800 hover:text-gray-900 focus:outline-none"
                      >
                        {emoji}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div>
                <label
                  htmlFor="file-input"
                  className="bg-gray-200 text-gray-600 hover:bg-gray-300 py-2 px-4 rounded-full cursor-pointer"
                >
                  <input
                    type="file"
                    id="file-input"
                    className="hidden"
                    onChange={handleFileInputChange}
                    multiple
                  />
                  <svg
                    className="w-6 h-6 mr-1 inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  Attach Files
                </label>
              </div>
              <div className="flex items-center">
                {/* Display selected file icons */}
                {selectedFiles.length > 0 && (
                  <div className="flex items-center">
                    {selectedFiles.map((file, index) => (
                      <div key={index} className="mr-2">
                        {getFileIcon(file.name)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogNewMail;
