import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import Apply_Leave from "./apply_leave";
import EditLeave from "./update_leave";
import PDF from "../../../../Assets/icons/png.png"; // Assuming you have a PDF icon image
import { FcRefresh } from "react-icons/fc";

const ConfirmationModal = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
      <div className="relative z-50 w-auto max-w-sm mx-auto my-6">
        <div className="relative bg-white border-0 rounded-[30px] shadow-lg outline-none focus:outline-none">
          <div className="relative p-6">
            <div>
              <button
                className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 mt-3 mr-3 rounded-[20px]"
                onClick={onCancel} // Call handleClosePopup to close the popup
              >
                X
              </button>
            </div>
            <div className="flex justify-center">
              <div className="bg-primary w-[48px] h-[11px] rounded-[22px]"></div>
            </div>
            <p className="my-4 text-blueGray-500 text-[30px] text-headerColor leading-relaxed flex justify-center font-bold">
              {message}
            </p>
          </div>
          <div className="flex items-center justify-center gap-[100px] p-6">
            <div>
              <button
                className="text-black bg-[#E2E2E2] rounded-[22px] font-bold  px-6 py-2 text-[22px] outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onCancel}
              >
                No
              </button>
            </div>
            <div>
              <button
                className="bg-[#E0EEDF] text-black active:bg-green-600 font-bold text-[22px] px-6 py-2 rounded-[22px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Leave() {
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // You can adjust the number of items per page here
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submittedFiles, setSubmittedFiles] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of the item being deleted
  const [leaveData, setLeaveData] = useState([]);
  const [editLeaveId, setEditLeaveId] = useState(null); // State to track the leave ID for editing
  const [deleteMode, setDeleteMode] = useState("reclaim"); // State to track delete mode (reclaim or not)

  const downloadDisciplinaryFile = (leave_file_path) => {
    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${leave_file_path}`;
    window.open(downloadUrl, "_blank");
  };

  const handleDeleteClick = (index) => {
    setShowConfirmationModal(true);
    setDeleteIndex(index);
    setDeleteMode("delete");
  };

  const handleReclaimClick = (index) => {
    setShowConfirmationModal(true);
    setDeleteIndex(index);
    setDeleteMode("reclaim");
  };

  const handleDeleteConfirmed = async () => {
    const leaveId = leaveData[deleteIndex].id;

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/leave/deleteleave`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: leaveId }),
        }
      );

      if (response.ok) {
        const newData = [...leaveData];
        newData.splice(deleteIndex, 1); // Remove the item from the newData array
        setLeaveData(newData); // Update the state with the modified newData array
      } else {
        console.error("Error deleting leave entry:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting leave entry:", error);
    }

    setShowConfirmationModal(false); // Close the confirmation modal
  };

  const handleReclaimConfirmed = async () => {
    const leaveId = leaveData[deleteIndex].id;

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/leave/reclaimleave`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: leaveId }),
        }
      );

      if (response.ok) {
        const newData = [...leaveData];
        newData.splice(deleteIndex, 1); // Remove the item from the newData array
        setLeaveData(newData); // Update the state with the modified newData array
      } else {
        console.error("Error reclaiming leave entry:", response.statusText);
      }
    } catch (error) {
      console.error("Error reclaiming leave entry:", error);
    }

    setShowConfirmationModal(false); // Close the confirmation modal
  };

  const [editIndex, setEditIndex] = useState(null); // Step 2: Create state to track edit mode
  const [editLeavePopupOpen, setEditLeavePopupOpen] = useState(false);

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file && file.type !== "application/pdf") {
      setShowAlertMessage(true);
    } else {
      setShowAlertMessage(false);
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles[index] = file;
      setSelectedFiles(newSelectedFiles);
    }
  };

  const handleSubmit = (index) => {
    // Placeholder for submission logic
    const newSubmittedFiles = [...submittedFiles];
    newSubmittedFiles[index] = selectedFiles[index];
    setSubmittedFiles(newSubmittedFiles);
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index] = null;
    setSelectedFiles(newSelectedFiles);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = leaveData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleEditClick = (index, leaveId) => {
    setEditIndex(index); // Set the edit index when edit button is clicked
    setEditLeaveId(leaveId); // Set the leave ID for editing
    setEditLeavePopupOpen(true); // Open the EditLeave popup
  };

  const toggleEditLeavePopup = () => {
    setEditLeavePopupOpen(!editLeavePopupOpen); // Toggle the EditLeave popup visibility
  };

  const fetchLeaveData = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/leave/getleave`)
      .then((response) => response.json())
      .then((data) => {
        setLeaveData(data);
        setSubmittedFiles(new Array(data.length).fill(null));
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set desired filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Remove link after download
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Leave Management
      </p>
      <div className="flex justify-between items-center">
        <div className="flex flex-col font-poppins font-bold ml-6 mt-4 mb-[2%]">
          <form className="form relative">
            <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
              <svg
                className="w-5 h-5 text-gray-700"
                aria-labelledby="search"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16"
                width="17"
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="1.333"
                  stroke="currentColor"
                  d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              required=""
              placeholder="Search..."
              onChange={""}
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
            />
          </form>
        </div>

        <div>
          <Apply_Leave onSuccess={fetchLeaveData} />
        </div>
      </div>
      <label className="font-semibold ml-[5%]">Leave Sheet</label>
      <div className="ml-[5%]">
        <hr className="line border-t border-gray-300 w-[5%]"></hr>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white ">
              <th className="text-primary px-4 py-2 ">Type</th>
              <th className="text-primary px-4 py-2 ">Emp NO</th>
              <th className="text-primary px-4 py-2 ">Full Name</th>
              <th className="text-primary px-4 py-2 ">Requested Date</th>
              <th className="text-primary px-4 py-2 ">Approval Status 01</th>
              <th className="text-primary px-4 py-2 ">Approval Status 02</th>
              <th className="text-primary px-4 py-2 ">Reason</th>
              <th className="text-primary px-4 py-2 ">View Document</th>
              <th className="text-primary px-4 py-2 ">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td className="bg-white px-4 py-2 text-center">
                  {item.leave_type}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.employee_no}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.employee_fullname}
                </td>
                <td className="bg-white px-4 py-2">{item.requested_date}</td>
                <td className="bg-white px-4 py-2">{item.approved_status_1}</td>
                <td className="bg-white px-4 py-2">{item.approved_status_2}</td>
                <td className="bg-white px-4 py-2">{item.reason}</td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.leave_file_path ? (
                    <div
                      className="h-8 mt-3 flex items-center"
                      onClick={() =>
                        downloadDisciplinaryFile(item.leave_file_path)
                      }
                    >
                      <img src={PDF} alt="pdf-icon" className="w-8 h-8 mr-2" />
                      <p className="font-sans text-sm">
                        {item.leave_file_name}
                      </p>
                    </div>
                  ) : (
                    <div className="flex gap-3 items-center">
                      <div>
                        <p className="ml-10">no file attached</p>
                      </div>
                    </div>
                  )}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.approved_status_1 !== "APPROVED" &&
                    item.approved_status_1 !== "RECLAIMED" && (
                      <button
                        onClick={() => handleEditClick(index, item.id)}
                        className="text-black font-bold py-2 px-4 rounded mr-2"
                      >
                        <CiEdit />
                      </button>
                    )}
                  {(item.approved_status_1 !== "APPROVED" ||
                    item.approved_status_2 !== "APPROVED") &&
                    item.approved_status_1 !== "RECLAIMED" && (
                      <button
                        onClick={() => handleDeleteClick(index)}
                        className="text-red-600 font-bold py-2 px-4 rounded justify-end flex"
                      >
                        <MdDelete />
                      </button>
                    )}
                  {item.approved_status_1 === "APPROVED" &&
                    item.approved_status_2 === "APPROVED" && (
                      <button
                        onClick={() => handleReclaimClick(index)}
                        className="text-blue-600 font-bold py-2 px-4 rounded"
                      >
                        <FcRefresh />
                      </button>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(leaveData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(leaveData.length / itemsPerPage)}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md "
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          message={
            deleteMode === "reclaim"
              ? "This leave is approved. Are you sure you want to reclaim the leave?"
              : "Are you sure you want to delete this leave?"
          }
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={handleDeleteConfirmed}
        />
      )}

      {editLeavePopupOpen && (
        <EditLeave leaveId={editLeaveId} onClose={toggleEditLeavePopup} />
      )}
    </div>
  );
}

export default Leave;
