 

import React, { useEffect, useState } from "react";
import "./addStores.css";
import IdGenerate from "../../../utils/id_generate";

import axios from "axios";

export default function AddStores() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [data, setData] = useState({
    store_id: IdGenerate("STORE"),
    store_name: "",
    store_description: "",
    store_update_date: "",
    store_update_user_id: userId,
  });

  const [branch, setBranch] = useState([]);

  const SubmitHandler = async () => {
    const Data = {
      store_id: data.store_id,
      store_name: data.store_name,
      store_description: data.store_description,
      store_update_date: formattedDate,
      store_update_user_id: userId,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/store/add`,
        Data
      );
      console.log(res.data);
      if (res.status === 200 || res.status === 201) {
        alert("Store Added Successfully");
        ResetHandler();
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("Store Already Exist");
      } else if (error.response.status === 400) {
        alert("Store Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Store Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const ResetHandler = () => {
    setData({
      store_id: IdGenerate("STORE"),
      store_name: "",
      store_description: "",
      store_update_date: "",
      store_update_user_id: userId,
    });
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1"> Add Stores</p>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Enter Store Details</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Id</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.store_id}
              onChange={(e) => {
                const Data = { ...data };
                Data.store_id = e.target.value;
                setData(Data);
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              placeholder="Enter Name"
              value={data.store_name}
              onChange={(e) => {
                const Data = { ...data };
                Data.store_name = e.target.value;
                setData(Data);
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Description</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              placeholder="Enter Description"
              value={data.store_description}
              onChange={(e) => {
                const Data = { ...data };
                Data.store_description = e.target.value;
                setData(Data);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-10 px-[200px]">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4">
          <p className="text-white" onClick={SubmitHandler}>
            ADD
          </p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4">
          <p className="text-white">CANCEL</p>
        </button>
      </div>
    </div>
  );
}
