 

import React, { useState } from "react";
import { Link } from "react-router-dom";
import DialogBox from "../New Purchase Request/newPurchaseDialogBox";
import DialogBoxChairman from "../Request Review by/chairemanDialogbox";
import usePermissions from "../../../../components/permissions/permission";

const Per_request = () => {
  const { hasPermission } = usePermissions();
  const [showDialog, setShowDialog] = useState(false);
  const [showChairDialog, setShowChairDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialogChair = () => {
    setShowChairDialog(true);
  };

  const closeDialogChair = () => {
    setShowChairDialog(false);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Purchase Requests
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6 mt- ">
        {/* Card 1 */}
        {hasPermission(1790) && (
          <Link to="/purchase/requestForm" className="card-link">
            <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
              <div className="min-h-[110px] min-w-[150px]">
                <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                  Create Purchase Requests
                </h2>
              </div>
              <div className="flex items-center">
                <button className="bg-primary w-[30%] mt-2 ml-2 text-white font-bold py-2 px-4 rounded-[22px]">
                  Create
                </button>
              </div>
            </div>
          </Link>
        )}

        {/* Card 2 */}
        {hasPermission(1800) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Purchase <br /> Requests
              </h2>
            </div>
            <div className="flex items-center">
              <div className="h-9 w-10 flex items-center justify-center bg-primary rounded-full mr-2">
                <span className="text-white text-sm font-semibold">9</span>
              </div>
              <div className="flex justify-end w-full">
                <button
                  onClick={openDialog}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
            {showDialog && <DialogBox onClose={closeDialog} />}
          </div>
        )}

        {/* Card 3 */}
        {hasPermission(1810) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Request Approved <br /> by Chairman
              </h2>
            </div>
            <div className="flex items-center">
              <div className="h-9 w-10 flex items-center justify-center bg-primary rounded-full mr-2">
                <span className="text-white text-sm font-semibold">9</span>
              </div>
              <div className="flex justify-end w-full">
                <button
                  onClick={openDialogChair}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
            {showChairDialog && (
              <DialogBoxChairman onClose={closeDialogChair} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Per_request;
