import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import TableTenderUpdateOfBidders from "./table";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import Upload_sample_Reports from "./uploadSampleReports";
import axios from "axios";
import IdGenerate from "../../../../utils/id_generate";

const Sample_Testing_View = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewsecond] = useState(null);
  const [imagesecond, setImagesecond] = useState(null);
  const [imagePreviewthired, setImagePreviewthired] = useState(null);
  const [imagethired, setImagethired] = useState(null);
  const [imagePreviewfourth, setImagePreviewfouth] = useState(null);
  const [imagefourth, setImagefourth] = useState(null);
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSupplierTerm, setSearchSupplierTerm] = useState("");
  const [tenders, setTenders] = useState([]);
  const [tabledata, setTabledata] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState([]);
  const [selectedSupplier, setSelectedSupplierTender] = useState([]);
  const [appointingDate, setAppointingDate] = useState(null);
  const [tenderId, setTenderId] = useState("");
  const [tenderName, setTenderName] = useState("");
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [arrangeData, setArrangeData] = useState([]);
  const [sampleTable, setSampleTable] = useState([]);
  const navigate = useNavigate();

  const pdfDownloader = () => {
    navigate("/committess/sampleTestingDocument/PDF", { state: tabledata });
  };
  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Second Signature Box
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagesecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewsecond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Theired Signature Box
  const handleDragOverthired = (e) => {
    e.preventDefault();
  };

  const handleDropthired = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagethired(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthired(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Fourth Signature Box
  const handleDragOverfourth = (e) => {
    e.preventDefault();
  };

  const handleDropfourth = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagefourth(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewfouth(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/search/tender/${query}`
        );
        setTenders(response.data);

        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  const handleOptionClick = (result) => {
    getTableData(result.tender_id);
    setSearchTerm(result.tender_id);
    setSelectedTender(result);
    setIsDropdownOpen(false);

    //Assign values to the fetching input field.
    const tenderDateParts = result.deadline_date.split("/");
    const tenderDate = new Date(
      `${tenderDateParts[2]}/${tenderDateParts[1]}/${tenderDateParts[0]}`
    );
    setAppointingDate(tenderDate);
    setTenderId(result.tender_id);
    setTenderName(result.tender_name);
    // setSupplierName(result.contract_id);
  };

  const handleReset = () => {
    setSearchTerm("");
    setTenders([]);
    setIsDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
  };

  const getTableData = async (tenderId) => {
    const query = tenderId;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/SampleTesting/TenderId/${query}`
        );
        setTabledata(response.data[0]);
        const transformedSamples = transformSamplesData(
          response.data[0].testing_samples
        );
        setSampleTable(transformedSamples);
        console.log("Sample testing data:", response.data[0]);
        console.log("Transformed sample data:", transformedSamples);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTabledata([]);
      }
    } else {
      setTabledata([]);
    }
  };

  const transformSamplesData = (samples) => {
    return samples.map((sample) => ({
      searchSupplierTerm: sample.bidder_id,
      date: sample.date_of_submission,
      status: sample.status,
      drugName: sample.drug_name,
      SampleNumber: sample.sample_no,
      symbolNumber: sample.symbol_no,
    }));
  };

  console.log("Tender Data:", tabledata);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Sample Testing Committee View
      </p>

      <div className="grid grid-cols-2 mt-4">
        {/* Serach Box */}
        <form className="form relative mt-4 ml-[10%]">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button
            type="button"
            className="btn btn-primary text-[#797C80] hover:bg-gray-100"
            onClick={handleReset}
          >
            RESET
          </button>
          <div className="mt-4 relative">
            {isDropdownOpen && (
              <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                {tenders.length > 0 ? (
                  tenders.map((result) => (
                    <li
                      key={result._id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      value={selectedTender}
                      onClick={() => handleOptionClick(result)}
                    >
                      {result.tender_id}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500">No results found</li>
                )}
              </ul>
            )}
          </div>
        </form>
      </div>
      {/* Flex container for cards */}

      <div className="ml-6">
        <div className="mt-4 ml-6">
          <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
            Tender Updates of Bidders
          </p>
        </div>
        <TableTenderUpdateOfBidders data={sampleTable} />
      </div>
      {/* Signarute Image Uploader */}

      <div className="  grid grid-cols-4 ml-6 ">
        {/* Drag and drop area */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {tabledata.internal_auditor_img ? (
            <img
              src={` ${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${tabledata.internal_auditor_img}`}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Internal Auditor
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Second Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOversecond}
          onDrop={handleDropsecond}
        >
          {tabledata.certified_1_img ? (
            <img
              src={` ${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${tabledata.certified_1_img}`}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Certified By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Thiered Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOverthired}
          onDrop={handleDropthired}
        >
          {tabledata.certified_2_img ? (
            <img
              src={` ${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${tabledata.certified_2_img}`}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Certified By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Fourth Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOverfourth}
          onDrop={handleDropfourth}
        >
          {tabledata.certified_3_img ? (
            <img
              src={` ${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${tabledata.certified_3_img}`}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Certified By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="grid grid-flow-row-dense grid-cols-8 grid-rows-3 gap-2 text-[#8691AB] font-bold ... ">
          <div className="row-span-3  flex justify-end ">
            <label>Name:</label>
          </div>
          <div className="row-span-3">
            <input
              className=" bg-white rounded-[23px] w-[90%]"
              value={tabledata.internal_auditor_employee_id}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Name:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_1_employee_id}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Name:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_2_employee_id}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Name:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_3_employee_id}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Role:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_1_employee_role}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Role:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_2_employee_role}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Role:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_3_employee_role}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Designation:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_1_employee_designation}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Designation:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_2_employee_designation}
            ></input>
          </div>
          <div className=" flex justify-end">
            <label>Designation:</label>
          </div>
          <div>
            <input
              className=" bg-white w-[90%] rounded-[23px] "
              value={tabledata.certified_3_employee_designation}
            ></input>
          </div>
        </div>
      </div>
      <div className=" flex justify-end mr-4  text-[16px] font-bold mt-4 mb-4">
        <button
          className=" bg-[#5B6D49] rounded-[32px] w-[150px] h-[40px] text-white"
          onClick={pdfDownloader}
        >
          Export as PDF
        </button>
      </div>
    </div>
  );
};

export default Sample_Testing_View;
