/** @format */

import React, { useState, useEffect } from "react";
import usePermissions from "../../../../components/permissions/permission";

const SpecialIncentive = () => {
  const { hasPermission } = usePermissions();
  const [employeeId, setEmployeeId] = useState("");
  const [specialData, setSpecialData] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [generateYear, setGenerateYear] = useState("");
  const [generateMonth, setGenerateMonth] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchSpecialIncentives();
    fetchEmployees();
  }, []);

  const fetchSpecialIncentives = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/employee-special-incentives`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setSpecialData(data);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch special incentives data:", errorData);
      }
    } catch (error) {
      console.error(
        "Error occurred while fetching special incentives data:",
        error
      );
    }
  };

  const fetchEmployees = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/designations/employees-by-working-days`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setEmployeeData(data);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch employees data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching employees data:", error);
    }
  };

  const handleGenerate = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConfirmModal = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmGenerate = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/apply-special-incentive`;
    const body = {
      month: generateMonth,
      year: generateYear,
      selectedEmployees: selectedEmployees.map((emp) => emp.employee_no),
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Payroll generated successfully:", data);
        closeConfirmModal();
        fetchSpecialIncentives();
      } else {
        const errorData = await response.json();
        console.error("Failed to generate payroll:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while generating payroll:", error);
    }
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployees((prev) => {
      if (prev.find((emp) => emp.employee_no === employee.employee_no)) {
        return prev.filter((emp) => emp.employee_no !== employee.employee_no);
      } else {
        return [...prev, employee];
      }
    });
  };

  const displayEmployees = employeeData.filter((emp) =>
    emp.employee_fullname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredData = specialData.filter(
    (item) =>
      (!month || item.month.toString() === month) &&
      (!year || item.year.toString() === year) &&
      (!employeeId || item.employee_no.includes(employeeId))
  );

  return (
    <div className="font-sans bg-background overflow-auto h-screen">
      <div>
        <p className="mt-6 ml-6 text-headerColor text-[30px] font-semibold">
          Special Incentive
        </p>
      </div>
      <div className="flex justify-between items-center ml-6">
        <div className="flex flex-col">
          <select
            id="monthSelect"
            name="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            autoComplete="off"
            className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans mb-3"
          >
            <option value="" className="font-sans-bold">
              Filter By Month
            </option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <select
            id="yearSelect"
            name="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            autoComplete="off"
            className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
          >
            <option value="" className="font-sans-bold">
              Filter By Year
            </option>
            {Array.from(new Array(20), (v, i) => i + 2000).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-white rounded-[22px] h-[189px] w-[430px] p-5 mb-5 mr-10">
          <div className="text-[#797C80] font-bold text-[29px] mb-5">
            <p>Calculate Special Incentive</p>
          </div>
          <div className="flex items-center justify-between mt-14">
            <label
              htmlFor="fileInput"
              className="bg-primary p-2 rounded-[32px] text-white cursor-pointer"
              onClick={handleGenerate}
            >
              + Calculate
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-[50px] rounded-xl">
        <table className="table-auto border-none w-[95%] rounded-2xl border-2">
          <thead>
            <tr className="bg-white rounded-xl">
              <th className="text-primary px-4 py-2">Employee No</th>
              <th className="text-primary px-4 py-2">Month</th>
              <th className="text-primary px-4 py-2">Year</th>
              <th className="text-primary px-4 py-2">Incentive Amount</th>
              <th className="text-primary px-4 py-2">Calculated Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.id}>
                <td className="bg-white px-4 py-2 text-center">
                  {item.employee_no}
                </td>
                <td className="bg-white px-4 py-2 text-center">{item.month}</td>
                <td className="bg-white px-4 py-2 text-center">{item.year}</td>
                <td className="bg-white px-4 py-2 text-center">
                  {item.incentive_amount}
                </td>
                <td className="bg-white px-4 py-2 text-center">
                  {new Date(item.calculated_date).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <div className="mb-6 items-center justify-center">
                <p className="font-sans text-[32px] font-bold text-center">
                  Calculate Special Incentive
                </p>
                <div className="mt-6">
                  <div className="flex flex-col mb-4">
                    <label className="mb-2 font-bold">Year:</label>
                    <input
                      type="text"
                      value={generateYear}
                      onChange={(e) => setGenerateYear(e.target.value)}
                      className="input rounded-xl border-none h-10 px-8 py-3 m-2 placeholder-gray-400"
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label className="mb-2 font-bold">Month:</label>
                    <input
                      type="text"
                      value={generateMonth}
                      onChange={(e) => setGenerateMonth(e.target.value)}
                      className="input rounded-xl border-none h-10 px-8 py-3 m-2 placeholder-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div>
                  <input
                    type="text"
                    required=""
                    placeholder="Search by Employee"
                    className="input rounded-xl border-none h-10 px-8 py-3 m-2 placeholder-gray-400"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="mt-5">
                    <div className="overflow-y-auto max-h-64 mt-5">
                      <table className="table-auto w-full">
                        <thead>
                          <tr>
                            <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">
                              Employee ID
                            </th>
                            <th className="px-4 py-2 bg-[#F5F5F5] rounded-r-xl">
                              Full Name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayEmployees.map((employee) => (
                            <tr
                              key={employee.employee_no}
                              className="border-b border-black-300"
                            >
                              <td className="px-4 py-2 flex items-center">
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  onChange={() =>
                                    handleSelectEmployee(employee)
                                  }
                                  checked={selectedEmployees.some(
                                    (emp) =>
                                      emp.employee_no === employee.employee_no
                                  )}
                                />
                                {employee.employee_no}
                              </td>
                              <td className="px-4 py-2">
                                {employee.employee_fullname}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mt-[5%] h-64 overflow-y-auto ">
                    <h3 className="text-xl ml-3 font-bold text-[#344054] mb-4">
                      Selected Employees
                    </h3>
                    <table className="table-auto w-full">
                      <thead>
                        <tr className="">
                          <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">
                            Employee Number
                          </th>
                          <th className="px-4 py-2 bg-[#F5F5F5] rounded-r-xl">
                            Full Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedEmployees.map((employee) => (
                          <tr
                            key={employee.employee_no}
                            className="border-b border-black-300"
                          >
                            <td className="px-4 py-2">
                              {employee.employee_no}
                            </td>
                            <td className="px-4 py-2">
                              {employee.employee_fullname}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-5 space-x-3">
              <button
                className="bg-primary p-2 rounded-[22px] text-white"
                onClick={openConfirmModal}
              >
                Generate
              </button>
              <button
                className="bg-gray-500 p-2 rounded-[22px] text-white"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isConfirmModalOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Confirm Generation
              </p>
              <p className="text-center">
                Are you sure you want to generate the special incentive?
              </p>
              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-primary p-2 rounded-[22px] text-white"
                  onClick={handleConfirmGenerate}
                >
                  Confirm
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white"
                  onClick={closeConfirmModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecialIncentive;
