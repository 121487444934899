import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tender_Document_View_Dialog from "./viewTenderDocDialog";

const TableViewTender = ({ data }) => {
  const navigate = useNavigate();

  const handleUpdate = (tenderId) => {
    navigate(`/supply/updateTender/?tenderId=${tenderId}`);
  };
  const [showDialog, setShowDialog] = useState(null);

  const openDialog = (index) => {
    setShowDialog(index);
  };

  const closeDialog = () => {
    setShowDialog(null);
  };

  const tableData = data;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[95%] max-h-[500px] overflow-y-auto mt-8 mb-8 min-h-[500px]">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6 ">
              Tender <br />
              ID
            </th>
            <th className="px-6 py-6 ">
              Tender <br />
              Name
            </th>
            <th className="px-6 py-6 ">
              Contract <br />
              ID
            </th>
            <th className="px-6 py-6 ">Category</th>
            <th className="px-6 py-6 ">Tender Doc</th>
            <th className="px-6 py-6 ">Deadline1</th>
            <th className="px-6 py-6 ">Deadline2</th>
            <th className="px-6 py-6 "></th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.tender_id}</td>
              <td className="px-6 py-2">{item.tender_name}</td>
              <td className="px-6 py-2">{item.contract_id}</td>
              <td className="px-6 py-2">{item.category}</td>
              <td className="px-6 py-2">
                <button
                  className="text-white bg-[#5B6D49] rounded-[22px] min-w-[55px]"
                  onClick={() => openDialog(item.tender_file)}
                >
                  View
                </button>
                {showDialog && (
                  <Tender_Document_View_Dialog
                    onClose={closeDialog}
                    tenderFile={showDialog}
                  />
                )}
              </td>
              <td className="px-6 py-2">{item.deadline_date}</td>
              <td className="px-6 py-2">{item.deadline_date}</td>

              <td className="px-6 py-2">
                <button
                  className="text-black bg-gray-300 rounded-[22px] min-w-[55px]"
                  onClick={() => handleUpdate(item.tender_id)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableViewTender;
