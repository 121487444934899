import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import PURCHASE_REQUEST_TABLE from "./TablePurchaseRequest";
import "react-datepicker/dist/react-datepicker.css";

const PerRequestForm = () => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [prNo, setPrNo] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCurrentDate, setSelecteCurrentdDate] = useState(new Date());

  // Reset function
  const handleReset = () => {
    setFrom("");
    setTo("");
    setPrNo("");
    setSelectedDate(null);
    setSelecteCurrentdDate(new Date());
  };

  // Submit function
  const handleSubmit = () => {
    // Add your submit logic here
    console.log("Form submitted");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Purchase Requests Form
        </p>
      </div>
      <div className="square absolute ml-10 mt-[3%] w-full sm:w-4/5 rounded-[46px] border border-gray-400 bg-[#F3F8FF]">
        <div className="flex flex-wrap ml-6 mt-6">
          <div className="w-1/2 pr-4 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              From:
            </label>
            <select
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6"
            >
              <option value="">Select From</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
              <option value="Option 3">Option 3</option>
            </select>
          </div>

          <div className="w-1/2 pl-4 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              To:
            </label>
            <select
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6"
            >
              <option value="">Select To</option>
              <option value="Option 1">Option 1</option>
              <option value="Option 2">Option 2</option>
              <option value="Option 3">Option 3</option>
            </select>
          </div>

          <div className="w-1/2 pr-4 mt-6 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              PR NO:
            </label>
            <input
              type="text"
              value={prNo}
              onChange={(e) => setPrNo(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6"
            />
          </div>

          <div className="w-1/2 pl-4 mt-6 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Date:
            </label>
            <DatePicker
              selected={selectedCurrentDate}
              onChange={(date) => setSelecteCurrentdDate(date)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/2"
              disabled
            />
          </div>

          <div className="w-1/2 pl-4 mt-6 flex items-center">
            <label className="mr-28 text-black font-poppins font-bold text-xl">
              Select Date:
            </label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/2"
            />
          </div>
        </div>
      </div>

      <div className="mt-[20%]">
        <PURCHASE_REQUEST_TABLE />
      </div>

      <div className="absolute ml-6 mt-[50%] right-6 space-x-4">
        <button
          onClick={handleReset}
          className=" hover:bg-red-600 text-black font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Reset
        </button>
        <button
          onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Submit
        </button>
      </div>

      <div className="mt-[55%] flex justify-center mb-6 ">
        <div className="w-[272px] h-[155px] bg-[#F3F8FF] rounded-[20px] flex-shrink-0">
          <div className="p-4">
            <h2 className="text-[#272525] font-poppins font-extrabold text-24">
              Order By
            </h2>
          </div>
          <div className="w-[169px] h-[41px] bg-[#FFF] mt-2 ml-12 rounded-lg"></div>
        </div>

        <div className="w-[272px] h-[155px] bg-[#F3F8FF] rounded-[20px] flex-shrink-0 ml-12">
          <div className="p-4">
            <h2 className="text-[#272525] font-poppins font-extrabold text-24">
              Order By
            </h2>
          </div>
          <div className="w-[169px] h-[41px] bg-[#FFF] mt-2 ml-12 rounded-lg"></div>
        </div>

        <div className="w-[272px] h-[155px] bg-[#F3F8FF] rounded-[20px] flex-shrink-0 ml-12">
          <div className="p-4">
            <h2 className="text-[#272525] font-poppins font-extrabold text-24">
              Order By
            </h2>
          </div>
          <div className="w-[169px] h-[41px] bg-[#FFF] mt-2 ml-12 rounded-lg"></div>
        </div>
      </div>
    </div>
  );
};

export default PerRequestForm;
