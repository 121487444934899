/** @format */

import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../../components/permissions/permission";
import { RiArrowLeftDoubleLine } from "react-icons/ri";

function InAndOutTimeRegular() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(40);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(""); // State to hold the search input
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const { hasPermission } = usePermissions();

  const fetchData = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start date and end date.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/attendence/get-attendance?startDate=${startDate}&endDate=${endDate}`
      );
      const result = await response.json();
      setData(result);
      setFilteredData(result); // Also initialize filteredData with fetched data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const keyword = e.target.value.toLowerCase();
    setSearch(keyword);
    filterData(keyword, startDate, endDate);
  };

  const handleResetFilter = () => {
    setSearch("");
    setStartDate("");
    setEndDate("");
    setFilteredData(data);
  };

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setStartDate(value);
    filterData(search, value, endDate);
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setEndDate(value);
    filterData(search, startDate, value);
  };

  const filterData = (keyword, start, end) => {
    const filtered = data.filter((item) => {
      const itemDate = item.checkIN_time ? new Date(item.checkIN_time) : null;
      const isStartDateMatch = start ? new Date(start) <= itemDate : true;
      const isEndDateMatch = end ? new Date(end) >= itemDate : true;

      return (
        (item.employee_id &&
          item.employee_id.toLowerCase().includes(keyword)) ||
        (item.first_name && item.first_name.toLowerCase().includes(keyword)) ||
        (item.last_name && item.last_name.toLowerCase().includes(keyword)) ||
        (item.department &&
          item.department.toLowerCase().includes(keyword) &&
          isStartDateMatch &&
          isEndDateMatch)
      );
    });
    setFilteredData(filtered);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleTrackNow = () => {
    navigate("/in-and-out-shift");
  };

  const handleBack = () => {
    navigate("/attendance");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="flex justify-between items-center mr-[5%]">
        <p className="text-[25px] font-sans ml-6 font-bold text-[#797C80]">
          Time & Attendance Management
        </p>
        <button
          className="bg-white p-3 rounded-lg font-bold mb-5 mt-5"
          onClick={handleBack}
        >
          <div className="flex gap-2 items-center">
            <RiArrowLeftDoubleLine />
            Navigate to home
          </div>
        </button>
      </div>

      {/* Search */}
      <div className="flex justify-between items-center mt-[20px]">
        {/* input fields */}
        <div>
          <div className="ml-6 flex gap-[40px]">
            <div>
              <p className="text-headerColor font-bold mb-2">Start Date</p>
              <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                className="w-[217px] h-[55px] rounded-[22px] pl-5 pr-5"
              />
            </div>
            <div>
              <p className="text-headerColor font-bold mb-2">End Date</p>
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                className="w-[217px] h-[55px] rounded-[22px] pl-5 pr-5"
              />
            </div>
          </div>
          <div className="ml-6 mt-3">
            <button
              onClick={fetchData}
              className="bg-primary text-white px-4 py-2 rounded-md"
            >
              Get Attendance Data
            </button>
          </div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                value={search}
                onChange={handleFilterChange}
                className="w-[480px] h-[55px] rounded-[22px] pl-5"
                placeholder="Search By Employee Code"
              />
            </div>
            <div>
              <p
                className="text-headerColor font-bold"
                onClick={handleResetFilter}
              >
                Reset
              </p>
            </div>
          </div>

          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <select
                className="w-[480px] h-[55px] rounded-[22px] bg-white pl-5"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Sort by Department
                </option>
                <option value="option1"></option>
              </select>
            </div>
          </div>
        </div>
        {hasPermission(2010) && (
          <div className="ml-[35%] mr-[5%]">
            <div className="mt-[10%]">
              <div
                key=""
                className="bg-white rounded-[32px] w-[350px] h-[200px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  <h2 className="text-[25px] font-semibold mb-1 font-sans">
                    Shift Basis
                  </h2>
                  <h2 className="text-[25px] font-semibold mb-1 font-sans">
                    Employees
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                    onClick={handleTrackNow}
                  >
                    Track Now
                  </button>
                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <label className="font-semibold ml-[5%]">Attendance Sheet</label>
      <div className="ml-[6%]">
        <hr className="line border-t border-gray-300 w-[5%]"></hr>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%]">
          <thead>
            <tr className="bg-white text-[18px]">
              <th className="text-primary px-4 py-2 w-[10%]">Employee ID</th>
              <th className="text-primary px-4 py-2 w-[15%]">Check-in Time</th>
              <th className="text-primary px-4 py-2 w-[15%]">Check-in Type</th>
              <th className="text-primary px-4 py-2 w-[15%]">Check-out Time</th>
              <th className="text-primary px-4 py-2 w-[15%]">Check-out Type</th>
              <th className="text-primary px-4 py-2 w-[20%]">Remark</th>
              <th className="text-primary px-4 py-2 w-[10%]">Grace Time</th>
              <th className="text-primary px-4 py-2 w-[10%]">Status</th>
              <th className="text-primary px-4 py-2 w-[15%]">Time Table End</th>
              <th className="text-primary px-4 py-2 w-[10%]">OT</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item._id} className="bg-white text-[18px]">
                <td className="px-4 py-2">{item.employee_id}</td>
                <td className="px-4 py-2">{item.checkIN_time}</td>
                <td className="px-4 py-2">{item.checkIN_type}</td>
                <td className="px-4 py-2">{item.checkOUT_time}</td>
                <td className="px-4 py-2">{item.checkOUT_type}</td>
                <td className="px-4 py-2">{item.remark}</td>
                <td className="px-4 py-2">{item.grace_time}</td>
                <td className="px-4 py-2">{item.status}</td>
                <td className="px-4 py-2">{item.timeTable_end}</td>
                <td className="px-4 py-2">{item.OT}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {Array.from({
          length: Math.ceil(filteredData.length / itemsPerPage),
        }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`${
              currentPage === index + 1 ? "bg-primary text-white" : "bg-white"
            } px-4 py-2 mx-1 rounded-md`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
}

export default InAndOutTimeRegular;
