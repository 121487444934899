import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import New from "../../../../Assets/icons/New.png";
import Send from "../../../../Assets/icons/Side Nav icons.png";
import Trash from "../../../../Assets/icons/Trash.png";

import TableMail from "./mailTabel";
import DialogNewMail from "./newMessageDialog";
const Send_A_Mail = () => {
  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-10 mt-6 font-bold text-[#071C50] ">
        Tender Opening Committee
      </p>
      <div className="bg-white rounded-[60px] h-[70%] w-[90%] ml-10 mt-6">
        <div className="h-[100%] flex justify-between">
          <div className=" w-[15%] h-[100%] border-r-2 border-[#9CAC8B]">
            <p className=" text-[20px] font-bold mt-12 ml-4 text-[#071C50]">
              Colling for Prices
            </p>

            <button className="shadow-lg border-2 border-gray-400 w-[95%] h-12 rounded-lg mt-4 ml-2 flex items-center">
              <img
                src={New}
                alt="Image"
                className="ml-1 mr-1"
                onClick={openDialog}
              />{" "}
              Compose and Email
            </button>
            {showDialog && <DialogNewMail onClose={closeDialog} />}
            <div>
              <div className="flex ml-6 mt-8">
                <img src={Send} alt="Send" />
                <button className="ml-2">Send</button>
              </div>
              <div className="flex ml-6 mt-8">
                <img src={Trash} alt="Trash" />
                <button className="ml-2">Trash</button>
              </div>
            </div>
          </div>
          <div className=" w-[85%] h-[100%] right-0 ">
            <TableMail />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Send_A_Mail;
