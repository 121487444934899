import React, { useState, useEffect } from "react";

function AllDeductions() {
  const [deductionData, setDeductionData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/get-all-deductions`
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched data:", data); // Log the fetched data
          // Ensure data is an array
          if (Array.isArray(data)) {
            setDeductionData(data);
          } else if (data.data && Array.isArray(data.data)) {
            setDeductionData(data.data);
          } else {
            console.error("Unexpected data format:", data);
            setDeductionData([]);
          }
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/designations/getdesignation`
        );

        if (response.ok) {
          const data = await response.json();
          const departments = [...new Set(data.map((item) => item.department))];
          setDepartments(departments);
          setDesignations(data);
        } else {
          console.error("Failed to fetch designations");
        }
      } catch (error) {
        console.error("Error occurred while fetching designations:", error);
      }
    };

    fetchDesignations();
  }, []);

  const filteredDeductionData = deductionData.filter((item) => {
    return (
      item.employee_no.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedDepartment === "" ||
        item.employee_department === selectedDepartment) &&
      (selectedDesignation === "" ||
        item.employee_designation === selectedDesignation)
    );
  });

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[25px] ml-6 mt-6 font-bold">
          All Deductions 
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div className="flex flex-col">
                <input
                  type="text"
                  placeholder="Search by Emp No..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 ml-6"
                />
              </div>
              <div className="mt-3">
                <div className="flex flex-col">
                  <select
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white sm:text-sm px-2 font-sans"
                  >
                    <option value="">All Departments</option>
                    {departments.map((dept, index) => (
                      <option key={index} value={dept}>
                        {dept}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-3">
                <div className="flex flex-col">
                  <select
                    value={selectedDesignation}
                    onChange={(e) => setSelectedDesignation(e.target.value)}
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white sm:text-sm px-2 font-sans"
                  >
                    <option value="">All Designations</option>
                    {designations.map((designation) => (
                      <option
                        key={designation.id}
                        value={designation.designation}
                      >
                        {designation.designation}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col font-sans font-bold ml-6 rounded-xl mt-10">
            <table className="table-auto border-none w-[95%]">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary px-4 py-2">Emp NO</th>
                  <th className="text-primary px-4 py-2">PAY TAX</th>
                  <th className="text-primary px-4 py-2">Stamp Duty</th>
                  <th className="text-primary px-4 py-2">Salary Advance</th>
                  <th className="text-primary px-4 py-2">Welfare Members</th>
                  <th className="text-primary px-4 py-2">Festival Advance</th>
                  <th className="text-primary px-4 py-2">Bank Loan 1</th>
                  <th className="text-primary px-4 py-2">Other Deductions 1</th>
                  <th className="text-primary px-4 py-2">Welfare Advance</th>
                  <th className="text-primary px-4 py-2">Union 1</th>
                  <th className="text-primary px-4 py-2">Union 2</th>
                  <th className="text-primary px-4 py-2">Union 3</th>
                  <th className="text-primary px-4 py-2">Union 4</th>
                  <th className="text-primary px-4 py-2">Union 5</th>
                  <th className="text-primary px-4 py-2">
                    Buddha Sangamaya Members
                  </th>
                  <th className="text-primary px-4 py-2">Sanasa Loan</th>
                  <th className="text-primary px-4 py-2">Other Deductions 2</th>
                  <th className="text-primary px-4 py-2">Welfare Loan</th>
                  <th className="text-primary px-4 py-2">Saving To Bank</th>
                  <th className="text-primary px-4 py-2">Sanasa Member</th>
                  <th className="text-primary px-4 py-2">Sanasa Advance</th>
                  <th className="text-primary px-4 py-2">Donations</th>
                  <th className="text-primary px-4 py-2">Other Deductions 3</th>
                  <th className="text-primary px-4 py-2">Bank Loan 2</th>
                  <th className="text-primary px-4 py-2">Distress Loan</th>
                  <th className="text-primary px-4 py-2">
                    Distress Loan Interest
                  </th>
                  <th className="text-primary px-4 py-2">No Pay</th>
                </tr>
              </thead>
              <tbody>
                {filteredDeductionData.map((item, index) => (
                  <tr key={index} className="bg-white">
                    <td className="px-4 py-2">{item.employee_no}</td>
                    <td className="px-4 py-2">{item.PAY_TAX}</td>
                    <td className="px-4 py-2">{item.stamp_duty}</td>
                    <td className="px-4 py-2">{item.salary_advance}</td>
                    <td className="px-4 py-2">{item.welfare_members}</td>
                    <td className="px-4 py-2">{item.festival_advance}</td>
                    <td className="px-4 py-2">{item.bank_loan_01}</td>
                    <td className="px-4 py-2">{item.other_deductions_1}</td>
                    <td className="px-4 py-2">{item.welfare_advance}</td>
                    <td className="px-4 py-2">{item.Union_1}</td>
                    <td className="px-4 py-2">{item.Union_2}</td>
                    <td className="px-4 py-2">{item.Union_3}</td>
                    <td className="px-4 py-2">{item.Union_4}</td>
                    <td className="px-4 py-2">{item.Union_5}</td>
                    <td className="px-4 py-2">
                      {item.Buddha_Sangamaya_members}
                    </td>
                    <td className="px-4 py-2">{item.Sanasa_loan}</td>
                    <td className="px-4 py-2">{item.Other_deductions_2}</td>
                    <td className="px-4 py-2">{item.Welfare_Loan}</td>
                    <td className="px-4 py-2">{item.Saving_To_Bank}</td>
                    <td className="px-4 py-2">{item.Sanasa_member}</td>
                    <td className="px-4 py-2">{item.Sanasa_Advance}</td>
                    <td className="px-4 py-2">{item.Donations}</td>
                    <td className="px-4 py-2">{item.other_deductions_3}</td>
                    <td className="px-4 py-2">{item.Bank_Loan_2}</td>
                    <td className="px-4 py-2">{item.Distress_Loan}</td>
                    <td className="px-4 py-2">{item.Distress_Loan_Interest}</td>
                    <td className="px-4 py-2">{item.no_pay}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllDeductions;
