import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import cloudiccon from "../../../../../Assets/icons/cloud-add.png";
import axios from "axios";

const DialogBoxUploadDocuments = ({ onClose }) => {
  const navigate = useNavigate();

  const [files, setFiles] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSupliers, setSearchSupliers] = useState("");
  const [tenders, setTenders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [tenderName, setTenderName] = useState("");
  const [contractId, setContractId] = useState("");
  const [bidderName, setBidderName] = useState("");
  const [tenderId, setTenderId] = useState("");
  const [note, setNote] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  console.log("File path:", files);
  const addFile = (file) => {
    const newFiles = { ...files };
    newFiles[file.name] = file;
    setFiles(newFiles);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("tender_id", tenderId);
      formData.append("supplier_id", contractId);

      formData.append("bidder_note", note);
      Object.values(files).forEach((file) => {
        formData.append("bidder_document", file);
      });

      // // Add files to the formData
      // Object.values(files).forEach((file) => {
      //   formData.append("tender_document", file);
      // });

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_bidders/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Tender successfully added");
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add tender");
    }
  };

  const handleCancel = () => {
    setFiles({});
  };

  const handleFileInputChange = (e) => {
    const fileList = e.target.files;
    for (const file of fileList) {
      addFile(file);
    }
  };

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/search/tender/${query}`
        );
        setTenders(response.data);

        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  const handelSuplierSearchChange = async (event) => {
    const query = event.target.value;
    setSearchSupliers(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/supplier/search/${query}`
        );

        setCustomers(response.data);
        setIsCustomerDropdownOpen(true); // Ensure this sets the state correctly
      } catch (error) {
        console.error("Error fetching data:", error);
        setCustomers([]);
      }
    } else {
      setCustomers([]);
      setIsCustomerDropdownOpen(false); // Close the dropdown if the input is cleared
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setTenders([]);
    setIsDropdownOpen(false);
    setTenderId("");
    setContractId("");
    setNote("");
    setFiles("");
    setTenderId("");
    setCustomers([]);
    setBidderName("");
  };

  const handleOptionClick = (result) => {
    setSearchTerm(result.tender_id);
    setIsDropdownOpen(false);
    setTenderId(result.tender_id);
    setTenderName(result.tender_name);
  };

  const handleCustomerOptionClick = (result) => {
    setSearchSupliers(result.supplier_id);
    setIsCustomerDropdownOpen(false);
    setContractId(result.supplier_id);
    setBidderName(result.supplier_name);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[80%] h-[85%] min-h-[650px]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-full bg-clsDialog w-[40.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          {" "}
          <div className="flex flex-col font-poppins font-bold  ml-6 ">
            <form className="relative">
              <div className="flex items-center relative">
                <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                  <svg
                    className="w-5 h-5 text-gray-700"
                    aria-labelledby="search"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    height="16"
                    width="17"
                  >
                    <path
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      strokeWidth="1.333"
                      stroke="currentColor"
                      d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                    ></path>
                  </svg>
                </button>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                  className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                />
                <button
                  type="button"
                  className="btn btn-primary text-[#797C80] hover:bg-gray-100 ml-2"
                  onClick={handleReset}
                >
                  RESET
                </button>
              </div>
              <div className="mt-4 relative">
                {isDropdownOpen && (
                  <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                    {tenders.length > 0 ? (
                      tenders.map((result) => (
                        <li
                          key={result._id}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          value={selectedTender}
                          onClick={() => handleOptionClick(result)}
                        >
                          {result.tender_id}
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500">No results found</li>
                    )}
                  </ul>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="flex justify-center items-center h-[60%]">
          <div className="bg-dialogBoxbg rounded-[19px] w-[90%] h-[90%] flex-shrink-0">
            <div className="flex justify-center items-center h-[100%]">
              <div className="bg-dialogBoxbg rounded-[19px] w-[100%] h-[100%] flex-shrink-0">
                <div className=" items-center justify-between grid grid-cols-2">
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Tender Name:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={tenderName}
                      onChange={(e) => setTenderName(e.target.value)}
                      readOnly
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Contract ID:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={searchSupliers}
                      onChange={handelSuplierSearchChange}
                    />
                    <div className=" mt-6 ">
                      {isCustomerDropdownOpen && (
                        <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10 w-[40%] ml-[50%]">
                          {customers.length > 0 ? (
                            customers.map((result) => (
                              <li
                                key={result._id}
                                className="p-2 hover:bg-gray-100 cursor-pointer "
                                onClick={() =>
                                  handleCustomerOptionClick(result)
                                }
                              >
                                {result.supplier_id}
                              </li>
                            ))
                          ) : (
                            <li className="p-2 text-gray-500">
                              No results found
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Bidder Name:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      placeholder="xxxxx"
                      value={bidderName}
                      onChange={(e) => setBidderName(e.target.value)}
                      required
                      readOnly
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Notes:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      placeholder="xxxxx"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className=" flex items-center justify-center">
                  <div>
                    <h2 className="text-dialogHeader font-poppins font-semibold text-[26px] text-center">
                      Upload Files
                    </h2>
                    <hr className=" ml-[20%] w-[60%] border-solid border-b-2 border-black"></hr>
                  </div>
                </div>
                <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[80%] h-[90%] flex-shrink-0 mt-5 ml-[10%]">
                  <main className="container mx-auto max-w-screen-lg h-full">
                    {/* File upload modal */}
                    <article className="relative h-full flex flex-col rounded-md">
                      {/* Scroll area */}
                      <section className="h-full overflow-auto p-8 w-full flex flex-col">
                        <div className="flex items-center">
                          <img
                            src={cloudiccon}
                            alt="Cloud Icon"
                            className="h-10 w-10 rounded-full border border-gray-600"
                          />

                          <div>
                            <p className="text-gray-800 font-inter font-medium text-lg ml-2">
                              Upload files
                            </p>
                            <p className="text-dialogSubmenu-500 text-gray-400 font-inter font-medium text-sm ml-2">
                              Select and upload the files of your choice
                            </p>
                          </div>
                        </div>
                        <hr className="  w-[100%] border-solid border-b-2 border-grayColor mt-2"></hr>

                        <header className="border-dashed border-2 bg-dialogboxFileup border-gray-400 py-12 flex flex-col justify-center items-center mt-2 rounded-[26px]">
                          <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center items-center flex-col">
                            <div>
                              <img
                                src={cloudiccon}
                                alt="Cloud Icon"
                                className="h-10 w-10 rounded-full"
                              />
                            </div>
                            <span>
                              Choose a PDF file or drag & drop it here
                            </span>
                            &nbsp;
                            <span className="text-gray-400">
                              files anywhere or
                            </span>
                          </p>
                          <input
                            id="hidden-input"
                            type="file"
                            multiple
                            className="hidden"
                            onChange={handleFileInputChange}
                          />
                          <button
                            id="button"
                            className="mt-2 rounded-[16px] px-3 py-1 bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                            onClick={() =>
                              document.getElementById("hidden-input").click()
                            }
                          >
                            Browse File
                          </button>
                        </header>

                        {/* Uploaded files list */}
                        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                          Uploaded Files
                        </h1>
                        <ul className="flex flex-1 flex-wrap -m-1">
                          {Object.values(files).map((file, index) => (
                            <li
                              key={index}
                              className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
                            >
                              <article
                                tabIndex="0"
                                className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                              >
                                <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                  <h1 className="flex-1 group-hover:text-blue-800">
                                    {file.name}
                                  </h1>
                                  <div className="flex">
                                    <span className="p-1 text-blue-800">
                                      <i>
                                        <svg
                                          className="fill-current w-4 h-4 ml-auto pt-1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                        </svg>
                                      </i>
                                    </span>
                                    <p className="p-1 size text-xs text-gray-700">
                                      {file.size > 1024
                                        ? file.size > 1048576
                                          ? Math.round(file.size / 1048576) +
                                            "mb"
                                          : Math.round(file.size / 1024) + "kb"
                                        : file.size + "b"}
                                    </p>
                                  </div>
                                </section>
                              </article>
                            </li>
                          ))}
                        </ul>
                      </section>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sticky footer */}

        <footer className="flex  px-8 pb-8">
          <button
            id="submit"
            className="rounded-lg px-3 py-1 bg-addBtn hover:bg-green-800 text-white text-lg focus:shadow-outline focus:outline-none"
            onClick={handleSubmit}
          >
            Add
          </button>
          <button
            id="cancel"
            className="ml-3 rounded-lg px-3 py-1 hover:bg-gray-300 text-black focus:shadow-outline focus:outline-none text-lg"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default DialogBoxUploadDocuments;
