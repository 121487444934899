 

import React, { useState, useEffect } from "react";
import axios from "axios";

import Earth from "../../../Assets/icons/Sphere-Green-Glossy.png";
import Cube from "../../../Assets/icons/Cube-2.png";
import Sphere from "../../../Assets/icons/Sphere-Green-Glossy.png";
import Toroid from "../../../Assets/icons/SuperToroid-2.png";

import Card1 from "../../../components/card 1/card1";
import usePermissions from "../../../components/permissions/permission";

export default function PosItems() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string

  const [brachId, setBranchId] = useState("BRANCH-0001");
  const [billDate, setBillDate] = useState(formattedDate.split("T")[0]);

  const [totalItems, setTotalItems] = useState(0);
  const [totalSale, setTotlaSale] = useState(0);
  const [totalSoldItems, setTotalSoldItems] = useState(0);

  //today
  const [todayTotal, setTodayTotal] = useState(0);
  const [todayBill, setTodayBill] = useState(0);
  const [todayBills, setTodayBills] = useState([]);

  //get bills
  const ToDaySale = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/pos/billsAccToDate/Branch/PosType/${billDate}/${brachId}/BRANCH-POS`
      );
      // console.log(res.data)
      setTodayBills(res.data);
      let total = 0;
      let bill = 0;
      if (res.data.length > 0) {
        res.data.forEach((element) => {
          total = total + parseInt(element.pos_net_total);
          bill++;
          // console.log(total)
        });
        setTodayTotal(total);
        setTodayBill(bill);
      }
    } catch (error) {}
  };
  useEffect(() => {
    ToDaySale();
  }, [billDate]);

  const [billItems, setBillItems] = useState([]);
  const GetBillItems = async () => {
    try {
      const promises = todayBills.map(async (element) => {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/pos/getPos/${element.pos_id}`
        );
        console.log(res.data);
        return res.data;
      });

      const billItemsData = await Promise.all(promises);

      // Flatten the array of arrays into a single array
      const flattenedBillItems = billItemsData.flat();

      // Update billItems state after all promises are resolved
      setBillItems(flattenedBillItems);
    } catch (error) {
      console.error("Error fetching bill items:", error);
    }
  };

  useEffect(() => {
    GetBillItems();
  }, [todayBills]);

  const [totalBilItems, setTotalBillItems] = useState();

  function calculateTotalQtyPerItem(items) {
    const itemTotals = {};
    items.forEach((item) => {
      // billItems
      const itemName = item.item_name;
      const itemQty = parseInt(item.pos_item_qty);

      // console.log('bill items',billItems)
      if (itemTotals[itemName]) {
        itemTotals[itemName] += itemQty;
      } else {
        itemTotals[itemName] = itemQty;
        // console.log(itemQty)
      }
      console.log(itemTotals);
    });
    // Convert itemTotals object into an array of objects
    const resultArray = Object.keys(itemTotals).map((key) => ({
      name: key,
      qty: itemTotals[key],
    }));
    setTotalBillItems(resultArray);
    // console.log(resultArray);
  }

  useEffect(() => {
    // console.log(billItems)
    calculateTotalQtyPerItem(billItems);
  }, [billItems]);

  //get all available items
  const [AvailableItems, setAvailableItems] = useState([]);
  const [TotalAvailableItems, setTotalAvailableItems] = useState([]);
  const GetAllAvailableItems = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/inventory/Shadow/Branch/Status/SellStatus/BRANCH-06247866/ACTIVE/true`
      );
      console.log(res.data);
      setAvailableItems(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetAllAvailableItems();
  }, []);

  useEffect(() => {
    calculateTotalQtyPerItem(AvailableItems);
  }, [AvailableItems]);

  function calculateTotalQtyPerItem(items) {
    const itemTotals = {};
    items.forEach((item) => {
      const itemName = item.item_name;
      const itemQty = parseInt(item.shadow_qty);
      if (itemTotals[itemName]) {
        itemTotals[itemName] += itemQty;
      } else {
        itemTotals[itemName] = itemQty;
      }
    });

    // Convert itemTotals object into an array of objects
    const resultArray = Object.keys(itemTotals).map((key) => ({
      name: key,
      qty: itemTotals[key],
    }));
    setTotalAvailableItems(resultArray);
    console.log(TotalAvailableItems);
  }

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (searchQuery !== "") {
      const filtered = TotalAvailableItems.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setTotalAvailableItems(filtered);
    }
  }, [searchQuery]);

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Item View</p>
      <div className="w-full flex flex-wrap justify-between my-10 px-5">
        {hasPermission(1100) && (
          <Card1
            title1="Total"
            title2="Items"
            subTitle=""
            value={TotalAvailableItems.length}
            img={Toroid}
          />
        )}{" "}
        {hasPermission(1101) && (
          <Card1
            title1="Total Sales"
            title2=""
            subTitle="(Today)"
            info2={1111}
            value={todayTotal}
            img={Earth}
          />
        )}{" "}
        {hasPermission(1102) && (
          <Card1
            title1="Total Sold"
            title2="Items"
            subTitle="(Today)"
            info2={1111}
            value={billItems.length}
            img={Cube}
          />
        )}
      </div>
      <div className="w-full flex justify-end items-center gap-5">
        <p className="label-1">Date </p>
        <p className="label-1">:</p>
        <input
          className="w-[259px] h-[53px] rounded-[46px] border px-5"
          type="date"
          value={billDate}
          onChange={(e) => {
            setBillDate(e.target.value);
          }}
        />
      </div>
      <div className="w-full flex justify-start items-center ">
        <input
          className="w-[259px] h-[53px] rounded-[46px] border px-5"
          type="text"
          placeholder="Search item"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="w-full h-[640px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-10">
        <div className="w-full h-[72px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="font-bold text-[24px] text-[#656565] text-center w-3">
            #
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[650px]">
            Item Name
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
            Available Qty.
          </p>
        </div>
        <div className="w-full h-[530px] flex flex-col justify-around items-center px-5 overflow-y-auto">
          {TotalAvailableItems.length > 0
            ? TotalAvailableItems.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex justify-around items-center  "
                  >
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-3">
                      {index + 1}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-left  w-[650px]">
                      {item.name}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                      {item.qty}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
