import React, { useEffect, useState } from "react";
import "./addUser.css";
import axios from "axios";
import IdGenerate from "../../../utils/id_generate";

export default function AddUser() {
  const [data, setData] = useState({
    userId: IdGenerate("USER"),
    userName: "",
    userPassword: "",
    branch_id: "",
    branch_name: "",
  });

  const [branch, setBranch] = useState([]);
  const GetBranches = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/branch/all/Branch/ACTIVE`
      );
      console.log(res.data);
      setBranch(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetBranches();
  }, []);

  const SubmitHandler = async (e) => {
    const Data = {
      user_id: data.userId,
      user_name: data.userName,
      user_password: data.userPassword,
      branch_id: data.branch_id,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/add`,
        Data
      );
      console.log(res.data);
      if (res.status === 200 || res.status === 201) {
        alert("User Added Successfully");
        ResetHandler();
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("User Already Exist");
      } else if (error.response.status === 400) {
        alert("User Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("User Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const ResetHandler = () => {
    setData({
      userId: IdGenerate("USER"),
      userName: "",
      userPassword: "",
      branch_id: "",
      branch_name: "",
    });
    GetBranches();
  };
  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <p className="header-1"> Add User</p>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Enter User Details</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Id</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.userId}
              onChange={(e) => {
                setData({
                  ...data,
                  userId: e.target.value,
                });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.userName}
              onChange={(e) => {
                setData({
                  ...data,
                  userName: e.target.value,
                });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">User Password</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.userPassword}
              onChange={(e) => {
                setData({
                  ...data,
                  userPassword: e.target.value,
                });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Branch</label>
            <label className="label-1">:</label>
            <select
              className="input-1 border border-[#00000036]"
              onChange={(e) => {
                setData({
                  ...data,
                  branch_id: e.target.value.split("/")[0],
                  branch_name: e.target.value.split("/")[1],
                });
              }}
            >
              <option>Select Branch</option>
              {branch.length > 0
                ? branch.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={`${item.branch_id}/${item.branch_name}`}
                      >
                        {item.branch_name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          {/* <div className='AddUser-form-div'>
            <label className='label'>User Status</label>
            <label  className='label'>:</label>
            <select className='form-input-select'>
              <option>Select Status</option>
            </select>
          </div> */}
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-10 px-[280px]">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4" onClick={SubmitHandler}>
        <p className="text-white">ADD</p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4" onClick={ResetHandler}>
          <p className="text-white">CANCEL</p>
        </button>
      </div>
    </div>
  );
}
