/** @format */

import React from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";
const Performance_nav = () => {
  const { hasPermission } = usePermissions();
  return (
    <div className="bg-background h-screen overflow-y-auto overflow-x-hidden ">
      <div>
        <p className="text-[40px] text-headerColor font-semibold ml-6 mt-6 text-header">
          Performance
        </p>
      </div>

      <div>
        <div className="flex flex-col font-poppins font-bold ml-6 mt-4"></div>
      </div>

      <div className="mt-5 ml-6 mr-[5%]">
        <div className="grid grid-cols-3 grid-flow-col gap-4">
          {hasPermission(1030) && (
            <div className="bg-white p-5 rounded-[20px]">
              <div className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                <p>Training </p>
              </div>

              <div className="flex justify-between mt-12 items-center">
                <Link to="/training">
                  <button className="bg-primary mt-5 ml-4 text-white p-2 rounded-[30px] font-bold">
                    Click me
                  </button>
                </Link>
                <div>
                  <div className="bg-primary h-[37px] w-[13px] rounded-[22px] mr-5"></div>
                </div>
              </div>
            </div>
          )}
          {hasPermission(1031) && (
            <div className="bg-white p-5 rounded-[20px] ">
              <div className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                <p>Salary </p>
                <p>Increments</p>
              </div>

              <div className="flex justify-between items-center">
                {" "}
                <Link to="/salary-increments">
                  <button className="ml-4 mt-5 bg-primary text-white p-2 rounded-[30px] font-bold">
                    Click me
                  </button>
                </Link>
                <div className="bg-primary h-[37px] w-[13px] rounded-[22px] mr-5"></div>
              </div>
            </div>
          )}{" "}
          {hasPermission(1032) && (
            <div className="bg-white p-5 rounded-[20px]">
              <div className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                <p>Examinations </p>
              </div>

              <div className="flex justify-between mt-10 items-center ">
                <Link to="/examinations">
                  <button className=" ml-4 mt-5 bg-primary p-2 rounded-[30px] text-white font-bold">
                    Click me
                  </button>
                </Link>
                <div className="bg-primary h-[37px] w-[13px] rounded-[22px] mr-5"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Performance_nav;
