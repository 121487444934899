import React, { useState } from "react";

const PopupBox = ({ onClose, fetchData }) => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [formData, setFormData] = useState({
    training_name: "",
    training_type: "",
    institute: "",
    duration: "",
    cost_of_the_training: "",
    participated_employees: "",
    date: "",
    training_status: "UPCOMING",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async () => {
    try {
      console.log("Form Data:", formData);
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/training/addtraining`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const responseData = await response.json(); // Parse the JSON response

      if (response.ok) {
        console.log("Training added successfully!");
        alert("Training added successfully!");
        onClose();
        fetchData();
      } else {
        console.error("Failed to add training:", response.status);
        // Check if the error message is about the training name already existing
        if (
          responseData.error &&
          responseData.error === "Training name already exists"
        ) {
          alert("Error: Training name already exists!");
        } else {
        }
      }
    } catch (error) {
      console.error("Error adding training:", error);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6">
        <p className="font-sans text-left text-[36px] font-bold mb-4 text-[#797C80]">
          Add Training Details{" "}
          <hr className="line border-t border-gray-300 w-[55%]" />
        </p>

        {/* Form */}
        <div>
          <div className="grid grid-cols-2 gap-[80px]">
            {/* Section 01 */}
            <div>
              <label className="font-sans">Training Name</label>
              <div className="mb-5">
                <input
                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  type="text"
                  id="training_name"
                  placeholder="Enter training name"
                  value={formData.training_name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label className="font-sans">Institute</label>
                <div>
                  <input
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    type="text"
                    id="institute"
                    placeholder="Enter institute name"
                    value={formData.institute}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mt-5">
                <label className="font-sans">Cost for the training (LKR)</label>
                <div>
                  <input
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    type="text"
                    id="cost_of_the_training"
                    placeholder="Enter cost of training"
                    value={formData.cost_of_the_training}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* Section 02 */}
            <div>
              <label className="font-sans">Training Type</label>
              <div className="mb-5">
                <input
                  className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                  type="text"
                  id="training_type"
                  placeholder="Enter training type"
                  value={formData.training_type}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label className="font-sans">Duration (Days)</label>
                <div>
                  <input
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    type="text"
                    id="duration"
                    placeholder="Enter duration in days"
                    value={formData.duration}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mt-5">
                <label className="font-sans">Participated Employees</label>
                <div>
                  <input
                    className="w-[100%]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                    type="text"
                    id="participated_employees"
                    placeholder="Enter number of participated employees"
                    value={formData.participated_employees}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="mt-[-50px]">
                <div>
                  <p className="font-sans">Select dates</p>
                </div>
                <div className="flex justify-between items-center gap-3 font-sans font-semibold ">
                  <div>
                    <input
                      type="date"
                      required=""
                      id="date"
                      placeholder="Select date"
                      className="w-[300px]  h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-white placeholder-white sm:text-[20px] px-2 font-normal font-sans"
                      value={formData.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center gap-4">
          <div>
            <button
              onClick={onClose}
              className="text-black font-bold py-2 px-4 mt-4 rounded-[32px]"
            >
              Close
            </button>
          </div>
          <div className="mt-[2%]">
            <button
              className="bg-primary w-[3rem] h-[30px] text-white rounded-[20px]"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupBox;
