/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../../components/permissions/permission";

const LatenessPermission = () => {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchData = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start date and end date.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/attendence/get-attendance?startDate=${startDate}&endDate=${endDate}&status=abnormal`
      );
      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // Filter data based on search query
  const filteredData = data.filter((item) =>
    item.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when search query changes
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleGetAttendanceData = () => {
    fetchData();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-background h-full overflow-y-auto">
      <div>
        <p className="text-headerColor text-[25px] font-sans ml-6 mt-6 font-bold mb-8">
          Lateness Permission
        </p>
      </div>

      <div className="flex justify-between items-center mt-[-50px]">
        {/* Search section */}
        <div>
          <div>
            <div className="flex flex-col">
              <div className="flex gap-3 ml-5 items-center ">
                <div>
                  <label
                    htmlFor="nic_number"
                    className="text-sm mb-2  font-bold text-[#797C80] font-sans"
                  >
                    Search by EMP No
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter EMP No"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  />
                </div>
              </div>
              <div className="flex gap-3 ml-5 items-center mt-3">
                <div>
                  <label
                    htmlFor="start_date"
                    className="text-sm mb-2 font-bold text-[#797C80] font-sans"
                  >
                    Start Date
                  </label>
                </div>
                <div>
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="w-[200px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  />
                </div>
                <div>
                  <label
                    htmlFor="end_date"
                    className="text-sm mb-2 font-bold text-[#797C80] font-sans"
                  >
                    End Date
                  </label>
                </div>
                <div>
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="w-[200px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  />
                </div>
                <div>
                  <button
                    onClick={handleGetAttendanceData}
                    className="bg-primary text-white px-4 py-2 rounded-md"
                  >
                    Get Attendance Data
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {hasPermission(2030) && (
          <div className="p-[50px] mt-20">
            {/* <div>
              <div className="bg-white p-5 rounded-xl relative bg-">
                <p className="font-bold text-[#797C80] text-[32px] ">
                  View Document <br />
                  Log
                </p>

                <div className="flex justify-between items-center mt-2">
                  <Link to="/leave-doc">
                    <div>
                      <button
                        className="bg-primary p-2 rounded-[50px] font-sans font-semibold text-white mt-2"
                        //   onClick={() => setIsOpen(true)}
                      >
                        Click me
                      </button>
                    </div>
                  </Link>
                  <div>
                    <div className="bg-primary p-2 rounded-[22px] h-[37px]"></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        )}
      </div>

      {/* Permission Sheet */}
      <div>
        <div className="flex flex-col font-sans font-bold ml-6 mt-3 rounded-xl mr-[5%] ">
          <div>
            <p className="text-[16px] text-[black] mb-3">Permission Sheet</p>
            <hr className="w-[5%] border-b-2 border-primary mb-5 " />
          </div>
          <table className="table-auto border-none ">
            <thead>
              <tr className="bg-white text-[20px] ">
                <th className="text-primary px-4 py-2  ">Employee ID</th>
                <th className="text-primary px-4 py-2">Check-in Time</th>
                <th className="text-primary px-4 py-2">Check-in Type</th>
                <th className="text-primary px-4 py-2">Check-out Time</th>
                <th className="text-primary px-4 py-2">Check-out Type</th>
                <th className="text-primary px-4 py-2">Remark</th>
                <th className="text-primary px-4 py-2">Grace Time</th>
                <th className="text-primary px-4 py-2">Status</th>
                <th className="text-primary px-4 py-2">Time Table End</th>
                <th className="text-primary px-4 py-2">OT</th>
                {hasPermission(2031) && (
                  <th className="text-primary px-4 py-2">View Document</th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.employee_id}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.checkIN_time}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.checkIN_type}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.checkOUT_time}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.checkOUT_type}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.remark}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.grace_time}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.status}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.timeTable_end}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">{item.OT}</td>
                  {hasPermission(2031) && (
                    <td className="bg-white px-4 py-2 text-center">
                      <button
                        className="bg-primary p-1 rounded-md text-white w-[70px]"
                        onClick={""}
                      >
                        View
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-center mt-4 mb-5">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md "
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
};

export default LatenessPermission;
