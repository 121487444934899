import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logoImage from "../../Assets/icons/login-new.png";
import erpLogo from "../../Assets/icons/erpp.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Tick_Submit from "../../Assets//icons/Tick-Submit.png";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLogInConfirmation, setShowLogInConfirmation] = useState(false);

  // Hardcoded credentials and token
  const hardcodedEmail = "user@example.com";
  const hardcodedPassword = "user123";
  const hardcodedToken =
    "1-100-101-102-103-104-1070-1080-1090-1091-1092-1100-1101-1102-2-110-111-112-113-114-115-116-117-118-119-1200-1201-1202-1210-1211-1220-2300-2301-1221-2310-2311-1230-1231-1240-1241-1270-1280-1281-1282-1290-3-120-121-122-123-124-125-1300-2400-2401-1310-2410-2411-1320-2420-1330-2430-1340-1350-2450-1360-1370-1400-1410-1411-1420-1430-2500-2501-2502-2503-2504-1440-2510-1450-1460-1470-2520-2521-2522-4-130-131-132-133-134-135-136-137-1000-1001-2000-2001-2002-2003-1002-1010-2010-1011-2020-2021-2022-1012-2030-2031-1013-2040-1014-2050-1020-2060-1021-2061-1022-2062-1023-1024-1030-2070-10000-2071-10001-2072-1031-2080-2081-1032-2090-10010-10011-2091-10012-10013-10014-2092-1040-2100-2101-1041-2110-2111-1042-2120-2121-1050-1051-1052-1053-1060-2200-2201-1061-1062-1063-1064-1065-1066-1067-5-140-141-1500-1510-6-150-151-152-1550-1560-1570-1580-7-160-161-8-170-171-1600-1610-1620-9-180-181-1640-1650-1660-10-190-191-1670-1680-1690-11-200-201-1700-1710-1720-12-210-211-13-220-221-1730-1740-1750-14-230-231-1760-1770-1780-15-240-241-242-243-244-245-1790-1800-2600-2601-2602-1810-2610-2611-2612-1820-1830-1840-2620-2621-2622-1850-2630-2631-2632-1860-2640-2641-2642-2643-1870-2650-10030-10031-2651-2652-10040-10041-2653-2654-2655-2656-1880-2660-2661-10050-10051-2662-2663-1890-2670-2671-10060-10061-2672-10070-10071-1900-2680-2681-1910-2700-2701-1920-2710-2711-1930-2720-1940-2730-2731-1950-1951-1952-1953-1954-1955-16-250-251-1960-1961-1962-1970-1971-1972-17-260-261-1980-1981-1982-1983-1984-1985-18-270-1990-1991-2740-1992-2750";

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if the entered credentials match the hardcoded ones
    if (email === hardcodedEmail && password === hardcodedPassword) {
      // Store the token and other user details in cookies
      Cookies.set("username", email);
      Cookies.set("token", hardcodedToken); // Store the hardcoded token
      Cookies.set("employee_no", "12345");
      Cookies.set("employee_fullname", "John Doe");
      Cookies.set("employee_name_initial", "JD");
      Cookies.set("employee_nic", "123456789V");

      setLoginError("");
      setShowLogInConfirmation(true);

      // Set a timeout to navigate to the dashboard after 1 second
      setTimeout(() => {
        setShowLogInConfirmation(false);
        navigate("/dashboard");
      }, 1000);
    } else {
      setLoginError("Invalid email or password. Please try again.");
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div className="flex gap-[100px] items-center bg-gray-100 overflow-hidden">
      <div className="mr-2">
        <img
          src={logoImage}
          alt="Logo"
          className="w-[550px] h-[850px] rounded-b-[179px] ml-20 mr-20"
        />
      </div>

      <div className="w-1/2 p-8">
        <div className="">
          <img src={erpLogo} alt="Logo" className="w-[200px] mb-5" />
        </div>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Employee Login
        </h2>
        <div className="mb-4">
          <p className="text-sm font-sans font-semibold">
            Login to access your Ayurveda account
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email input */}
          <div className="flex flex-col w-fit static">
            <label
              htmlFor="email"
              className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-gray-100 rounded-[20px] w-fit"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
            />
          </div>

          <div className="flex flex-col w-fit static">
            <label
              htmlFor="password"
              className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-gray-100 rounded-[20px] w-fit z-10"
            >
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-[10px] focus:outline-none"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FiEyeOff className="text-[#252C58] h-[20px] w-[20px]" />
                ) : (
                  <FiEye className="text-[#252C58] h-[20px] w-[20px]" />
                )}
              </button>
            </div>
          </div>
          <div className="mt-[20px] text-red-500">
            {loginError && <p>{loginError}</p>}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input type="checkbox" id="remember" className="mr-2" />
              <label htmlFor="remember" className="text-sm text-gray-600">
                Remember me
              </label>
            </div>
          </div>
          <div className="mt-[80px]">
            <button
              type="submit"
              className="w-[512px] bg-lime-500 text-[25px] text-white font-bold py-2 focus:outline-none rounded-[63px] mt-10"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      {showLogInConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="bg-white p-6 mt-10 rounded-[20px] w-[30%]">
            <p className="mb-4 font-semibold text-[20px]">Login Successful</p>
            <img
              className="w-[40px] h-[60px] ml-[70%] -mt-10 text-center"
              src={Tick_Submit}
              alt="submit-tick"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
