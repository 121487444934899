import React from "react";
import warning from "../../../Assets/icons/warning.png";

const Inventory_Not_Matched = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white h-[600px] w-[500px] rounded-md">
        <div className="text-center">
          <img
            src={warning}
            alt="Warning Icon"
            className="w-20 h-20 mx-auto mt-4 mb-4"
          />
          <h2 className="text-lg font-bold">Non Approved Rows</h2>
          <p className="text-sm">Please Approve it with inventory.</p>
        </div>
        <div className="flex justify-center mt-4">
          <button
            onClick={onClose}
            className="bg-gray-300 px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Inventory_Not_Matched;
