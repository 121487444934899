 

import React, { useState, useEffect } from "react";
import "./updatedesignation.css";
import { GrPrevious, GrNext } from "react-icons/gr";
import { IoCloseSharp } from "react-icons/io5";
import usePermissions from "../../../components/permissions/permission";

export default function UpdateDesignation() {
  const { hasPermission } = usePermissions();
  const [designationData, setDesignationData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    action: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [filter, setFilter] = useState({
    department: "",
    designation: "",
  });
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  useEffect(() => {
    applyFilters(); // Apply filters whenever the filter state changes
  }, [filter, designationData]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/designations/getdesignation`
      );
      const data = await response.json();
      setDesignationData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const applyFilters = () => {
    const { department, designation } = filter;
    let data = [...designationData];

    if (department) {
      data = data.filter((item) =>
        item.department.toLowerCase().includes(department.toLowerCase())
      );
    }

    if (designation) {
      data = data.filter((item) =>
        item.designation.toLowerCase().includes(designation.toLowerCase())
      );
    }

    setFilteredData(data);
    setCurrentPage(1); // Reset to the first page when filters are applied
  };

  const handleEditDesignation = (designation) => {
    setSelectedDesignation(designation);
    setEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedDesignation((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleUpdateDesignation = () => {
    setConfirmationDialog({ isOpen: true, action: "update" });
  };

  const handleDeleteDesignation = (designation) => {
    setSelectedDesignation(designation);
    setConfirmationDialog({ isOpen: true, action: "delete" });
  };

  const confirmApprovalChange = async () => {
    setConfirmationDialog({ isOpen: false });
    if (confirmationDialog.action === "update") {
      try {
        if (!selectedDesignation || !selectedDesignation.designation) {
          console.error("Selected designation is missing.");
          return;
        } else if (!selectedDesignation.id) {
          console.error("Selected Id missing");
          console.log("All Data:", selectedDesignation);
        }
        console.log("ID: ", selectedDesignation.id);

        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/designations/updatedesignation?id=${selectedDesignation.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(selectedDesignation),
          }
        );
        if (response.ok) {
          console.log("Designation updated successfully!");
          setEditModalOpen(false);
          fetchData();
        } else {
          console.error(
            `Failed to update designation. Server responded with status ${response.status}.`
          );
          const errorData = await response.json(); // Parse and log any additional error data from the server
          console.error("Error details:", errorData);
        }
      } catch (error) {
        console.error("Error updating designation:", error);
      }
    } else if (confirmationDialog.action === "delete") {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/designations/deletedesignation?id=${selectedDesignation.id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          console.log("Designation deleted successfully!");
          fetchData();
        } else {
          console.error(
            `Failed to delete designation. Server responded with status ${response.status}.`
          );
          const errorData = await response.json(); // Parse and log any additional error data from the server
          console.error("Error details:", errorData);
        }
      } catch (error) {
        console.error("Error deleting designation:", error);
      }
    }
  };

  const cancelApprovalChange = () => {
    setConfirmationDialog({ isOpen: false });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <p className="header-1">View & Update Designation</p>

      <div className="flex justify-between mt-10">
        <div>
          <div className="flex flex-col">
            <label className="text-xl mb-2 font-bold text-[#797C80] font-sans">
              Department
            </label>
            <input
              type="text"
              name="department"
              value={filter.department}
              onChange={handleFilterChange}
              className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
            />
          </div>

          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <label className="text-xl mb-2 font-bold text-[#797C80] font-sans">
              Designation
            </label>
            <input
              type="text"
              name="designation"
              value={filter.designation}
              onChange={handleFilterChange}
              className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
            />
          </div>
        </div>
      </div>

      {/* Designation Table */}
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none mt-10">
          <thead>
            <tr className="bg-white">
              <th className="text-PrimaryColor1 px-4 py-2">Department</th>
              <th className="text-PrimaryColor1 px-4 py-2">Designation Name</th>
              <th className="text-PrimaryColor1 px-4 py-2">
                Short Leave Count
              </th>
              <th className="text-PrimaryColor1 px-4 py-2">Half Day Count</th>
              <th className="text-PrimaryColor1 px-4 py-2">
                Annual Leave Count
              </th>
              <th className="text-PrimaryColor1 px-4 py-2">
                Casual Leave Count
              </th>
              <th className="text-PrimaryColor1 px-4 py-2">
                Medical Leave Count
              </th>
              <th className="text-PrimaryColor1 px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((designation) => (
              <tr key={designation.id}>
                <td className="bg-white px-4 py-2">{designation.department}</td>
                <td className="bg-white px-4 py-2">
                  {designation.designation}
                </td>
                <td className="bg-white px-4 py-2">
                  {designation.shortLeaveCount}
                </td>
                <td className="bg-white px-4 py-2">
                  {designation.halfDayCount}
                </td>
                <td className="bg-white px-4 py-2">
                  {designation.annualLeaveCount}
                </td>
                <td className="bg-white px-4 py-2">
                  {designation.casualLeaveCount}
                </td>
                <td className="bg-white px-4 py-2">
                  {designation.medicalLeaveCount}
                </td>
                <td className="bg-white px-4 py-2">
                  {hasPermission(1740) && (
                    <button
                      className="bg-gray-300 p-2 rounded-md"
                      onClick={() => handleEditDesignation(designation)}
                    >
                      Edit
                    </button>
                  )}{" "}
                  {hasPermission(1750) && (
                    <button
                      className="bg-red-500 text-white p-2 rounded-md ml-2"
                      onClick={() => handleDeleteDesignation(designation)}
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-PrimaryColor1 text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-PrimaryColor1 px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-PrimaryColor1 text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>

      {/* Edit Designation Modal */}
      {editModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 max-w-3xl h-[50%] overflow-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">
                Update Designation Details
              </h2>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setEditModalOpen(false)}
              >
                <IoCloseSharp className="w-6 h-6" />
              </button>
            </div>
            <div>
              <div className="flex flex-col mb-4">
                <label className="label-1">Department:</label>
                <input
                  className="input-1 border border-[#00000036]"
                  type="text"
                  name="department"
                  value={selectedDesignation?.department || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="label-1">Designation Name:</label>
                <input
                  className="input-1 border border-[#00000036]"
                  type="text"
                  name="designation"
                  value={selectedDesignation?.designation || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="label-1">Short Leave Count:</label>
                <input
                  className="input-1 border border-[#00000036]"
                  type="number"
                  name="shortLeaveCount"
                  value={selectedDesignation?.shortLeaveCount || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="label-1">Half Day Count:</label>
                <input
                  className="input-1 border border-[#00000036]"
                  type="number"
                  name="halfDayCount"
                  value={selectedDesignation?.halfDayCount || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="label-1">Annual Leave Count:</label>
                <input
                  className="input-1 border border-[#00000036]"
                  type="number"
                  name="annualLeaveCount"
                  value={selectedDesignation?.annualLeaveCount || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="label-1">Casual Leave Count:</label>
                <input
                  className="input-1 border border-[#00000036]"
                  type="number"
                  name="casualLeaveCount"
                  value={selectedDesignation?.casualLeaveCount || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="label-1">Medical Leave Count:</label>
                <input
                  className="input-1 border border-[#00000036]"
                  type="number"
                  name="medicalLeaveCount"
                  value={selectedDesignation?.medicalLeaveCount || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex justify-end">
                <button
                  className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
                  onClick={handleUpdateDesignation}
                >
                  <p className="p2 text-white">Update</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Dialog */}
      {confirmationDialog.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-xl mb-4">
              {confirmationDialog.action === "update"
                ? "Are you sure you want to update the designation?"
                : "Are you sure you want to delete the designation?"}
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={cancelApprovalChange}
              >
                No
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={confirmApprovalChange}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
