import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import TableActivity from "./activityTable";
import "react-datepicker/dist/react-datepicker.css";

const ActivityLog = () => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [prNo, setPrNo] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCurrentDate, setSelecteCurrentdDate] = useState(new Date());

  // Reset function
  const handleReset = () => {
    setFrom("");
    setTo("");
    setPrNo("");
    setSelectedDate(null);
    setSelecteCurrentdDate(new Date());
  };

  // Submit function
  const handleSubmit = () => {
    // Add your submit logic here
    console.log("Form submitted");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <div className="flex items-center">
          <p className="text-[40px] font-sans ml-16 mt-[1%] font-bold text-[#071C50] ">
            Activity Log
          </p>
        </div>
      </div>

      {/* Search Tab */}
      <div className="flex flex-col font-poppins font-bold mt-5 ml-16">
        <form class="form relative">
          <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              class="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            required=""
            placeholder="Search..."
            class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
            RESET
          </button>
        </form>
      </div>
      {/* Log */}
      <div className="mt-6">
        <label className=" ml-8 font-bold">Log</label>
        <hr className="max-w-6 ml-8 border-t-2 mt-2 border-gray-700"></hr>
      </div>
      {/* Table */}
      <div>
        <TableActivity />
      </div>
    </div>
  );
};

export default ActivityLog;
