/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import usePermissions from "../../../../components/permissions/permission";

const deductions = [
  { name: "Pay Tax", value: "PAY_TAX", type: "Deduction" },
  { name: "Stamp Duty", value: "stamp_duty", type: "Deduction" },
  { name: "Salary Advance", value: "salary_advance", type: "Deduction" },
  { name: "Welfare Members", value: "welfare_members", type: "Deduction" },
  { name: "Festival Advance", value: "festival_advance", type: "Deduction" },
  { name: "Bank Loan 01", value: "bank_loan_01", type: "Deduction" },
  {
    name: "Other Deductions 1",
    value: "other_deductions_1",
    type: "Deduction",
  },
  { name: "Welfare Advance", value: "welfare_advance", type: "Deduction" },
  { name: "Union 1", value: "Union_1", type: "Deduction" },
  { name: "Union 2", value: "Union_2", type: "Deduction" },
  { name: "Union 3", value: "Union_3", type: "Deduction" },
  { name: "Union 4", value: "Union_4", type: "Deduction" },
  { name: "Union 5", value: "Union_5", type: "Deduction" },
  {
    name: "Buddha Sangamaya Members",
    value: "Buddha_Sangamaya_members",
    type: "Deduction",
  },
  { name: "Sanasa Loan", value: "Sanasa_loan", type: "Deduction" },
  {
    name: "Other Deductions 2",
    value: "Other_deductions_2",
    type: "Deduction",
  },
  { name: "Welfare Loan", value: "Welfare_Loan", type: "Deduction" },
  { name: "Saving to Bank", value: "Saving_To_Bank", type: "Deduction" },
  { name: "Sanasa Member", value: "Sanasa_member", type: "Deduction" },
  { name: "Sanasa Advance", value: "Sanasa_Advance", type: "Deduction" },
  { name: "Donations", value: "Donations", type: "Deduction" },
  {
    name: "Other Deductions 3",
    value: "other_deductions_3",
    type: "Deduction",
  },
  { name: "Bank Loan 2", value: "Bank_Loan_2", type: "Deduction" },
  { name: "Distress Loan", value: "Distress_Loan", type: "Deduction" },
  {
    name: "Distress Loan Interest",
    value: "Distress_Loan_Interest",
    type: "Deduction",
  },
  { name: "No Pay", value: "no_pay", type: "Deduction" },
];

const DeductionsMenu = () => {
  const { hasPermission } = usePermissions();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleButtonClick = () => {
    navigate("/all-deduction"); // Replace '/payments' with your desired path
  };

  const filteredDeductions = deductions.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-background h-screen overflow-y-auto overflow-x-hidden">
      <div>
        <p className="ml-6 mt-6 font-sans text-[25px] font-bold text-[#797C80]">
          Deductions Menu
        </p>
      </div>

      <div className="flex justify-between items-center gap-[45%] mt-[-40px] ">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <form className="form relative mt-8">
              <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  className="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeWidth="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                required=""
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 "
              />
            </form>
          </div>
        </div>
        <div>
          {hasPermission(2061) && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6">
              <div
                key=""
                className="bg-white rounded-[32px] w-[350px] h-[180px] p-4 shadow-md flex flex-col justify-between ml-[65%]"
              >
                <div>
                  <h2 className="text-[20px] font-semibold mb-1 font-sans">
                    All Deduction
                  </h2>
                  <h2 className="text-[20px] font-semibold mb-1 font-sans">
                    Details
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                    onClick={handleButtonClick}
                  >
                    Click Here
                  </button>

                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="mt-5 ml-6 font-sans font-semibold text-[#797C80] text-[20px] mb-[30px]">
          <p className="text-[25px] font-bold">All Deductions</p>
          <div className="flex justify-center ">
            <div className="bg-white w-[80%] rounded-[39px] mt-6 mr-[5%] h-[500px] overflow-y-auto scrollbar-thin scrollbar-thumb-primary mb-5">
              <div className="ml-6 grid grid-cols-5 gap-1">
                {filteredDeductions.map((item) => (
                  <Link
                    to={`/payment_details/${item.value}/${item.name}/${item.type}`}
                    key={item.value}
                  >
                    <button className="bg-primary p-3 rounded-[20px] w-[200px] mt-8 h-[80px] text-white">
                      <p className="text-[20px]">{item.name}</p>
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeductionsMenu;
