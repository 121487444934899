 

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FiFile } from "react-icons/fi";
import DialogTenderView from "./tenderViewDialog";
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission";

const TableBidderEvaluation = ({ tednerId }) => {
  const { hasPermission } = usePermissions();
  const [biddertableData, setBidderTableData] = useState([]);
  const [tenderEvaluation, setTenderEvaluatino] = useState([]);
  const [selectedDocumentName, setSelectedDocumentName] = useState(null);
  const [changedValues, setChangedValues] = useState({});
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleCriteria = async () => {
    if (tednerId) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_evaluation_criteria/${tednerId}`
        );
        setTenderEvaluatino(response.data);
        console.log("Data:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenderEvaluatino([]);
      }
    } else {
      setTenderEvaluatino([]);
    }
  };

  const handleSearchChange = async () => {
    if (tednerId) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_bidders/${tednerId}`
        );
        setBidderTableData(response.data);
        console.log("Data:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setBidderTableData([]);
      }
    } else {
      setBidderTableData([]);
    }
  };
  const navigate = useNavigate();
  console.log("Tender ID:", tednerId);

  const [tenderViewShowDialog, setTenderViewShowDialog] = useState(false);

  const openTenderViewDialog = (documentName) => {
    setSelectedDocumentName(documentName);
    setTenderViewShowDialog(true);
  };

  const closeTenderViewDialog = () => {
    setTenderViewShowDialog(false);
    setSelectedDocumentName(null);
  };
  useEffect(() => {
    handleSearchChange();
    handleCriteria();
  }, [tednerId]);

  // Handle input changes
  const handleInputChange = (criteriaId, supplierId, value) => {
    setChangedValues((prev) => ({
      ...prev,
      [criteriaId]: {
        ...prev[criteriaId],
        [supplierId]: value,
      },
    }));
  };

  // Save changes to the backend
  const saveChanges = async () => {
    const values = [];
    for (const [criteriaId, bidders] of Object.entries(changedValues)) {
      for (const [supplierId, criteriaValue] of Object.entries(bidders)) {
        values.push({
          bidder_id: supplierId,
          criteria_id: criteriaId,
          criteria_value: criteriaValue,
        });
      }
    }

    const payload = {
      tender_id: tednerId,
      values,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_evaluation_criteria_value`,
        payload
      );
      console.log("Save response:", response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = biddertableData;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[96%] ml-10 max-h-[100%] overflow-y-auto mb-8 mt-6">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6"></th>
            <th className="px-6 py-6">%</th>
            {tableData.map((item, index) => (
              <th key={index} className="px-6 py-6">
                {item.supplier_name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="font-bold">
          {tenderEvaluation.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.criteria}</td>
              <td className="px-6 py-2">{item.percentage}</td>
              {tableData.map((bidder, index) => (
                <td key={index} className="px-6 py-6">
                  <input
                    className="border border-gray-300 rounded-[22px] text-center"
                    onChange={(e) =>
                      handleInputChange(
                        item._id,
                        bidder.supplier_id,
                        e.target.value
                      )
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {hasPermission(2656) && (
        <button
          onClick={saveChanges}
          className="mt-4 p-2 bg-blue-500 text-white rounded"
        >
          Save Changes
        </button>
      )}

      {tenderViewShowDialog && (
        <DialogTenderView
          onClose={closeTenderViewDialog}
          document={selectedDocumentName}
        />
      )}
    </div>
  );
};

export default TableBidderEvaluation;
