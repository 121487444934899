import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import axios from "axios";

const Purchase_Order_View = () => {
  const location = useLocation();
  const tenderData = location.state;
  const [PurchaseData, setPurchaseData] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const navigate = useNavigate();

  const pdfDownload = () => {
    console.log("Hello");
    navigate("/purchaseOrder/pdf", { state: { PurchaseData, purchaseItems } });
  };

  const fetchInstitutes = async (id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/purchase_order/search/po/tender/${id}/ACTIVE`
      );
      setPurchaseData(response.data[0]);
    } catch (error) {
      console.error("Error fetching institutes:", error);
    }
  };

  useEffect(() => {
    if (tenderData) {
      fetchInstitutes(tenderData.tender_id);
      itemsList(PurchaseData.po_id);
    }
  }, [tenderData, PurchaseData.po_id]);

  const itemsList = async (purid) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/purchase_order/items/${purid}`
      );
      setPurchaseItems(response.data);
      console.log("Item list:", response.data);
    } catch (error) {
      console.error("Error fetching institutes:", error);
    }
  };

  const componentRef = useRef();
  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen " ref={componentRef}>
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        View: Purchase Order
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2">
            Tender ID:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderData.tender_id}
          ></input>

          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Tender Name:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2 "
            value={tenderData.tender_name}
          ></input>
        </div>
      </div>

      {/* Second form part */}
      <div className=" flex">
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          View a Purchase Order
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">
            Purchase Order ID:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={PurchaseData.po_id}
          ></input>
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Suppliers:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={PurchaseData.supplier_name}
          ></input>
        </div>
        <div></div>
        <div></div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Issue Date :
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={PurchaseData.po_issue_date}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Reference No :
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={PurchaseData.reference_no}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Delivery Date :
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={PurchaseData.delivery_date}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Address :
          </p>
        </div>
        <div className=" col-span-2">
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8 w-full mb-2 "
            value={PurchaseData.address}
          ></input>
        </div>
      </div>

      {/* Third form part */}
      <div>
        {purchaseItems.map((item, index) => (
          <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
            <div className="flex items-center justify-end mt-2">
              <p className="text-[22px] text-[#8691AB] font-bold ml-6">
                Item ID:
              </p>
            </div>
            <div>
              <input
                className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 "
                value={item.item_id}
              ></input>
            </div>
            <div className="flex items-center justify-end mt-2">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
                Unit:
              </p>
            </div>
            <div>
              {" "}
              <input
                className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
                value={item.item_measure_unit}
              ></input>
            </div>
            <div className="flex items-center justify-end">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
                Quantity :
              </p>
            </div>
            <div>
              {" "}
              <input
                className=" rounded-[22px] border border-gray-300  ml-10 h-8"
                value={item.item_qty}
              ></input>
            </div>
            <div className="flex items-center justify-end ">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
                Rate :
              </p>
            </div>
            <div>
              {" "}
              <input
                className=" rounded-[22px] border border-gray-300 ml-10 h-8"
                value={item.rate}
              ></input>
            </div>

            <div className="flex items-center justify-end ">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
                Amount :
              </p>
            </div>
            <div className=" col-span-2">
              <input
                className=" rounded-[22px] border border-gray-300 ml-10 h-8 mb-2"
                value={item.amount}
              ></input>
            </div>
          </div>
        ))}
      </div>

      {/* Forth form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">
            Total Order:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 "
            value={PurchaseData.total_order}
          ></input>
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Total Discount:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={PurchaseData.total_discount}
          ></input>
        </div>
        <div className="flex items-center justify-end">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Total VAT :
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8"
            value={PurchaseData.total_vat}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">Total :</p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={PurchaseData.total}
          ></input>
        </div>

        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Amount In Words :
          </p>
        </div>
        <div className=" col-span-2">
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8 mb-2 w-full"
            value={PurchaseData.amount_in_words}
          ></input>
        </div>
      </div>

      <div className=" flex justify-center gap-10 w-[95%] ml-6 mt-6">
        {/* First Drag and drop box */}
        <div className="">
          <p className="text-black font-bold ml-2 mt-2 text-[24px] flex justify-center">
            Prepared By
          </p>
          <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6 w-[200px] h-[200px]">
            <img
              src={`${REACT_APP_API_ENDPOINT}/v1/inventory/purchase_order/get/file/${PurchaseData.prepared_img}`}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          </div>
        </div>

        {/* Second Drag and drop box */}
        <div className="">
          <p className="text-black font-bold ml-2 mt-2 text-[24px] flex justify-center">
            Checked By
          </p>
          <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6 w-[200px] h-[200px]">
            <img
              src={`${REACT_APP_API_ENDPOINT}/v1/inventory/purchase_order/get/file/${PurchaseData.checked_img}`}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          </div>
        </div>

        {/* Theird Drag and drop box */}
        <div className="">
          <p className="text-black font-bold ml-2 mt-2 text-[24px] flex justify-center">
            Approved By
          </p>
          <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6 w-[200px] h-[200px]">
            <img
              src={`${REACT_APP_API_ENDPOINT}/v1/inventory/purchase_order/get/file/${PurchaseData.approved_img}`}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          </div>
        </div>
      </div>

      <div className="mb-6 mt-6 mr-10 flex justify-center">
        <button
          className="bg-[#797C80] text-[20px] font-bold text-white rounded-[33px] w-[12%] h-8 print:hidden"
          onClick={pdfDownload}
        >
          Export as PDF
        </button>
      </div>
    </div>
  );
};

export default Purchase_Order_View;
