/** @format */

import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import PDF from "../../../../Assets/icons/png.png";
import Add_Training_popup from "./add_training_popup";
import Feedback_Popup from "./feedback_popup";
import Edit_Popup from "./edit_popup";
import fileIcon from "../../../../Assets/icons/png.png";
import usePermissions from "../../../../components/permissions/permission";

const Upcoming_Training = () => {
  const { hasPermission } = usePermissions();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [trainingData, setTrainingData] = useState([]);
  const [selectedTrainingType, setSelectedTrainingType] =
    useState("Type Of Training");
  const [institute, setInstitute] = useState("");
  const [fetchKey, setFetchKey] = useState(0);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  const toggleViewPopup = () => setIsViewPopupOpen(!isViewPopupOpen);
  const [isEditPopupOPen, setIsEditPopupOPen] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/training/gettraining`
      );
      const data = await response.json();
      const upcomingTrainings = data.filter(
        (training) => training.training_status === "UPCOMING"
      );
      setTrainingData(upcomingTrainings);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchKey]);

  const handleTrainingTypeChange = (e) => {
    setSelectedTrainingType(e.target.value);
  };

  const handleInstituteChange = (e) => {
    setInstitute(e.target.value);
  };

  const upcomingTrainingData = trainingData.filter(
    (training) => training.training_status === "UPCOMING"
  );

  const filteredTrainingData = upcomingTrainingData.filter((training) => {
    const matchesType =
      selectedTrainingType === "Type Of Training" ||
      training.training_type === selectedTrainingType;
    const matchesInstitute =
      institute === "" ||
      training.institute.toLowerCase().includes(institute.toLowerCase());
    return matchesType && matchesInstitute;
  });

  const uniqueTrainingTypes = Array.from(
    new Set(upcomingTrainingData.map((item) => item.training_type))
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTrainingData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const toggleEditPopup = () => {
    setIsEditPopupOPen(!isEditPopupOPen);
  };

  const handleDownload = (filePath) => {
    if (!filePath) {
      alert("No file available for download.");
      return;
    }

    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${encodeURIComponent(
      filePath
    )}`;
    window.location.href = downloadUrl;
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          Upcoming Trainings
        </p>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <div>
            <div className="mt-3">
              <div>
                <div className="flex flex-col">
                  <select
                    value={selectedTrainingType}
                    onChange={handleTrainingTypeChange}
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  >
                    <option>Type Of Training</option>
                    {uniqueTrainingTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    value={institute}
                    onChange={handleInstituteChange}
                    placeholder="Institute"
                    className="mt-4 input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 ml-6"
                  />
                </div>
              </div>
            </div>
          </div>
          {hasPermission(10001) && (
            <div className="mr-[5%]">
              <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%] ml-[%] ">
                <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
                  <p>Create an </p>
                  <p>Upcoming Training</p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={togglePopup}
                    className="bg-primary mt-5 ml-4 p-2 font-bold rounded-[32px] text-white"
                  >
                    Click Here
                  </button>
                  {isPopupOpen && (
                    // eslint-disable-next-line react/jsx-pascal-case
                    <Add_Training_popup
                      onClose={() => {
                        togglePopup(); // Close the Edit_Popup
                        setFetchKey((prevKey) => prevKey + 1); // Increment fetchKey to trigger refetch
                      }}
                      fetchKey={fetchKey}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Table of filtered training data */}
        <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
          <table className="table-auto border-none w-[95%]  ">
            <thead>
              <tr className="bg-white ">
                <th className="text-primary">Training Name</th>
                <th className="text-primary ">Type Of Training</th>
                <th className="text-primary ">Institute</th>
                <th className="text-primary ">Duration</th>
                <th className="text-primary ">Cost of the Training</th>
                <th className="text-primary px-4 py-2 ">
                  Participated employees
                </th>

                <th className="text-primary px-4 py-2 ">Feedback</th>
                <th className="text-primary px-4 py-2 ">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((training, index) => (
                <tr key={index}>
                  <td className="bg-white px-4 py-2 text-center">
                    {training.training_name}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {training.training_type}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {training.institute}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {training.duration}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {training.cost_of_the_training}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {training.participated_employees}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    <div>
                      <button
                        onClick={toggleViewPopup}
                        className="bg-gray-300 text-white p-2 rounded-[10px] "
                      >
                        {" "}
                        <div className="items-center">
                          <div
                            onClick={() =>
                              handleDownload(
                                training.training_feedback_file_path
                              )
                            }
                          >
                            {training.training_feedback_file_path ? (
                              <div className="flex gap-2 items-center">
                                <img
                                  src={fileIcon}
                                  alt="File Icon"
                                  className="w-7 h-7 cursor-pointer"
                                />
                                <div className="cursor-pointer">
                                  {training.training_feedback_file_name}
                                </div>
                              </div>
                            ) : (
                              <span>No file available</span>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                    {isViewPopupOpen && (
                      <Feedback_Popup onClose={toggleViewPopup} />
                    )}
                  </td>
                  <td className="bg-white px-4 py-2 ">
                    <div className="flex justify-between ">
                      <div>
                        <button
                          onClick={toggleEditPopup}
                          className="bg-primary rounded-[10px] p-3 text-white"
                        >
                          Edit
                        </button>
                        {/* Render Edit Popup */}
                        {isEditPopupOPen && (
                          // eslint-disable-next-line react/jsx-pascal-case
                          <Edit_Popup
                            trainingData={{
                              training_id: training.training_id,
                              training_name: training.training_name,
                              training_type: training.training_type,
                              institute: training.institute,
                              duration: training.duration,
                              cost_of_the_training:
                                training.cost_of_the_training,
                              participated_employees:
                                training.participated_employees,
                              date: training.date,
                              training_status: training.training_status,
                              training_feedback_file_path:
                                training.training_feedback_file_path,
                              training_feedback_file_name:
                                training.training_feedback_file_name,
                            }}
                            onClose={() => {
                              toggleEditPopup(); // Close the Edit_Popup
                              setFetchKey((prevKey) => prevKey + 1); // Increment fetchKey to trigger refetch
                            }}
                            fetchKey={fetchKey}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrPrevious className="w-3 h-3" />
          </button>
          {[
            ...Array(Math.ceil(filteredTrainingData.length / itemsPerPage)),
          ].map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage ===
              Math.ceil(filteredTrainingData.length / itemsPerPage)
            }
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrNext className="w-3 h-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Upcoming_Training;
