import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import cloudiccon from "../../../../Assets/icons/cloud-add.png";
import axios from "axios";

const IdGenerate = (name) => {
  const timestamp = Date.now().toString(); // Get current timestamp
  const random = Math.random().toString().substr(2, 4); // Get 4 random digits
  const uniqueID = timestamp.substr(-4) + random; // Combine timestamp and random digits
  const id = `${name}-${uniqueID}`; // Combine name and unique ID
  return id;
};

const DialogBoxEnterDecision = ({ onClose }) => {
  const navigate = useNavigate();
  const [decisionId, setdecisionId] = useState(IdGenerate("Dec"));
  const [file, setFile] = useState(null);
  const [decisionName, setdecisionName] = useState("");
  const [date, setdate] = useState(new Date());
  const { REACT_APP_API_ENDPOINT } = process.env;
  const addFile = (file) => {
    setFile(file);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    try {
      formData.append("decision_id", decisionId);
      formData.append("decision_name", decisionName);
      formData.append(
        "decision_updated_date",
        date.toLocaleDateString("en-GB")
      );

      formData.append("decision_updated_user_id", "EMP-0004");
      // Add the single file to the formData
      if (file) {
        formData.append("file", file);
      }
      console.log("FIle:", file);
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/decision`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Tender successfully added");
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add tender");
    }
  };

  // Reset function
  const handleReset = () => {
    setdecisionId("");
    setdecisionName("");
    setdate(new Date());
    setFile(null);
  };

  const handleCancel = () => {
    setFile(null);
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    addFile(selectedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    addFile(droppedFile);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[80%] h-[85%] min-h-[650px]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-dialogHeader font-poppins font-semibold text-[26px] text-center ">
            Enter Decision Details
          </h2>
        </div>
        <div className="flex justify-center items-center h-[80%]">
          <div className="bg-dialogBoxbg rounded-[19px] w-[80%] h-[95%] flex-shrink-0">
            <div className="flex justify-center items-center h-[100%]">
              <div className="bg-dialogBoxbg rounded-[19px] w-[100%] h-[100%] flex-shrink-0">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl">
                      Decision Name:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-lg px-2 py-2 ml-2"
                      value={decisionName}
                      onChange={(e) => setdecisionName(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="mr-4 text-black font-poppins font-bold text-xl">
                      Date:
                    </label>
                    <div className="relative">
                      <DatePicker
                        className="border border-gray-300 rounded-lg px-2 py-2 mr-2"
                        placeholderText="Select Date"
                        selected={date}
                        onChange={(date) => setdate(date)}
                      />
                      <FaCalendarAlt className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                  </div>
                </div>
                <div className="mt-[4%] flex items-center justify-center">
                  <div>
                    <h2 className="text-dialogHeader font-poppins font-semibold text-[26px] text-center">
                      Upload Decision Documents
                    </h2>
                    <hr className=" ml-[20%] w-[60%] border-solid border-b-2 border-black"></hr>
                  </div>
                </div>
                <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[80%] h-[90%] flex-shrink-0 mt-5 ml-[10%]">
                  <main className="container mx-auto max-w-screen-lg h-full">
                    {/* File upload modal */}
                    <article className="relative h-full flex flex-col rounded-md">
                      {/* Scroll area */}
                      <section className="h-full overflow-auto p-8 w-full flex flex-col">
                        <div className="flex items-center">
                          <img
                            src={cloudiccon}
                            alt="Cloud Icon"
                            className="h-10 w-10 rounded-full border border-gray-600"
                          />

                          <div>
                            <p className="text-gray-800 font-inter font-medium text-lg ml-2">
                              Upload files
                            </p>
                            <p className="text-dialogSubmenu-500 text-gray-400 font-inter font-medium text-sm ml-2">
                              Select and upload the files of your choice
                            </p>
                          </div>
                        </div>
                        <hr className="  w-[100%] border-solid border-b-2 border-grayColor mt-2"></hr>

                        <header
                          className="border-dashed border-2 bg-dialogboxFileup border-gray-400 py-12 flex flex-col justify-center items-center mt-2 rounded-[26px]"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center items-center flex-col">
                            <div>
                              <img
                                src={cloudiccon}
                                alt="Cloud Icon"
                                className="h-10 w-10 rounded-full"
                              />
                            </div>
                            <span>
                              Choose a PDF file or drag & drop it here
                            </span>
                            &nbsp;
                            <span className="text-gray-400">
                              files anywhere or
                            </span>
                          </p>
                          <input
                            id="hidden-input"
                            type="file"
                            className="hidden"
                            onChange={handleFileInputChange}
                          />
                          <button
                            id="button"
                            className="mt-2 rounded-[16px] px-3 py-1 bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                            onClick={() =>
                              document.getElementById("hidden-input").click()
                            }
                          >
                            Browse File
                          </button>
                        </header>

                        {/* Uploaded files list */}
                        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                          Uploaded File
                        </h1>
                        {file && (
                          <div className="block p-1 w-full h-24">
                            <article
                              tabIndex="0"
                              className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                            >
                              <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                <h1 className="flex-1 group-hover:text-blue-800">
                                  {file.name}
                                </h1>
                                <div className="flex">
                                  <span className="p-1 text-blue-800">
                                    <i>
                                      <svg
                                        className="fill-current w-4 h-4 ml-auto pt-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                      </svg>
                                    </i>
                                  </span>
                                  <p className="p-1 size text-xs text-gray-700">
                                    {file.size > 1024
                                      ? file.size > 1048576
                                        ? Math.round(file.size / 1048576) + "mb"
                                        : Math.round(file.size / 1024) + "kb"
                                      : file.size + "b"}
                                  </p>
                                </div>
                              </section>
                            </article>
                          </div>
                        )}
                      </section>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end px-8 pb-8 mt-[50%] ">
            <button
              id="submit"
              className="rounded-lg px-3 py-1 bg-addBtn hover:bg-green-800 text-white text-lg focus:shadow-outline focus:outline-none"
              onClick={handleSubmit}
            >
              Add
            </button>
            <button
              id="cancel"
              className="ml-3 rounded-lg px-3 py-1 hover:bg-gray-300 text-black focus:shadow-outline focus:outline-none text-lg"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
        {/* Sticky footer */}
      </div>
    </div>
  );
};

export default DialogBoxEnterDecision;
