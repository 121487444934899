/** @format */

import React, { useState, useEffect, useCallback } from "react";

const HRIS_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const permissionHierarchy = {
    4: { children: [130, 131, 132, 133, 134, 135, 136, 137] },
    130: { children: [1000, 1001, 1002] },
    131: { children: [1010, 1011, 1012, 1013, 1014] },
    132: { children: [1020, 1021] },
    133: { children: [1030, 1031, 1032] },
    134: { children: [1040, 1041, 1042] },
    135: { children: [1011] },
    136: { children: [1060, 1061] },
    137: { children: [1062, 1063, 1064, 1065, 1066, 1067, 1068] },
    1068: { children: [1069, 1071] },
    1001: { children: [2000, 2001, 2002, 2003] },
    1010: { children: [2010] },
    1011: { children: [2020, 2021, 2022] },
    1012: { children: [2030, 2031] },
    1013: { children: [2040] },
    1014: { children: [2050] },
    1020: { children: [2060] },
    1030: { children: [2070, 2071, 2072] },
    1031: { children: [2080, 2081] },
    1032: { children: [2090, 2091, 2092] },
    1040: { children: [2100, 2101] },
    1041: { children: [2110, 2111] },
    1042: { children: [2120, 2121] },
    1060: { children: [2200, 2201] },
    2070: { children: [10000] },
    2071: { children: [10001] },
    2090: { children: [10010, 10011] },
    2091: { children: [10012, 10013, 10014] },
    // Add the rest of your permissions here
  };

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (
            permissionHierarchy[key] &&
            permissionHierarchy[key].children.includes(value)
          ) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
    console.log("Key Values", checkedValues);
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure HRIS permission ID (4) is included if any HRIS permission is selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[4].children.includes(perm)
      ) &&
      !selectedPermissions.includes(4)
    ) {
      selectedPermissions.push(4);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues]);

  return (
    <div>
      <div>
        <div className="">
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">HRIS</p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Employee
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1000"
                      checked={!!checkedValues[1000]}
                      onChange={() => handleCheckboxChange(1000)}
                    />
                    Employee Quick OnBoard
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1001"
                      checked={!!checkedValues[1001]}
                      onChange={() => handleCheckboxChange(1001)}
                    />
                    Export Details
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2000"
                          checked={!!checkedValues[2000]}
                          onChange={() => handleCheckboxChange(2000, 1001)}
                        />
                        View & Edit
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2001"
                          checked={!!checkedValues[2001]}
                          onChange={() => handleCheckboxChange(2001, 1001)}
                        />
                        Download
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2002"
                          checked={!!checkedValues[2002]}
                          onChange={() => handleCheckboxChange(2002, 1001)}
                        />
                        Export csv
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2003"
                          checked={!!checkedValues[2003]}
                          onChange={() => handleCheckboxChange(2003, 1001)}
                        />
                        Upcoming Retirement
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1002"
                      checked={!!checkedValues[1002]}
                      onChange={() => handleCheckboxChange(1002)}
                    />
                    History Log Edits
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Attendance
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1010"
                      checked={!!checkedValues[1010]}
                      onChange={() => handleCheckboxChange(1010)}
                    />
                    In Time & Out-Time
                  </label>
                  <div className=" ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2010"
                        checked={!!checkedValues[2010]}
                        onChange={() => handleCheckboxChange(2010, 1010)}
                      />
                      Track Now
                    </label>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1012"
                      checked={!!checkedValues[1012]}
                      onChange={() => handleCheckboxChange(1012)}
                    />
                    Permission Grant (Lateness)
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2030"
                          checked={!!checkedValues[2030]}
                          onChange={() => handleCheckboxChange(2030, 1012)}
                        />
                        View Doc
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2031"
                          checked={!!checkedValues[2031]}
                          onChange={() => handleCheckboxChange(2031, 1012)}
                        />
                        Edit
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1013"
                      checked={!!checkedValues[1013]}
                      onChange={() => handleCheckboxChange(1013)}
                    />
                    Over Time (OT)
                  </label>
                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2040"
                        checked={!!checkedValues[2040]}
                        onChange={() => handleCheckboxChange(2040, 1013)}
                      />
                      Sent
                    </label>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1014"
                      checked={!!checkedValues[1014]}
                      onChange={() => handleCheckboxChange(1014)}
                    />
                    TimeTable Management
                  </label>
                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2050"
                        checked={!!checkedValues[2050]}
                        onChange={() => handleCheckboxChange(2050, 1014)}
                      />
                      Add
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Payroll
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1020"
                      checked={!!checkedValues[1020]}
                      onChange={() => handleCheckboxChange(1020)}
                    />
                    Payroll Allowances
                  </label>
                  <div className=" ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2060"
                        checked={!!checkedValues[2060]}
                        onChange={() => handleCheckboxChange(2060, 1020)}
                      />
                      View Payment Details
                    </label>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1021"
                      checked={!!checkedValues[1021]}
                      onChange={() => handleCheckboxChange(1021)}
                    />
                    Payroll Deductions
                  </label>
                  <div className=" ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2061"
                        checked={!!checkedValues[2061]}
                        onChange={() => handleCheckboxChange(2061, 1021)}
                      />
                      View Payment Details
                    </label>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1022"
                      checked={!!checkedValues[1022]}
                      onChange={() => handleCheckboxChange(1022)}
                    />
                    Monthend Payroll
                  </label>
                  <div className=" ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2062"
                        checked={!!checkedValues[2062]}
                        onChange={() => handleCheckboxChange(2062, 1022)}
                      />
                      Generate Monthly Payroll
                    </label>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1023"
                      checked={!!checkedValues[1023]}
                      onChange={() => handleCheckboxChange(1023)}
                    />
                    Intensive Payroll (OT)
                  </label>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1024"
                      checked={!!checkedValues[1024]}
                      onChange={() => handleCheckboxChange(1024)}
                    />
                    Generated Payroll Management
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Performance
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1030"
                      checked={!!checkedValues[1030]}
                      onChange={() => handleCheckboxChange(1030)}
                    />
                    Training
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2070"
                          checked={!!checkedValues[2070]}
                          onChange={() => handleCheckboxChange(2070, 1030)}
                        />
                        Completed Trainings
                      </label>
                      <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10000"
                              checked={!!checkedValues[10000]}
                              onChange={() =>
                                handleCheckboxChange(10000, 2070, 1030)
                              }
                            />
                            Add Upcoming Training
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2071"
                          checked={!!checkedValues[2071]}
                          onChange={() => handleCheckboxChange(2071, 1030)}
                        />
                        Upcoming Training
                      </label>
                      <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10001"
                              checked={!!checkedValues[10001]}
                              onChange={() =>
                                handleCheckboxChange(10001, 2071, 1030)
                              }
                            />
                            Add Upcoming Training
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2072"
                          checked={!!checkedValues[2072]}
                          onChange={() => handleCheckboxChange(2072, 1030)}
                        />
                        Training Count
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1031"
                      checked={!!checkedValues[1031]}
                      onChange={() => handleCheckboxChange(1031)}
                    />
                    Salary Increments
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2080"
                          checked={!!checkedValues[2080]}
                          onChange={() => handleCheckboxChange(2080, 1031)}
                        />
                        Approve
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2081"
                          checked={!!checkedValues[2081]}
                          onChange={() => handleCheckboxChange(2081, 1031)}
                        />
                        Delete
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1032"
                      checked={!!checkedValues[1032]}
                      onChange={() => handleCheckboxChange(1032)}
                    />
                    Examinations
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2090"
                          checked={!!checkedValues[2090]}
                          onChange={() => handleCheckboxChange(2090, 1032)}
                        />
                        Completed Examinations
                      </label>
                      <div className="ml-[0%] grid grid-cols-2 grid-flow-row gap-5">
                        <div className="ml-[0%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10010"
                              checked={!!checkedValues[10010]}
                              onChange={() =>
                                handleCheckboxChange(10010, 2090, 1032)
                              }
                            />
                            Add Upcoming Examinations
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10011"
                              checked={!!checkedValues[10011]}
                              onChange={() =>
                                handleCheckboxChange(10011, 2090, 1032)
                              }
                            />
                            Delete Upcoming Examinations
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2091"
                          checked={!!checkedValues[2091]}
                          onChange={() => handleCheckboxChange(2091, 1032)}
                        />
                        Upcoming Examinations
                      </label>
                      <div className="ml-[0%] grid grid-cols-2 grid-flow-row gap-5">
                        <div className="ml-[0%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10012"
                              checked={!!checkedValues[10012]}
                              onChange={() =>
                                handleCheckboxChange(10012, 2091, 1032)
                              }
                            />
                            Add Upcoming Examinations
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10013"
                              checked={!!checkedValues[10013]}
                              onChange={() =>
                                handleCheckboxChange(10013, 2091, 1032)
                              }
                            />
                            Edit Upcoming Examinations
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10014"
                              checked={!!checkedValues[10014]}
                              onChange={() =>
                                handleCheckboxChange(10014, 2091, 1032)
                              }
                            />
                            Delete Upcoming Examinations
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2092"
                          checked={!!checkedValues[2092]}
                          onChange={() => handleCheckboxChange(2092, 1032)}
                        />
                        Examinations Count
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Reports & Analytics
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1040"
                      checked={!!checkedValues[1040]}
                      onChange={() => handleCheckboxChange(1040)}
                    />
                    Monthly Leave Reports
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2100"
                          checked={!!checkedValues[2100]}
                          onChange={() => handleCheckboxChange(2100, 1040)}
                        />
                        Approve
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2101"
                          checked={!!checkedValues[2101]}
                          onChange={() => handleCheckboxChange(2101, 1040)}
                        />
                        Edit
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1041"
                      checked={!!checkedValues[1041]}
                      onChange={() => handleCheckboxChange(1041)}
                    />
                    Medical Leave Reports
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2110"
                          checked={!!checkedValues[2110]}
                          onChange={() => handleCheckboxChange(2110, 1041)}
                        />
                        Approve
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2111"
                          checked={!!checkedValues[2111]}
                          onChange={() => handleCheckboxChange(2111, 1041)}
                        />
                        Edit
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1042"
                      checked={!!checkedValues[1042]}
                      onChange={() => handleCheckboxChange(1042)}
                    />
                    Incentive & Attendance Allowance Reports
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2120"
                          checked={!!checkedValues[2120]}
                          onChange={() => handleCheckboxChange(2120, 1042)}
                        />
                        Approve
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2121"
                          checked={!!checkedValues[2121]}
                          onChange={() => handleCheckboxChange(2121, 1042)}
                        />
                        Edit
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Leave Management
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="1011"
                    checked={!!checkedValues[1011]}
                    onChange={() => handleCheckboxChange(1011)}
                  />
                  Leaves
                </label>
                <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2020"
                        checked={!!checkedValues[2020]}
                        onChange={() => handleCheckboxChange(2020, 1011)}
                      />
                      Apply
                    </label>
                  </div>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2021"
                        checked={!!checkedValues[2021]}
                        onChange={() => handleCheckboxChange(2021, 1011)}
                      />
                      Edit
                    </label>
                  </div>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2022"
                        checked={!!checkedValues[2022]}
                        onChange={() => handleCheckboxChange(2022, 1011)}
                      />
                      Delete
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Loan Information
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1060"
                      checked={!!checkedValues[1060]}
                      onChange={() => handleCheckboxChange(1060)}
                    />
                    View Loan Information
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2200"
                          checked={!!checkedValues[2200]}
                          onChange={() => handleCheckboxChange(2200, 1060)}
                        />
                        Review Already Assigned Guarantors
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2201"
                          checked={!!checkedValues[2201]}
                          onChange={() => handleCheckboxChange(2201, 1060)}
                        />
                        Check ligibility
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1061"
                      checked={!!checkedValues[1061]}
                      onChange={() => handleCheckboxChange(1061)}
                    />
                    Review Guarants
                  </label>
                </div>
              </div>
              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Dashboard
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1062"
                      checked={!!checkedValues[1062]}
                      onChange={() => handleCheckboxChange(1062)}
                    />
                    Employee Card
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1063"
                      checked={!!checkedValues[1063]}
                      onChange={() => handleCheckboxChange(1063)}
                    />
                    Working Locations Card
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1064"
                      checked={!!checkedValues[1064]}
                      onChange={() => handleCheckboxChange(1064)}
                    />
                    Departments Card
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1065"
                      checked={!!checkedValues[1065]}
                      onChange={() => handleCheckboxChange(1065)}
                    />
                    Overall Payroll Cost Card
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1066"
                      checked={!!checkedValues[1066]}
                      onChange={() => handleCheckboxChange(1066)}
                    />
                    Average Salary Increment Card
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1067"
                      checked={!!checkedValues[1067]}
                      onChange={() => handleCheckboxChange(1067)}
                    />
                    Contact Popup
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1068"
                      checked={!!checkedValues[1068]}
                      onChange={() => handleCheckboxChange(1068)}
                    />
                    HR Documents
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1069"
                          checked={!!checkedValues[1069]}
                          onChange={() => handleCheckboxChange(1069, 1068)}
                        />
                        Upload Documents
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1071"
                          checked={!!checkedValues[1071]}
                          onChange={() => handleCheckboxChange(1071, 1068)}
                        />
                        Download Documents
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HRIS_Permission;
