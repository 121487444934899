 

import React, { useState } from "react";
import DialogBoxEnterDecision from "../Enter Decision Details/decisionDataDialog";
import TableDecisions from "./decisionsTable.jsx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission.jsx";

const Decisions_home = () => {
  const { hasPermission } = usePermissions();
  const [decisionId, setdecisionId] = useState("");
  const [data, setdata] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const navigate = useNavigate();

  const handleTenderIDChange = async (e) => {
    const id = e.target.value;
    setdecisionId(id);
    refreshData(id);
  };

  const refreshData = async (id) => {
    if (id) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/decision/search/${id}`
        );
        setdata(response.data.length > 0 ? response.data : []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setdata([]);
      }
    }
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleNavActivity = () => {
    navigate("/supply/ActivityLog");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Decisions
      </p>
      <div className="flex justify-between mt-10 mx-6">
        {hasPermission(1820) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[25%] ml-10">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Add <br /> Decisions
              </h2>
            </div>
            <div className="flex justify-end">
              <button
                onClick={openDialog}
                className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
              >
                Create
              </button>
            </div>
            {showDialog && <DialogBoxEnterDecision onClose={closeDialog} />}
          </div>
        )}{" "}
        {hasPermission(1830) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[25%] mr-10">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1 relative">
                View Activity <br /> Log
                <div className="bg-primary rounded-[64px] w-4 h-10 absolute right-[10px] top-[50%] transform -translate-y-1/2"></div>
              </h2>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleNavActivity}
                className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
              >
                Click Here
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col font-poppins font-bold mt-10 ml-16">
        <form className="form relative">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            value={decisionId}
            onChange={handleTenderIDChange}
            type="text"
            required=""
            placeholder="Search..."
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
            RESET
          </button>
        </form>
        <div className="mt-6 mr-4">
          <TableDecisions
            data={data}
            refreshData={() => refreshData(decisionId)}
          />
        </div>
      </div>
    </div>
  );
};

export default Decisions_home;
