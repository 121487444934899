import React, { useState, useEffect } from "react";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

const SectionIncentive = () => {
  const [fileSelected, setFileSelected] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [allowanceData, setAllowanceData] = useState([]);
  const [sections, setSections] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCalculateOpen, setIsCalculateOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [productionAmount, setProductionAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [isGenerateOpen, setIsGenerateOpen] = useState(false);
  const [generateMonth, setGenerateMonth] = useState("");
  const [generateYear, setGenerateYear] = useState("");
  const [sectionIncentives, setSectionIncentives] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchSectionIncentives = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/section-incentives`
      );
      const data = await response.json();
      setSectionIncentives(data);
    } catch (error) {
      console.error("Error fetching section incentives:", error);
    }
  };

  useEffect(() => {
    fetchSectionIncentives();
  }, []);

  const handleGenerateSubmit = async () => {
    const body = {
      month: parseInt(generateMonth),
      year: parseInt(generateYear),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/calculate-section-incentive`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setShowAlertMessage(false);
        setIsGenerateOpen(false); // Close the modal on success
      } else {
        setAlertMessage(data.error || data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error generating section incentive");
      setShowAlertMessage(true);
    }
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-values`
    )
      .then((response) => response.json())
      .then((data) => setAllowanceData(data))
      .catch((error) => console.error("Error fetching data:", error));

    fetchSections();
    fetchEmployees();
  }, []);

  const fetchSections = () => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/section`)
      .then((response) => response.json())
      .then((data) => setSections(data))
      .catch((error) => console.error("Error fetching sections:", error));
  };

  const fetchEmployees = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/designations/employees-by-working-days`
      );
      if (response.ok) {
        const data = await response.json();
        setEmployees(Array.isArray(data) ? data : []);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message);
      }
    } catch (error) {
      setErrorMessage("Error fetching employee data");
    }
  };

  const fetchAssignedEmployees = async (sectionId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/section-employees?section_id=${sectionId}`
      );
      const data = await response.json();
      setAssignedEmployees(Array.isArray(data) ? data : []);
      setSelectedEmployees(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching assigned employees:", error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
    setFileSelected(true);
  };

  const handleSubmit = async () => {
    const body = {
      month: parseInt(month),
      year: parseInt(year),
      production_amount: parseFloat(productionAmount),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/${
          isEdit ? "production-value?id=" + editId : "add-production-value"
        }`,
        {
          method: isEdit ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        if (isEdit) {
          setAllowanceData(
            allowanceData.map((item) => (item.id === editId ? data : item))
          );
        } else {
          setAllowanceData([...allowanceData, data]); // Update the allowanceData with new entry
        }
        setShowAlertMessage(false);
        setIsOpen(false); // Close the modal on success
        setIsEdit(false);
        setEditId(null);
      } else {
        setAlertMessage(data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error submitting data");
      setShowAlertMessage(true);
    }
  };

  const handleAssignEmployees = async () => {
    const body = {
      section_id: parseInt(sectionId),
      employees: selectedEmployees.map((e) => e.employee_no),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/assign-employees`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setShowAlertMessage(false);
        setIsOpen(false); // Close the modal on success
        setSelectedEmployees([]);
        setSectionId("");
        fetchAssignedEmployees(sectionId); // Refresh assigned employees list
      } else {
        setAlertMessage(data.error || data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error assigning employees");
      setShowAlertMessage(true);
    }
  };

  const handleUpdateAssignedEmployees = async () => {
    const body = {
      section_id: parseInt(sectionId),
      employees: selectedEmployees.map((e) => e.employee_no),
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/section-employees`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setShowAlertMessage(false);
        setIsOpen(false); // Close the modal on success
        setSelectedEmployees(Array.isArray(data) ? data : []);
        fetchAssignedEmployees(sectionId); // Refresh assigned employees list
      } else {
        setAlertMessage(data.error || data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error updating assigned employees");
      setShowAlertMessage(true);
    }
  };

  const handleCalculateIncentive = async () => {
    const body = {
      section_id: parseInt(sectionId),
      employees: assignedEmployees.map((e) => e.employee_no),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/calculate-section-incentive`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setShowAlertMessage(false);
        setIsCalculateOpen(false); // Close the modal on success
      } else {
        setAlertMessage(data.error || data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error calculating section incentive");
      setShowAlertMessage(true);
    }
  };

  const handleClearClick = () => {
    setFileSelected(false);
    setUploadedFile(null);
    setAllowanceData([]);
  };

  const handleEdit = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-value?id=${id}`
      );
      const data = await response.json();

      if (response.ok) {
        setMonth(data.month);
        setYear(data.year);
        setProductionAmount(data.production_amount);
        setEditId(id);
        setIsEdit(true);
        setIsOpen(true);
      } else {
        setAlertMessage(data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error fetching data");
      setShowAlertMessage(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-value?id=${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setAllowanceData(allowanceData.filter((item) => item.id !== id));
      } else {
        const data = await response.json();
        setAlertMessage(data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error deleting data");
      setShowAlertMessage(true);
    }
  };

  const handleSelectEmployee = (employee) => {
    if (Array.isArray(selectedEmployees)) {
      if (
        selectedEmployees.some((e) => e.employee_no === employee.employee_no)
      ) {
        setSelectedEmployees(
          selectedEmployees.filter(
            (e) => e.employee_no !== employee.employee_no
          )
        );
      } else {
        setSelectedEmployees([...selectedEmployees, employee]);
      }
    } else {
      setSelectedEmployees([employee]);
    }
  };

  const handleSectionChange = (e) => {
    setSectionId(e.target.value);
    if (e.target.value) {
      fetchAssignedEmployees(e.target.value); // Fetch assigned employees when section is selected
    } else {
      setAssignedEmployees([]); // Clear assigned employees if no section is selected
    }
  };

  const filteredEmployeeData = employees.filter(
    (employee) =>
      employee.employee_name_initial &&
      employee.employee_name_initial
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  const displayEmployees = employees.map((employee) => ({
    ...employee,
    isChecked:
      Array.isArray(selectedEmployees) &&
      selectedEmployees.some(
        (selectedEmployee) =>
          selectedEmployee.employee_no === employee.employee_no
      ),
  }));

  const handleCheckboxChange = (id, checked) => {
    setSectionIncentives((prevIncentives) =>
      prevIncentives.map((incentive) =>
        incentive.id === id
          ? { ...incentive, yes_no: checked ? "yes" : "no" }
          : incentive
      )
    );
  };

  const handleSaveChanges = async () => {
    try {
      const updates = sectionIncentives.map(async (incentive) => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/section-incentives`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: incentive.id,
              yes_no: incentive.yes_no,
            }),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || "Error updating yes_no column");
        }
      });

      await Promise.all(updates);
      setAlertMessage("Changes saved successfully");
      setShowAlertMessage(true);
    } catch (error) {
      setAlertMessage(error.message);
      setShowAlertMessage(true);
    }
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          Section Incentive
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
                <div className="relative">
                  <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                    <svg
                      className="w-5 h-5 text-gray-700"
                      aria-labelledby="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      height="16"
                      width="17"
                    >
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="1.333"
                        stroke="currentColor"
                        d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                      ></path>
                    </svg>
                  </button>
                  <input
                    type="text"
                    required=""
                    placeholder="Search by Employee"
                    className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
              <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
                <p>Assign Employees to </p>
                <p>Sections</p>
              </div>

              <div className="flex items-center justify-between">
                <label
                  htmlFor="fileInput"
                  className={`bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white cursor-pointer ${
                    fileSelected ? "hidden" : ""
                  }`}
                  onClick={() => setIsOpen(true)}
                >
                  +Assign
                </label>

                <button
                  type="button"
                  className={`bg-primary mt-5 ml-4  p-2 rounded-[32px] text-white cursor-pointer ${
                    !fileSelected ? "hidden" : ""
                  }`}
                  onClick={handleUpdateAssignedEmployees}
                >
                  Update
                </button>

                <div className="mr-5">
                  <div className="bg-primary rounded-[32px] h-[37px] w-[13px]"></div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
              <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
                <p>Calculate Section </p>
                <p>Incentive</p>
              </div>

              <div className="flex items-center justify-between">
                <label
                  htmlFor="fileInput"
                  className={`bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white cursor-pointer`}
                  onClick={() => setIsGenerateOpen(true)}
                >
                  +Calculate
                </label>
              </div>
            </div>
          </div>

          <div
            className="flex justify-end mr-[5%] mt-5 font-bold mb-5 sm:text-l"
            onClick={handleClearClick}
          ></div>
          <div className="flex flex-col font-sans font-bold ml-6 mt-2 rounded-xl">
            <table className="table-auto border-none w-[95%] text-center">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary px-4 py-2">Employee No</th>
                  <th className="text-primary px-4 py-2">Month</th>
                  <th className="text-primary px-4 py-2">Year</th>
                  <th className="text-primary px-4 py-2">Section ID</th>
                  <th className="text-primary px-4 py-2">Incentive Amount</th>
                  <th className="text-primary px-4 py-2">Yes/No</th>
                </tr>
              </thead>
              <tbody>
                {sectionIncentives.map((item) => (
                  <tr key={item.id} className="bg-white">
                    <td>{item.employee_no}</td>
                    <td>{item.month}</td>
                    <td>{item.year}</td>
                    <td>{item.section_id}</td>
                    <td>{Number(item.section_incentive_amount).toFixed(2)}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.yes_no === "yes"}
                        onChange={(e) =>
                          handleCheckboxChange(item.id, e.target.checked)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center mt-5 space-x-3">
              <button
                className="bg-primary p-2 rounded-[22px] text-white"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Assign Employee to sections
              </p>
              <div className="mt-6">
                <div>
                  <select
                    id="sectionSelect"
                    name="section"
                    value={sectionId}
                    onChange={handleSectionChange}
                    autoComplete="off"
                    className="w-[500px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-lg px-2 font-sans"
                  >
                    <option className="font-sans-bold">Select Section</option>
                    {sections.map((section) => (
                      <option key={section.id} value={section.id}>
                        {section.department_section}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">
                  <div className="grid grid-cols-2 gap-4 ">
                    <div className="mt-5">
                      <div className="form relative w-[60%] rounded-xl mb-5">
                        <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                          <svg
                            className="w-5 h-5 text-gray-700"
                            aria-labelledby="search"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            height="16"
                            width="17"
                          >
                            <path
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              strokeWidth="1.333"
                              stroke="currentColor"
                              d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                            ></path>
                          </svg>
                        </button>
                        <input
                          type="text"
                          required=""
                          placeholder="Search by Employee"
                          className="input rounded-xl border-none h-10 px-8 py-3  m-2 placeholder-gray-400"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>

                      <div className="overflow-y-auto max-h-64 mt-5">
                        <table className="table-auto w-full">
                          <thead>
                            <tr>
                              <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">
                                Employee ID
                              </th>
                              <th className="px-4 py-2 bg-[#F5F5F5] rounded-r-xl">
                                Full Name
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayEmployees.map((employee) => (
                              <tr
                                key={employee.employee_no}
                                className="border-b border-black-300"
                              >
                                <td className="px-4 py-2 flex items-center">
                                  <input
                                    type="checkbox"
                                    className="mr-2"
                                    onChange={() =>
                                      handleSelectEmployee(employee)
                                    }
                                    checked={employee.isChecked}
                                  />
                                  {employee.employee_no}
                                </td>
                                <td className="px-4 py-2">
                                  {employee.employee_fullname}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="mt-[5%] h-64 overflow-y-auto ">
                      <h3 className="text-xl ml-3 font-bold text-[#344054] mb-4">
                        Selected Employees
                      </h3>
                      <table className="table-auto w-full">
                        <thead>
                          <tr className="">
                            <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">
                              Employee Number
                            </th>
                            <th className="px-4 py-2 bg-[#F5F5F5] rounded-r-xl">
                              Full Name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedEmployees.map((employee) => (
                            <tr
                              key={employee.employee_no}
                              className="border-b border-black-300"
                            >
                              <td className="px-4 py-2">
                                {employee.employee_no}
                              </td>
                              <td className="px-4 py-2">
                                {employee.employee_fullname}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-5 space-x-3">
              <button
                className="bg-primary p-2 rounded-[22px] text-white"
                onClick={handleUpdateAssignedEmployees}
              >
                Update
              </button>
              <button
                className="bg-gray-500 p-2 rounded-[22px] text-white"
                onClick={() => {
                  setIsOpen(false);
                  setIsEdit(false);
                  setEditId(null);
                }}
              >
                Cancel
              </button>
            </div>
            {showAlertMessage && (
              <div className="text-red-600 text-sm2 mt-2 ml-5">
                {alertMessage}
              </div>
            )}
          </div>
        </div>
      )}

      {isGenerateOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Generate Section Incentive
              </p>

              <div className="mt-5 flex flex-col">
                <div className="flex flex-row items-center">
                  <div>
                    <p className="font-sans font-semibold">Month :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      min="1"
                      max="12"
                      value={generateMonth}
                      onChange={(e) => setGenerateMonth(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.month && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.month}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center mt-4">
                  <div>
                    <p className="font-sans font-semibold">Year :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      value={generateYear}
                      onChange={(e) => setGenerateYear(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.year && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.year}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-primary p-2 rounded-[22px] text-white"
                  onClick={handleGenerateSubmit}
                >
                  Submit
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white"
                  onClick={() => setIsGenerateOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionIncentive;
