import React from "react";
import Error from "../Assets/icons/404.png";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history
  };

  return (
    <div className="font-sans overflow-y-hidden">
      <img className="w-screen h-screen" src={Error} alt="logo-icon" />
      <div className="justify-center flex mt-[-10%]">
        <button
          className="bg-[#5B6D49] p-2 text-white w-[250px] rounded-[15px]"
          onClick={handleGoBack} // Add onClick handler to invoke navigate
        >
          <div className="flex items-center text-[24px] justify-center">
            <div className="flex gap-2 items-center">
              {" "}
              <FaArrowLeftLong />
              Go Back
            </div>
            <div></div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
