 

import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "./updateStore.css";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

import Arrow from "../../../Assets/icons/down-arrow.png";

export default function UpdateStores() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [resultsShow, setResultsShow] = useState(false);

  const [results, setResults] = useState([]);
  const [stores, setStores] = useState([]);
  useEffect(() => {
    const fetchStores = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/store`
        );
        console.log(res.data);
        setStores(res.data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchStores();
  }, []);

  const [data, setData] = useState({
    store_id: "",
    store_name: "",
    store_description: "",
    store_update_date: "",
    store_update_user_id: "",
    store_status: "",
  });
  const GetStore = (selectedOption) => {
    const store = stores.find(
      (store) => store.store_id === selectedOption.value
    );
    if (store) {
      setData({
        ...data,
        store_id: store.store_id || "",
        store_name: store.store_name || "",
        store_description: store.store_description || "",
        store_update_date: store.store_update_date || "",
        store_update_user_id: store.store_update_user_id || "",
        store_status: store.store_status || "",
      });
    }
  };

  const UpdateItemHandler = async () => {
    if (data.store_id !== "") {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/store/${data.store_id}`,
          {
            data: data,
            user_id: "USER-00001",
            update_date: formattedDate,
          }
        );
        console.log(res.data);
        if (res.status === 200 || res.status === 201) {
          alert("Store Updated Successfully");
          window.location.reload();
        }
      } catch (error) {
        if (error.response.status === 409) {
          alert("Store Already Exist");
        } else if (error.response.status === 400) {
          alert("Store Not Updated");
        } else if (error.response.status === 500) {
          alert("Internal Server Error");
        } else if (error.response.status === 404) {
          alert("Store Not Found");
        } else if (error.response.status === 403) {
          alert("Forbidden");
        } else if (error.response.status === 401) {
          alert("Unauthorized");
        }
      }
    }
  };

  const DeleteItemHandler = async () => {
    if (data.store_id !== "") {
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/store/${data.store_id}`
        );
        console.log(res.data);
        if (res.status === 200 || res.status === 201) {
          alert("Store Deleted Successfully");
        }
      } catch (error) {
        if (error.response.status === 409) {
          alert("Store Already Exist");
        } else if (error.response.status === 400) {
          alert("Store Not Deleted");
        } else if (error.response.status === 500) {
          alert("Internal Server Error");
        } else if (error.response.status === 404) {
          alert("Store Not Found");
        } else if (error.response.status === 403) {
          alert("Forbidden");
        } else if (error.response.status === 401) {
          alert("Unauthorized");
        }
      }
    }
  };

  const CancelItemHandler = () => {};
  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  return (
    <div className=" bg-background h-screen overflow-y-auto">
      <p className="header-1"> Update Stores</p>
      <div className="flex gap-10 items-center py-5 px-10 w-[800px] mt-10 bg-[#F3F8FF] rounded-[46px] border border-[#00000036]">
        <p className="label-1"> find store</p>
        <p className="label-1">:</p>
        {hasPermission(1700) && (
          <Select
            className="w-80"
            styles={customStyles}
            options={stores.map((store) => ({
              value: store.store_id,
              label: store.store_name,
            }))}
            onChange={GetStore}
          />
        )}
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Update Stores</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Id</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              disabled
              value={data.store_id}
              onChange={(e) => {
                setData({ ...data, store_id: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Store Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={data.store_name}
              onChange={(e) => {
                setData({ ...data, store_name: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Stores Description</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={data.store_description}
              onChange={(e) => {
                setData({ ...data, store_description: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-end gap-x-6 mb-10 px-[150px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={CancelItemHandler}
        >
          <p className="p2 text-white">Cancel</p>
        </button>
        {hasPermission(1720) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={DeleteItemHandler}
          >
            <p className="p2 text-white">Delete</p>
          </button>
        )}
        {hasPermission(1710) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={UpdateItemHandler}
          >
            <p className="p2 text-white">Update</p>
          </button>
        )}
      </div>
    </div>
  );
}
