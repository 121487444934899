import React, { useState, useEffect } from "react";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditDialog from "./EditDialog"; // Import your dialog box component

const TableEditUpdate = ({ tenderIdValue }) => {
  const [members, setMembers] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/committee/members/${tenderIdValue}/ACTIVE`
        );
        if (response.status === 200) {
          setMembers(response.data);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, [tenderIdValue]);

  const openEditDialog = (row) => {
    setSelectedRow(row);
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedRow(null);
  };

  const handleEdit = async (updatedRow) => {
    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/committee/members/update/a/member/${selectedRow._id}`,
        updatedRow
      );
      if (response.status === 200) {
        // Handle successful edit
        console.log("Member updated successfully!");
        // You can also update the state to reflect the changes immediately if needed
      }
    } catch (error) {
      console.error("Error updating member:", error);
    }
  };

  const handleDelete = async (row) => {
    const updatedRow = {
      ...row,
      taken_decision: "delete",
    };
    setSelectedRow(row);
    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/committee/members/update/a/member/${row._id}`,

        updatedRow
      );
      if (response.status === 200) {
        console.log("Member deleted successfully");
        alert("Member deleted successfully");
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member._id !== row._id)
        );
      }
    } catch (error) {
      console.error("Member deletion failed", error);
      alert("Member deletion failed");
    }
  };

  return (
    <div>
      <div className="bg-[#FFF] rounded-[20px] p-4 w-[90%] ml-10 max-h-[100%] overflow-y-auto mb-8">
        <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
          <thead>
            <tr className="text-left bg-white text-primary font-bold">
              <th className="px-6 py-6">Employee ID</th>
              <th className="px-6 py-6">Employee Name</th>
              <th className="px-6 py-6">Appointing Date</th>
              <th className="px-6 py-6"></th>
              <th className="px-6 py-6">
                Send for <br /> Approval
              </th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {members.map((member, index) => (
              <tr
                key={index}
                className={`${
                  index !== members.length - 1 ? "border-b border-gray-300" : ""
                }`}
              >
                <td className="px-6 py-2">{member.committee_user_id}</td>
                <td className="px-6 py-2">{member.committee_role}</td>
                <td className="px-6 py-2">
                  {member.committee_appointing_date}
                </td>
                <td className="px-6 py-2">
                  <div className="grid grid-cols-2 items-center">
                    <button
                      onClick={() => openEditDialog(member)}
                      className="bg-[#ADB4BE] text-white font-bold  rounded-[22px] min-w-[50px]"
                    >
                      Edit
                    </button>
                    <button
                      className="ml-4"
                      onClick={() => handleDelete(member)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </div>
                </td>
                <td className="px-6 py-2">
                  <button
                    // onClick={openDialog}
                    className="bg-primary text-white font-bold  rounded-[22px] min-w-[50px]"
                  >
                    Send
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {editDialogOpen && (
        <EditDialog
          member={selectedRow}
          onClose={closeEditDialog}
          onEdit={handleEdit}
        />
      )}
    </div>
  );
};

export default TableEditUpdate;
