 

import React from "react";
import { Link } from "react-router-dom"; // Import for anchor tag if needed
import usePermissions from "../../../../components/permissions/permission";

const TableInsideCard = ({ data, navigate }) => {
  const { hasPermission } = usePermissions();
  const handleViewClick = () => {
    navigate("/purchase/request");
  };

  const getButtonStyle = (status) => {
    switch (status) {
      case "Approved":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "Rejected":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      default:
        return ""; // Default style
    }
  };

  // Dummy table data array
  const dummyData = [
    {
      prNo: "111",
      requestTitle: "Text 1",
      department: "Text 1",
      status: "Approved",
    },
    {
      prNo: "222",
      requestTitle: "Text 2",
      department: "Text 2",
      status: "Rejected",
    },
    // Add more rows as needed
  ];

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data || dummyData;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 ">
      <table className="w-full border-collapse border-none">
        <thead>
          <tr className="text-left bg-[#FFF] ">
            <th className="px-4 py-2 font-normal">Pr No</th>
            <th className="px-4 py-2 font-normal">Request Title</th>
            <th className="px-4 py-2 font-normal">Department</th>
            <th className="px-4 py-2 font-normal">Decision</th>
            <th className="px-4 py-2 font-normal"></th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr key={index}>
              <td className="px-4 py-2">{item.prNo}</td>
              <td className="px-4 py-2">{item.requestTitle}</td>
              <td className="px-4 py-2">{item.department}</td>
              <td className="px-4 py-2">
                <button
                  className={`inline-block ${getButtonStyle(
                    item.status
                  )} text-[#5B6D49]`}
                >
                  {item.status}
                </button>
              </td>
              <td className="px-4 py-2">
                {hasPermission(2600) && (
                  <Link
                    to="/purchase/viewPurchaseRequest"
                    className="text-approveStattxt hover:underline"
                    onClick={handleViewClick}
                  >
                    View
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableInsideCard;
