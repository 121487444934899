import React, { useState, useEffect } from "react";
import Add_Training_popup from "./add_training_popup";
import Edit_Popup from "./edit_popup";
import fileIcon from "../../../../Assets/icons/png.png";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../../components/permissions/permission";

const Completed_Training = () => {
  const { hasPermission } = usePermissions();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [traningData, setTraningData] = useState([]);
  const [fetchKey, setFetchKey] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const [selectedTraining, setSelectedTraining] = useState(null);
  const [selectedTrainingType, setSelectedTrainingType] =
    useState("Type Of Training");
  const [institute, setInstitute] = useState("");

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleViewPopup = (training) => {
    setSelectedTraining(training);
    setIsViewPopupOpen(!isViewPopupOpen);
  };

  const toggleEditPopup = (training) => {
    setSelectedTraining(training);
    setIsEditPopupOpen(!isEditPopupOpen);
  };

  // Define a function to fetch data
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/performance/training/gettraining`
      );
      const data = await response.json();
      setTraningData(data);
    } catch (error) {
      console.error("Error fetching exam data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchKey]);

  const handleDownload = (filePath) => {
    if (!filePath) {
      alert("No file available for download.");
      return;
    }

    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${encodeURIComponent(
      filePath
    )}`;
    window.location.href = downloadUrl;
  };

  const filteredTrainingData = traningData.filter((training) => {
    const matchesType =
      selectedTrainingType === "Type Of Training" ||
      training.training_type === selectedTrainingType;
    const matchesInstitute =
      institute === "" ||
      training.institute.toLowerCase().includes(institute.toLowerCase());
    return matchesType && matchesInstitute;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTrainingData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          Completed Training
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div className="mt-3">
                <div className="flex flex-col">
                  <label className="text-xl mb-2 font-bold text-[#797C80] font-sans"></label>
                  <select
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    value={selectedTrainingType}
                    onChange={(e) => setSelectedTrainingType(e.target.value)}
                  >
                    <option className="font-sans-bold">Type Of Training</option>
                    {traningData.map((training, index) => (
                      <option key={index} value={training.training_type}>
                        {training.training_type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
                <form className="form relative">
                  <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                    <svg
                      className="w-5 h-5 text-gray-700"
                      aria-labelledby="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      height="16"
                      width="17"
                    >
                      <path
                        stroke-linejoin="round"
                        stroke-linecap="round"
                        stroke-width="1.333"
                        stroke="currentColor"
                        d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                      ></path>
                    </svg>
                  </button>
                  <input
                    type="text"
                    required=""
                    placeholder="Institute"
                    value={institute}
                    onChange={(e) => setInstitute(e.target.value)}
                    className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  />
                </form>
              </div>
            </div>
            {hasPermission(10000) && (
              <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
                <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
                  <p>Create an </p>
                  <p>Upcoming Training</p>
                </div>

                <div className="flex items-center justify-between">
                  <button
                    onClick={togglePopup}
                    className="bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white font-bold"
                  >
                    Click Here
                  </button>
                  {isPopupOpen && (
                    // eslint-disable-next-line react/jsx-pascal-case
                    <Add_Training_popup
                      onClose={() => {
                        togglePopup(); // Close the Edit_Popup
                        setFetchKey((prevKey) => prevKey + 1); // Increment fetchKey to trigger refetch
                      }}
                      fetchKey={fetchKey}
                    />
                  )}

                  <div className="mr-5">
                    <div className="bg-primary rounded-[32px] h-[37px] w-[13px]"></div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
            <table className="table-auto border-none w-[95%]">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary justify-center text-[15px] font-bold py-4">
                    Training Name
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Type Of Training
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Institute
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Duration
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Cost of the Training
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Participated employees
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Feedback
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((training) => (
                  <tr key={training.id} className="bg-white text-center">
                    <td className="py-4">{training.training_name}</td>
                    <td className="py-4">{training.training_type}</td>
                    <td className="py-4">{training.institute}</td>
                    <td className="py-4">{training.duration}</td>
                    <td className="py-4">{training.cost_of_the_training}</td>
                    <td className="py-4">{training.participated_employees}</td>
                    <td className="py-4">
                      <button
                        onClick={() => toggleViewPopup(training)}
                        className="bg-[#ADB4BE] text-white p-2 rounded-[10px]"
                      >
                        View
                      </button>
                      {isViewPopupOpen && selectedTraining === training && (
                        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
                          <div className="bg-white rounded-lg p-6">
                            <div className="bg-[#E0EEDF] rounded-[28px] w-[250px]">
                              <p className="text-[#797C80] text-[20px] font-bold font-sans text-center">
                                Feedback
                              </p>
                              <div
                                className="p-5"
                                onClick={() =>
                                  handleDownload(
                                    training.training_feedback_file_path
                                  )
                                }
                              >
                                {training.training_feedback_file_path ? (
                                  <div className="flex gap-5 items-center">
                                    <img
                                      src={fileIcon}
                                      alt="File Icon"
                                      className="w-7 h-7 cursor-pointer"
                                    />
                                    <div className="cursor-pointer">
                                      {training.training_feedback_file_name}
                                    </div>
                                  </div>
                                ) : (
                                  <span>No file available</span>
                                )}
                              </div>
                            </div>
                            <button
                              onClick={() => setIsViewPopupOpen(false)}
                              className="bg-[#9CAC8B] text-white mt-4 p-2 rounded-[20px]"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="py-4">
                      <button
                        onClick={() => toggleEditPopup(training)}
                        className="bg-primary rounded-[10px] p-2 text-white"
                      >
                        Edit
                      </button>
                      {isEditPopupOpen && selectedTraining === training && (
                        <Edit_Popup
                          trainingData={{
                            training_id: training.training_id,
                            training_name: training.training_name,
                            training_type: training.training_type,
                            institute: training.institute,
                            duration: training.duration,
                            cost_of_the_training: training.cost_of_the_training,
                            participated_employees:
                              training.participated_employees,
                            date: training.date,
                            training_status: training.training_status,
                            training_feedback_file_path:
                              training.training_feedback_file_path,
                            training_feedback_file_name:
                              training.training_feedback_file_name,
                          }}
                          onClose={() => {
                            toggleEditPopup(); // Close the Edit_Popup
                            setFetchKey((prevKey) => prevKey + 1); // Increment fetchKey to trigger refetch
                          }}
                          fetchKey={fetchKey}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mb-4">
          <div className="flex justify-center mt-4">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrPrevious className="w-3 h-3" />
            </button>
            {Array.from({
              length: Math.ceil(filteredTrainingData.length / itemsPerPage),
            }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`${
                  currentPage === index + 1
                    ? "bg-primary text-white"
                    : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(filteredTrainingData.length / itemsPerPage)
              }
              className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
            >
              <GrNext className="w-3 h-3" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Completed_Training;
