import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';  

export default function DisposedItems() {

    const [dateStart,setDateStart] = useState('');
    const [dateEnd,setDateEnd] = useState('');
    const [items,setItems] = useState([])

    const SearchByDateRange = async() => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/dispose/disposedItemsByDateRange/${dateStart}/${dateEnd}`)
            // console.log(res.data)
            setItems(res.data)

        } catch (error) {

        }
    
    };


  const SearchHandler =async (e) => {
    if(e !== ""){
      try {
        console.log(e)
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/inventory/searchInventory/ItemName/ShadowQty/Branch/${e}/BRANCH-06247866 `)
        console.log(res.data)
        setItems(res.data)
        const options = res.data.map(item => ({
          value: item.inventory_batch_id,
          label: item.item_name // Customize label as needed
        }));
        return options;
        
      } catch (error) {
        
      }

    }
  }

  const SelectHandler =async (inventory_id) =>{
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/inventory/inventory/InventoryBatchId/${inventory_id.value}`) 
      console.log(res.data) 
      
   

    } catch (error) {
      
    }
  }

  const customStyles = {
    control: (baseStyles, state) => ({ // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: 'white',
      borderRadius:"100px",
    //   borderColor: state.isFocused ? 'grey' : 'red',
    //   border:state.isFocused ? 'none' : 'none',
    //   borderColor:'black'


    }),
    valueContainer: (provided) => ({ // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: 'transparent',


    }),
    input: (provided) => ({ // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: 'transparent',
      placeholder: 'Search',
      

    }),
    singleValue: (provided) => ({ // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: 'transparent',

    }),
    indicatorSeparator: (provided) => ({ // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: 'transparent',


      borderRadius: '100px',
    }),
    dropdownIndicator: (provided) => ({ // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: 'transparent',

    }),

    
    menu: (provided) => ({ // 'menu' is from the div class too.
      ...provided,
      background: 'white',

    }),
    Option: (provided) => ({
      ...provided,
      background: 'red',
    }),
  };
  return (
    <div className='p-10 bg-background'>
        <p className='header-1'>Inventory Dispose</p>

        <div className='w-full flex justify-start items-center mt-10 gap-x-5'>
            <div className='w-[500px] h-[82px] flex justify-between items-center  bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]'>
                <p className='label-1'>date start :</p>
                <input type='date' className='w-[250px] h-[50px] rounded-[46px] border border-[#00000036] text-center'
                value={dateStart} onChange={(e)=>setDateStart(e.target.value)}/>
            </div>
            <div className='w-[500px] h-[82px] flex justify-between items-center  bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]'>
                <p className='label-1'>date end :</p>
                <input type='date' className='w-[250px] h-[50px] rounded-[46px] border border-[#00000036] text-center'
                value={dateEnd} onChange={(e)=>setDateEnd(e.target.value)}/>
            </div>
            <button  className='w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 p2 text-white'
            onClick={SearchByDateRange}>Search</button>
        </div>


        <div className='flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10'>
          <div className='w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5'>
                <p className='w-10 p1 text-[#656565] text-center'>#</p>
                <div className='w-[350px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>Item Name</p><div className='border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1'></div></div>
                <div className='w-[180px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>Disposed Qty</p><div className='border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1'></div></div>
                <div className='w-[50px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>unit</p><div className='border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1'></div></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>Location</p><div className='border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1'></div></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>Remarks</p><div className='border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1'></div></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>Disposed User</p><div className='border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1'></div></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>Date</p><div className='border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1'></div></div>
          </div>

          <div className='flex flex-col gap-y-5 my-3 h-[450px] overflow-y-scroll'>
          {items.length > 0 ?items.map((item, index)=>(
                <div key={index} className='w-full  flex justify-around items-start'>
                    <div className='w-10 flex items-center'><p className='w-full p1 text-[#656565] text-center '>{index+1}</p></div>
                    <div className='w-[350px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>{item.item_name}</p></div>
                <div className='w-[180px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>{item.dispose_qty}</p></div>
                <div className='w-[50px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>{item.item_measure_unit}</p></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>{item.location}</p></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>{item.dispose_item_description}</p></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>{item.user_name}</p></div>
                <div className='w-[200px] flex flex-col items-center'><p className='w-full p1 text-[#656565] text-center '>{item.dispose_date}</p></div>
                    
                

            </div>
            )):null
                }

          </div>


        </div>

    </div>
  )
}
