import React from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

const PayrollNav = () => {
  const { hasPermission } = usePermissions();

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-header ml-6 mt-6 font-bold text-[#797C80]">
        Payroll Management
      </p>
      <div className="flex ml-8 gap-12">
        {hasPermission(1022) && (
          <Link to="/monthend-payroll">
            <div className="bg-white rounded-[32px] h-[100px] w-[550px] p-4 shadow-md flex flex-col justify-between mt-[2.5%] ">
              <div className="flex justify-between items-center">
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Month-end Payroll
                </h2>
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Genarate{" "}
                </button>
              </div>
              <div className="flex justify-between items-center ml-3">
                <div className="bg-primary w-[37px] h-[13px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}
        {hasPermission(1022) && (
          <Link to="/incentive">
            <div className="bg-white rounded-[32px] h-[100px] w-[550px] p-4 shadow-md flex flex-col justify-between mt-[2.5%] ">
              <div className="flex justify-between items-center">
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Incentive Payroll
                </h2>
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Genarate{" "}
                </button>
              </div>
              <div className="flex justify-between items-center ml-3">
                <div className="bg-primary w-[37px] h-[13px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}
        {hasPermission(1024) && (
          <Link to="/generated-payroll">
            <div className="bg-white rounded-[32px] h-[100px] w-[550px] p-4 shadow-md flex flex-col justify-between mt-[2.5%] ">
              <div className="flex justify-between items-center">
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Generated Payroll{" "}
                </h2>
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Management{" "}
                </button>
              </div>
              <div className="flex justify-between items-center ml-3">
                <div className="bg-primary w-[37px] h-[13px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[2.5%] mx-6 font-sans">
        {hasPermission(1020) && (
          <Link to="/allowances">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Payroll
                </h2>
                <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                  Allowances
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}

        {hasPermission(1021) && (
          <Link to="/dedutions">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Payroll
                </h2>
                <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                  Deductions
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}

        {hasPermission(1020) && (
          <Link to="/production_value">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Production
                </h2>
                <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                  Value
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}

        {hasPermission(1021) && (
          <Link to="/section-incentive">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Section
                </h2>
                <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                  Incentive
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}

        {hasPermission(1022) && (
          <Link to="/slab">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  SLAB
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}

        {hasPermission(1023) && (
          <Link to="/special-incentive">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Special Incentive
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}

        {hasPermission(1023) && (
          <Link to="/overtime">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Overtime
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}

        {hasPermission(1024) && (
          <Link to="/leave-amount-management">
            <div className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between">
              <div>
                <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  Leave Amount
                </h2>
                <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                  Management
                </h2>
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                  Click Here
                </button>
                <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PayrollNav;
