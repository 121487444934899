import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';


import IdGenerate from '../../../utils/id_generate';
import axios from 'axios'; 

export default function InventoryAdjustment() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId,setUserId] = useState('USER-000000');
  const [branchId,setBranchId] = useState('BRANCH-000000');

  const [resultsShow,setResultShow] = useState(false);

  const [disposeId,setDisposeId] = useState(IdGenerate('DISPOSE'));

  const [tableData,setTableData] = useState([])


  const [items,setItems] = useState([]);
  const SearchItems =async (e) => {
    if(e !== ""){
      try {
        console.log(e)
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/inventory/searchInventory/ItemName/ShadowQty/Branch/${e}/BRANCH-06247866 `)
        console.log(res.data)
        setItems(res.data)
        const options = res.data.map(item => ({
          value: item.inventory_batch_id,
          label: item.item_name +'-'+ item.item_id // Customize label as needed
        }));
        return options;
        
      } catch (error) {
        
      }

    }
  }

  const SelectItemHandler =async (inventory_id) =>{
    setResultShow(false)
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/inventory/inventory/InventoryBatchId/${inventory_id.value}`) 
      console.log(res.data) 
      if(res.data.length > 0){
        res.data.map((item,index)=>{
          const data = [...tableData]
          data.push({
            item_name : item.item_name,
            item_id : item.inventory_batch_id,
            location : item.location,
            available_qty : item.shadow_qty,
            item_qty : 0,
            measure_unit: item.item_measure_unit,
            item_description : '',
          })
          setTableData(data)
        })
      }

    } catch (error) {
      
    }
  }


  const customStyles = {
    control: (baseStyles, state) => ({ // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: 'white',
      borderRadius:"100px",
    //   borderColor: state.isFocused ? 'grey' : 'red',
    //   border:state.isFocused ? 'none' : 'none',
    //   borderColor:'black'


    }),
    valueContainer: (provided) => ({ // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: 'transparent',


    }),
    input: (provided) => ({ // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: 'transparent',
      placeholder: 'Search',
      

    }),
    singleValue: (provided) => ({ // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: 'transparent',

    }),
    indicatorSeparator: (provided) => ({ // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: 'transparent',


      borderRadius: '100px',
    }),
    dropdownIndicator: (provided) => ({ // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: 'transparent',

    }),

    
    menu: (provided) => ({ // 'menu' is from the div class too.
      ...provided,
      background: 'white',

    }),
    Option: (provided) => ({
      ...provided,
      background: 'red',
    }),
  };
  return (
    <div  className='p-10 bg-background'>
      <p className='header-1'>Inventory Item Adjustment</p>

      <div className='w-full flex justify-start items-center mt-10 '>
            <div className='w-[670px] h-[82px] flex justify-between items-center gap-x-10 bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]'>
                <p className='label-1'>Request Id :</p>
                <AsyncSelect
                        className="w-80"
                        styles={customStyles}
                        loadOptions={SearchItems}
                        onChange={SelectItemHandler}
                        value={items.inventory_batch_id}
                    />
            </div>
        </div>


    </div>
  )
}
