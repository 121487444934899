import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import TableRecommendation from "./recommendationTable";
const Tender_Recommndations_View_Dialog = ({ onClose }) => {
  const navigate = useNavigate();

  const [files, setFiles] = useState({});

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[80%] h-[85%] min-h-[650px]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div className="flex justify-center items-center text-[#3A3F52] text-[41px] mt-2 font-bold">
          <div className="text-center flex flex-col">
            <p>View the Recommendation</p>
            <p>of Suppliers</p>
          </div>
        </div>

        <div>
          <form class="form relative">
            <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
              <svg
                class="w-5 h-5 text-gray-700"
                aria-labelledby="search"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16"
                width="17"
              >
                <path
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  stroke-width="1.333"
                  stroke="currentColor"
                  d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              required=""
              placeholder="Search by Tender ID/Name"
              class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
            />
            <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
              RESET
            </button>
          </form>
          <TableRecommendation />
        </div>
      </div>
    </div>
  );
};

export default Tender_Recommndations_View_Dialog;
