 

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableCategory from "./categoryTable";
import IdGenerate from "../../../../utils/id_generate";
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission";

const Supply_Category = () => {
  const { hasPermission } = usePermissions();
  const [catId, setcatId] = useState(IdGenerate("CAT"));
  const [catName, setcatName] = useState("");
  const [date, setdate] = useState(new Date());
  const [data, setdata] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const postcategory = async () => {
    const body = {
      supplier_category_id: catId,
      supplier_category_name: catName,
      supplier_category_update_date: date.toLocaleDateString("en-GB"),
      supplier_category_update_user_id: "EMP-00001",
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/supplierCategory/add`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Tender successfully added");
      reset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add tender");
    }
  };

  const refreshData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/supplierCategory/status/ACTIVE`
      );
      setdata(response.data.length > 0 ? response.data : []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setdata([]);
    }
  };

  const reset = () => {
    setcatId(IdGenerate("CAT"));
    setcatName("");
    setdate(new Date());
  };

  // Fetch data when the component mounts
  useEffect(() => {
    refreshData();
  }, []);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Supplier Category
      </p>
      <p className="text-[32px] font-sans ml-24 mt-4 font-bold text-[#071C50] ">
        Add Category
      </p>

      {/* Flex container for cards */}
      <div className="flex justify-between mt-10 mx-6">
        <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[25%] ml-[65px] w-[50%] h-[20%]">
          <div className="min-h-[110px] min-w-[150px] ">
            <h2 className="text-[26px] text-[#797C80] font-semibold relative">
              Enter Category Details
              {/* Gray color div */}
            </h2>
          </div>
          <div className="min-w-[50%] pr-4 flex items-center">
            <label className="mr-[10%] text-black font-poppins font-bold text-xl">
              Category ID:
            </label>
            <input
              readOnly
              type="text"
              value={catId}
              onChange={(e) => setcatId(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6 text-xl font-bold"
            />
          </div>
          <div className="min-w-[50%] pr-4 flex items-center mt-6">
            <label className="mr-[10%] text-black font-poppins font-bold text-xl">
              Category Name:
            </label>
            <input
              type="text"
              placeholder="Enter Name"
              value={catName}
              onChange={(e) => setcatName(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6 text-xl font-bold"
            />
            {hasPermission(2620) && (
              <button
                onClick={postcategory} // Call handleNavActivity to navigate
                className="bg-primary w-[10%] ml-10 text-white font-bold py-2 px-4 rounded-[22px]"
              >
                Add
              </button>
            )}
          </div>
          <div className="flex justify-end"></div>
        </div>
      </div>
      {/*Search Box*/}
      <div className="flex flex-col font-poppins font-bold mt-10 ml-[90px]">
        <form class="form relative">
          <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              class="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            required=""
            placeholder="Search..."
            class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
            RESET
          </button>
        </form>
        <TableCategory refreshData={() => refreshData()} data={data} />
      </div>
    </div>
  );
};

export default Supply_Category;
