import React, { useState, useEffect } from "react";

function ApprovalUserDetails() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [approveDialog, setApproveDialog] = useState({
    isOpen: false,
    itemId: null,
    description: "",
  });
  const [rejectDialog, setRejectDialog] = useState({
    isOpen: false,
    itemId: null,
    description: "",
  });

  // Fetch data function defined outside of useEffect for accessibility
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/getdataapprove2`
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const result = await response.json();
      setData(result);
    } catch (err) {
      console.error("Error fetching data:", err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openRejectDialog = (item) => {
    setRejectDialog({
      isOpen: true,
      itemId: item.id,
      description: "",
    });
  };

  const openApproveDialog = async (item) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/vieweditdata?id=${item.id}`
      );
      if (!response.ok) throw new Error("Failed to fetch employee details");
      const employeeDetails = await response.json();

      // Initialize the dialog state with fetched details and an empty description
      setApproveDialog({
        isOpen: true,
        itemId: item.id,
        description: "", // Start with an empty description
        data: {
          ...employeeDetails[0],
          approved_status_2: "APPROVED",
          approved_status_2_description: "",
        },
      });
    } catch (error) {
      console.error("Error preparing for approval:", error.message);
      setError("Error preparing for approval: " + error.message);
    }
  };

  const confirmApproval = async () => {
    const { itemId, data, description } = approveDialog;
    if (!data) return;

    // Ensure the description from the state is being included in the payload
    const updatedData = {
      ...data,
      approved_status_2_description: description,
    };

    console.log("Sending data for approval:", updatedData); // Check what is being sent

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/updateemployee?id=${itemId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) throw new Error("Failed to update employee");
      alert("Employee approved successfully");
      fetchData(); // Refresh data after update
      setApproveDialog({
        isOpen: false,
        itemId: null,
        description: "",
        data: null,
      });
    } catch (error) {
      console.error("Error updating employee: " + error.message);
    }
  };

  const handleReject = async () => {
    const { itemId, description } = rejectDialog;
    const employeeNo = data.find((item) => item.id === itemId)?.employee_no;
    if (!employeeNo) {
      alert("Missing employee number, cannot proceed with rejection");
      return;
    }

    const requestBody = {
      approved_status_2: "REJECTED",
      approved_status_2_description: description,
    };

    try {
      const url = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/updateemployeereject?id=${itemId}&employee_no=${employeeNo}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      alert("Rejection processed successfully");
      fetchData(); // Refresh data after rejection
      setRejectDialog({
        isOpen: false,
        itemId: null,
        description: "",
      });
    } catch (err) {
      console.error("Failed to reject:", err);
      alert(`Failed to reject due to an error: ${err.message}`);
    }
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Approve Two User Details Table
      </p>

      <div className="mt-5 ml-6 mb-[50px]"></div>

      <div className="flex justify-between items-center mt-[-50px]">
        <div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                className="w-[480px] h-[55px] rounded-[22px]"
                placeholder="Search By Employee Code"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <button
              className="text-headerColor font-bold"
              onClick={() => setSearchQuery("")}
            >
              Reset
            </button>
          </div>

          <div className="ml-6 mt-3">
            <select
              className="w-[480px] h-[55px] rounded-[22px] bg-white"
              value={userTypeFilter}
              onChange={(e) => setUserTypeFilter(e.target.value)}
            >
              <option value="" disabled hidden>
                Sort by Employee Department Type
              </option>
              <option value="">All</option>
              {data.map((item, index) => (
                <option key={index} value={item.leave_type}>
                  {item.leave_type}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%]">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Employee NO</th>
              <th className="text-primary px-4 py-2">Edit Description</th>
              <th className="text-primary px-4 py-2">Approve 01 Description</th>
              <th className="text-primary px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="4" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  <td className="bg-white px-4 py-2">{item.employee_no}</td>
                  <td className="bg-white px-4 py-2">
                    {item.edit_description}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {item.approved_status_1_description}
                  </td>
                  <td className="bg-white px-4 py-2">
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                      onClick={() => openApproveDialog(item)}
                    >
                      Approve
                    </button>
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded"
                      onClick={() => openRejectDialog(item)}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="bg-white px-4 py-2">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {approveDialog.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-xl mb-4">Confirm Approval</p>
            <input
              type="text"
              placeholder="Add a description (optional)" // Placeholder text gives users a hint
              value={approveDialog.description} // Binds input value to state
              onChange={(
                e // Handle changes to the input
              ) =>
                setApproveDialog({
                  ...approveDialog, // Spread existing state to retain other fields
                  description: e.target.value, // Update description with new input
                })
              }
              className="w-full p-2 border border-gray-300 rounded mb-4" // Styling classes
            />

            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() =>
                  setApproveDialog({
                    isOpen: false,
                    itemId: null,
                    description: "",
                    data: null,
                  })
                }
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={confirmApproval}
              >
                Yes, Approve
              </button>
            </div>
          </div>
        </div>
      )}

      {rejectDialog.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-xl mb-4">Confirm Rejection</p>
            <input
              type="text"
              placeholder="Add a description"
              value={rejectDialog.description}
              onChange={(e) =>
                setRejectDialog({
                  ...rejectDialog,
                  description: e.target.value,
                })
              }
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() =>
                  setRejectDialog({
                    isOpen: false,
                    itemId: null,
                    description: "",
                  })
                }
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleReject}
              >
                Yes, Reject
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApprovalUserDetails;
