import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaFilePdf } from "react-icons/fa"; // Importing an icon for the document

const DialogBoxViewDocuments = ({ onClose }) => {
  const [files, setFiles] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [tenders, setTenders] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState([]);
  const [tenderName, setTenderName] = useState("");
  const [contractId, setContractId] = useState("");
  const [bidderName, setBidderName] = useState("");
  const [tenderId, setTenderId] = useState("");
  const [note, setNote] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const navigate = useNavigate();

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_bidders/${query}`
        );
        setTenders(response.data);

        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setTenders([]);
    setIsDropdownOpen(false);
    setTenderId("");
    setContractId("");
    setNote("");
    setFiles("");
    setTenderId("");
    setBidderName("");
  };

  const handleOptionClick = (result) => {
    setSearchTerm(result.tender_id);
    setIsDropdownOpen(false);
    setTenderId(result.tender_id);
    setTenderName(result.tender_name);
    setBidderName(result.supplier_name);
    setContractId(result.supplier_id);
    setNote(result.bidder_note);

    setFiles(
      `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_bidders/file/` +
        result.bidder_document_name
    ); // Ensure this field contains the document URL
  };

  const handleDocumentClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[80%] h-[85%] min-h-[650px]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <form className="form relative mt-2">
            <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
              <svg
                className="w-5 h-5 text-gray-700"
                aria-labelledby="search"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16"
                width="17"
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="1.333"
                  stroke="currentColor"
                  d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Enter Tender ID"
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 font-bold text-center"
            />
            <button
              className="btn btn-primary text-[#797C80] hover:bg-gray-100"
              onClick={handleReset}
            >
              RESET
            </button>
            <div className="mt-4 relative">
              {isDropdownOpen && (
                <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                  {tenders.length > 0 ? (
                    tenders.map((result) => (
                      <li
                        key={result._id}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        value={selectedTender}
                        onClick={() => handleOptionClick(result)}
                      >
                        {result.tender_id}
                      </li>
                    ))
                  ) : (
                    <li className="p-2 text-gray-500">No results found</li>
                  )}
                </ul>
              )}
            </div>
          </form>
        </div>
        <div className="flex justify-center items-center h-[80%]">
          <div className="bg-dialogBoxbg rounded-[19px] w-[90%] h-[95%] flex-shrink-0">
            <div className="flex justify-center items-center h-[100%]">
              <div className="bg-dialogBoxbg rounded-[19px] w-[100%] h-[100%] flex-shrink-0">
                <div className="items-center justify-between grid grid-cols-2">
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Tender ID:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={tenderId}
                      onChange={(e) => setTenderId(e.target.value)}
                      readOnly
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Contract ID:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={contractId}
                      onChange={(e) => setContractId(e.target.value)}
                      readOnly
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Company Name:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={bidderName}
                      onChange={(e) => setBidderName(e.target.value)}
                      readOnly
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Notes:
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="h-[90%] w-[80%] bg-white mt-6 ml-[10%]">
                  {files ? (
                    <div className="flex items-center justify-center h-full">
                      <FaFilePdf
                        className="text-red-500 cursor-pointer"
                        size={100}
                        onClick={() => handleDocumentClick(files)}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-full">
                      <p>No document available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sticky footer */}
      </div>
    </div>
  );
};

export default DialogBoxViewDocuments;
