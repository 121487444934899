 

import React from "react";
import usePermissions from "../../../../components/permissions/permission";

const Salary_increments = () => {
  const { hasPermission } = usePermissions();
  return (
    <div className="bg-background overflow-y-auto h-screen font-sans ">
      <div>
        <p className="mt-6 ml-6 font-semibold text-headerColor text-[22px]">
          Salary Increments
        </p>
      </div>

      <div>
        <div>
          <div>
            <div className="flex flex-col">
              <label
                htmlFor="nic_number"
                className="text-xl mb-2 font-bold text-[#797C80] font-sans"
              ></label>
              <select
                id={``}
                name={``}
                autoComplete=""
                className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
              >
                <option className="font-sans-bold ">Department</option>
                <option>Dep 01</option>
                <option>Dep 02</option>
                <option>Dep 03</option>
              </select>
            </div>
          </div>

          <div className="mt-3">
            <div>
              <div className="flex flex-col">
                <label
                  htmlFor="nic_number"
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                ></label>
                <select
                  id={``}
                  name={``}
                  autoComplete=""
                  className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                >
                  <option className="font-sans-bold ">All</option>
                  <option>Training #</option>
                  <option>Training #</option>
                  <option>Training #</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <form class="form relative">
              <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  class="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke-width="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                required=""
                placeholder="Search..."
                class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              />
            </form>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none w-[95%]  ">
          <thead>
            <tr className="bg-white ">
              <th className="text-primary px-4 py-2 ">Request ID</th>
              <th className="text-primary px-4 py-2 ">Date</th>
              <th className="text-primary px-4 py-2 ">Authority</th>
              <th className="text-primary px-4 py-2 ">Purpose</th>

              <th className="text-primary px-4 py-2 ">Decision</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bg-white px-4 py-2 "></td>
              <td className="bg-white px-4 py-2 ">
                <p>Emp NO</p>
              </td>
              <td className="bg-white px-4 py-2 ">
                <p>full name </p>
              </td>

              <td className="bg-white px-4 py-2 "></td>

              <td className="bg-white px-4 py-2">
                <div className="flex justify-between ">
                  {hasPermission(2080) && (
                    <div>
                      <button
                        //   onClick={toggleEditPopup}
                        className="bg-primary rounded-[28px] p-2 w-[90px] text-white ml-[100%]"
                      >
                        Approve
                      </button>
                    </div>
                  )}
                  {hasPermission(2081) && (
                    <div className="mr-[100px]">
                      <button className="text-black bg-[#E2CECE] p-2 w-[80px] rounded-[25px]">
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Salary_increments;
