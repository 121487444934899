import React, { useState, useEffect } from "react";
import "./toggle.css";  // Assuming your CSS is in toggle.css

const ToggleSwitch = ({ status, onChange }) => {
  // Initialize the toggle based on the status prop
  const [checked, setChecked] = useState(status === "COMPLETED");

  useEffect(() => {
    // Update the toggle state if the trainingData prop changes
    setChecked(status === "COMPLETED");
  }, [status]);

  const handleChange = () => {
    const newCheckedState = !checked;
    setChecked(newCheckedState);
    onChange(newCheckedState); // Pass the new state back to the parent component
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className="hidden"
      />
      <span className="slider"></span>
    </label>
  );
};

export default ToggleSwitch;
