import { Outlet, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';

const UserAuth = (props) => {
    let loginStatus = sessionStorage.getItem('login');
    let user = sessionStorage.getItem('user');
    // let user_id = sessionStorage.getItem('id');
    const user_id = 'USER-52825488';

    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const GetRoles = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/userRoles/modules/${user_id}`);
                // console.log("Fetched roles data:", res.data);
                setRoles(res.data);
                setIsLoading(false); 
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        GetRoles();
    }, [user_id]);

    // console.log("props.ids:", props.ids);
    // console.log("roles:", roles);

    if (isLoading) {
        return <div>Loading...</div>; 
    }


    const isAuthorized = props.ids.some(id => roles.includes(id));


    return user_id && isAuthorized ? <Outlet /> : <Navigate to="/" />;
};

export default UserAuth;

