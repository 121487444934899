import React, { useRef } from "react";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import Drug_Logo from "../../../../Assets/icons/drug-logo.png";

const Sample_Commitee_Report = () => {
  const location = useLocation();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const tableData = location.state.testing_samples;
  const sampleData = location.state;
  console.log("PDF data:", sampleData);
  const formRef = useRef(null);

  const downloadPdf = () => {
    const input = formRef.current;
    html2canvas(input, { useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("OpeningPriceForm.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="flex justify-center mt-10">
      <button
        onClick={downloadPdf}
        className="bg-blue-500 text-white w-[100px] h-[100px] py-2 px-4 rounded"
      >
        Download Form as PDF
      </button>
      <div ref={formRef} id="form-to-print">
        <div className="py-[70px] px-[150px] font-sans">
          <div className="flex justify-center">
            <img
              className="w-[634px] h-[113px]"
              src={Drug_Logo}
              alt="drug-coop-logo"
            />
          </div>
          <div className="flex justify-center mt-8">
            <p className="font-semibold text-[#2D499B] text-[27px]">
              Sample Testing Committee Report
            </p>
          </div>
          <hr
            className="bg-[#2D499B] mx-auto mt-2 rounded-xl"
            style={{ height: "5px", width: "200px", border: "none" }}
          />
          <div className="mt-10 text-[18px] font-semibold">
            <div>
              <div className=" gap-4 items-center mt-4">
                <div className="grid grid-cols-6 grid-flow-row gap-6 box-border w-full p-4 border-2 rounded-[30px] border-black">
                  <div className="text-[15px]">
                    <p>Sample No</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>
                  <div className="text-[15px]">
                    <p>Drug Name</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>
                  <div className="text-[15px]">
                    <p>Symbol Number</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>

                  <div className="text-[15px]">
                    <p>Date of Submission</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>

                  <div className="text-[15px]">
                    <p>Bidder/Supplier Name</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>

                  <div className="text-[15px]">
                    <p>Status</p>
                    {/* <div className="border border-black rounded-[30px] h-10"></div> */}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.sample_no}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.drug_name}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.symbol_no}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.date_of_submission}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.bidder_id}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="gird grid-cols-1">
                    {tableData.map((samples, index) => (
                      <div key={index}>
                        <div className="border border-black rounded-[30px] h-10 mt-2 flex">
                          <input
                            value={samples.status}
                            disabled
                            className="text-center w-full h-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <div className="grid grid-cols-4 grid-flow-row gap-4">
                  <div>
                    <div className="border border-black rounded-[30px] h-[80px] flex  justify-center">
                      <img
                        className="h-[80px] "
                        src={`${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${sampleData.internal_auditor_img}`}
                        alt=""
                      />
                    </div>
                    <input
                      value={sampleData.internal_auditor_employee_id}
                      disabled
                      className="text-center"
                    />
                    <p className="text-center">Internal Auditor</p>
                  </div>
                  <div>
                    <div className="border border-black rounded-[30px] h-[80px] flex  justify-center">
                      {" "}
                      <img
                        className="h-[80px]"
                        src={`${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${sampleData.certified_1_img}`}
                        alt=""
                      />
                    </div>
                    <input
                      disabled
                      value={sampleData.certified_1_employee_id}
                      className="text-center"
                    />
                    <input
                      value={sampleData.certified_1_employee_role}
                      disabled
                      className="text-center"
                    />
                    <input
                      value={sampleData.certified_1_employee_designation}
                      disabled
                      className="text-center"
                    />
                  </div>
                  <div>
                    <div className="border border-black rounded-[30px] h-[80px] flex justify-center ">
                      {" "}
                      <img
                        className="h-[80px]"
                        src={`${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${sampleData.certified_2_img}`}
                        alt=""
                      />
                    </div>
                    <input
                      disabled
                      value={sampleData.certified_2_employee_id}
                      className="text-center"
                    />
                    <input
                      value={sampleData.certified_2_employee_role}
                      disabled
                      className="text-center"
                    />
                    <input
                      value={sampleData.certified_2_employee_designation}
                      disabled
                      className="text-center"
                    />
                  </div>
                  <div>
                    <div className="border border-black rounded-[30px] h-[80px] flex justify-center">
                      {" "}
                      <img
                        className="h-[80px]"
                        src={`${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${sampleData.certified_3_img}`}
                        alt=""
                      />
                    </div>
                    <input
                      disabled
                      value={sampleData.certified_3_employee_id}
                      className="text-center"
                    />
                    <input
                      value={sampleData.certified_3_employee_role}
                      disabled
                      className="text-center"
                    />
                    <input
                      value={sampleData.certified_3_employee_designation}
                      disabled
                      className="text-center"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sample_Commitee_Report;
