 

import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import usePermissions from "../../../components/permissions/permission";
const PendingRecipes = () => {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;
  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/getAccordingtoStatus?status=PENDING`
    )
      .then((response) => response.json())
      .then((data) => {
        // Extracting only required fields
        const extractedRecipes = data.map((recipe) => ({
          id: recipe.recipe_id, // Include the recipe ID
          createdBy: recipe.recipe_dr,
          status: recipe.recipe_status,
          name: recipe.recipe_name,
        }));
        setRecipes(extractedRecipes);
        console.log(extractedRecipes);
      })
      .catch((error) => {
        console.error("Error fetching recipes:", error);
      });
  }, []);
  // Define an object to store status for each row
  const [statusMap, setStatusMap] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedRecipeIndex, setSelectedRecipeIndex] = useState(null); // Track the index of the selected recipe
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const handleChange = (e, index) => {
    const selectedStatus = e.target.value;
    setStatusMap({ ...statusMap, [index]: selectedStatus });
    if (selectedStatus === "Reject") {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };
  const getStatusColor = (index) => {
    switch (statusMap[index]) {
      case "Approve":
        return "bg-[#F5E450] bg-opacity-50";
      case "Reject":
        return "bg-[#F55050] bg-opacity-50";
      default:
        return "";
    }
  };
  // Pagination
  const totalPages = Math.ceil(recipes.length / itemsPerPage);
  const maxPageNumbers = 5;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Filter data based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = recipes.slice(startIndex, endIndex);
  // Handler for rejecting reason change
  const handleRejectReasonChange = (e) => {
    setRejectReason(e.target.value);
  };
  // Function to handle status change
  const handleStatusChange = (e, index) => {
    const selectedStatus = e.target.value;
    setStatusMap({ ...statusMap, [index]: selectedStatus }); // Update status map
    if (selectedStatus === "Reject") {
      setShowPopup(true); // Show rejection popup
      setSelectedRecipeIndex(index); // Set the index of the selected recipe
    } else if (selectedStatus === "Approve") {
      setShowApproveConfirmation(true); // Show "Approve" confirmation popup
      setSelectedRecipeIndex(index); // Set the index of the selected recipe
    }
  };
  // Function to handle rejection confirmation
  const handleRejectionConfirmation = () => {
    const recipeId = filteredData[selectedRecipeIndex].id; // Get the ID of the selected recipe
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/update?recipe_id=${recipeId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          recipe_status: "REJECTED",
          recipe_reject_reason: rejectReason,
          recipe_dr: "EMP-005",
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("Recipe status updated to rejected successfully");
          // Handle success
          setShowPopup(false); // Close the rejection popup
          setRejectReason(""); // Clear rejection reason
        } else {
          console.error("Failed to update recipe status to rejected");
          // Handle failure
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error
      });
  };
  // Function to handle approval confirmation
  const handleApproveConfirmation = () => {
    const recipeId = filteredData[selectedRecipeIndex].id; // Get the ID of the selected recipe
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/update?recipe_id=${recipeId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          recipe_status: "APPROVE", // Corrected from "APPROVED"
          recipe_dr: "EMP-005",
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("Recipe status updated to approved successfully");
          // Handle success
          setShowApproveConfirmation(false); // Close the approval popup
        } else {
          console.error("Failed to update recipe status to approved");
          // Handle failure
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error
      });
  };
  const handleRecipeClick = (recipe) => {
    console.log("Recipe ID", recipe.id);
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/getrecipeAccordingtoId?recipe_id=${recipe.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Recipe Data:", data);
        setSelectedRecipe(data);
      })
      .catch((error) => {
        console.error("Error fetching recipe details:", error);
      });
  };
  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedRecipe(null);
  };
  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Pending Recipes
      </p>
      <div className="flex mt-10">
        <table className="border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Recipe Name
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Created By
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Status
              </th>{" "}
              <th className="border border-gray-200 px-10 py-4 w-[40px] text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((recipe, index) => (
              <tr key={index} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{recipe.name}</td>
                <td className="text-center">{recipe.createdBy}</td>
                <td>
                  {hasPermission(2410) && (
                    <select
                      className={`w-full py-1 px-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 font-bold ${getStatusColor(
                        index
                      )}`}
                      value={statusMap[index] || ""}
                      onChange={(e) => handleStatusChange(e, index)} // Pass index to handleStatusChange
                    >
                      <option value="">Select an option</option>
                      <option value="Approve">Approve</option>
                      <option value="Reject">Reject</option>
                    </select>
                  )}
                </td>
                <td className="text-center">
                  {hasPermission(2411) && (
                    <button
                      onClick={() => handleRecipeClick(recipe)}
                      className="text-gray-400 hover:text-gray-800 focus:outline-none"
                    >
                      <FaEye />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showApproveConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="mb-4">Are you sure you want to approve?</p>
            <div className="flex justify-end">
              <button
                onClick={handleApproveConfirmation}
                className="bg-green-600 text-white px-4 py-2 rounded-md mr-2"
              >
                Yes
              </button>
              <button
                onClick={() => setShowApproveConfirmation(false)}
                className="bg-gray-300 px-4 py-2 rounded-md"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              Mention the reason for rejection below
            </h2>
            <textarea
              value={rejectReason}
              onChange={handleRejectReasonChange}
              className="w-full border rounded-md p-2"
              rows={4}
              placeholder="Enter reason..."
            ></textarea>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md mr-4"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={handleRejectionConfirmation}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200  cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200  cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}
      {selectedRecipe && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex justify-center items-center ">
          <div className="relative bg-white p-8 rounded-lg max-w-md overflow-y-auto h-[400px]">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4">Recipe Details</h2>
            <p className="text-lg">Status: {selectedRecipe.status}</p>
            {selectedRecipe && selectedRecipe.length > 0 && (
              <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex justify-center items-center">
                <div className="relative bg-white p-8 rounded-lg max-w-md overflow-y-auto h-[400px]">
                  <button
                    onClick={handleClosePopup}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <h2 className="text-2xl font-bold mb-4">Recipe Details</h2>
                  {selectedRecipe[0].raw_item && (
                    <div>
                      <p className="text-lg">Raw Items:</p>
                      <ul>
                        {selectedRecipe[0].raw_item.map((item, index) => (
                          <li key={index}>
                            <p className="font-bold">
                              Item Name: {item.raw_item_name}
                            </p>
                            <p>Quantity: {item.raw_item_quantity}</p>
                            <p>Unit: {item.raw_item_unit}</p>
                            {item.alternative_item && (
                              <div>
                                <p className="text-lg">Alternative Items:</p>
                                <ul>
                                  {item.alternative_item.map(
                                    (alternative, altIndex) => (
                                      <li
                                        key={altIndex}
                                        className="bg-yellow-200 p-2 rounded-md mb-2"
                                      >
                                        <p className="font-bold">
                                          Alternative Item Name:{" "}
                                          {alternative.raw_item_name}
                                        </p>
                                        <p>
                                          Quantity:{" "}
                                          {alternative.raw_item_quantity}
                                        </p>
                                        <p>Unit: {alternative.raw_item_unit}</p>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default PendingRecipes;
