import React from "react";

const DialogMemberDetails = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[80%] h-[75%]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-[#071C50] font-poppins font-semibold text-[26px] ml-[5%] mt-4">
            Member Details
          </h2>
          <hr className=" w-1/12 border-t-4 border-[#5B6D49] ml-[5%] rounded-[22px]" />
        </div>
        <div className="flex justify-center items-center mt-4 h-[80%]">
          <div className="bg-[#FFF] rounded-[19px] w-[90%] h-[80%] flex-shrink-0 overflow-hidden">
            <div className="overflow-y-auto h-full">
              <table className="table-fixed mt-4 border-none min-h-[100%]">
                <thead>
                  <tr className="bg-white text-gray-600 ">
                    <th className="sticky top-0 bg-white z-10">Employee ID</th>
                    <th className="sticky top-0 bg-white z-10">
                      Employee Name
                    </th>
                    <th className="sticky top-0 bg-white z-10">
                      Appointing Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                    <td>Malcolm Lockyer</td>
                    <td>1961</td>
                  </tr>
                  <tr>
                    <td>Witchy Woman</td>
                    <td>The Eagles</td>
                    <td>1972</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr>
                  <tr>
                    <td>Shining Star</td>
                    <td>Earth, Wind, and Fire</td>
                    <td>1975</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogMemberDetails;
