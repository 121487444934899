import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

const Leave_Nav = () => {
  const { hasPermission } = usePermissions();
  const [leaveCounts, setLeaveCounts] = useState({});
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/leave/getleave`)
      .then((response) => response.json())
      .then((data) => {
        // Creating a counts object based on the data fetched
        const counts = data.reduce((acc, curr) => {
          // Use the API's leave_type directly as the key
          const key = curr.leave_type;
          acc[key] = (acc[key] || 0) + 1;
          return acc;
        }, {});
        console.log("Counts:", counts); // Debugging to verify counts
        setLeaveCounts(counts);
      })
      .catch((error) => console.error("Error fetching leave counts:", error));
  }, []);

  const handleCardClick = (leaveType) => {
    navigate("/leave-info", { state: { leaveType } });
  };
  const leaveTypes = [
    { type: "Annual-Leave", permissionId: 1050 },
    { type: "Casual-Leave", permissionId: 1051 },
    { type: "Medical-Leave", permissionId: 1052 },
    { type: "Short-Leave", permissionId: 1053 },
  ];

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="mt-6 ml-6">
        <p className="text-[40px] text-headerColor font-semibold ml-6 mt-6 text-header">
          Leave Management
        </p>
      </div>

      <div className="mt-5 ml-6 justify-center flex mr-[5%]">
        <div className="grid grid-cols-4 grid-rows-1 grid-flow-row gap-[25px] w-[1900px]">
          {leaveTypes.map(({ type, permissionId }) =>
            hasPermission(permissionId) ? (
              <Card
                key={type}
                leaveType={type.replace(/-/g, " ")} // Replace hyphens for display purposes
                count={leaveCounts[type] || 0}
                onClick={handleCardClick}
              />
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

// Card Component
const Card = ({ leaveType, count, onClick }) => {
  return (
    <div className="bg-white rounded-[30px]">
      <div className="ml-5 font-sans">
        <p className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans p-5 w-[10%]">
          {leaveType}
        </p>
      </div>
      <div className="flex justify-between items-center p-3">
        <div className="bg-primary w-[60px] font-bold h-[60px] text-center text-white mt-9 rounded-[20px] ml-5 flex justify-center items-center">
          {count}
        </div>
        <div onClick={() => onClick(leaveType)}>
          <p className="bg-gray-300 cursor-pointer w-[70px] flex justify-center rounded-[20px] mr-5 mt-8 text-black font-bold font-sans p-2">
            View
          </p>
        </div>
      </div>
    </div>
  );
};

export default Leave_Nav;
