import React from 'react';
import './card1.css';
import Earth from '../../Assets/icons/Sphere-Green-Glossy.png'

export default function Card1(props) {
  return (
    <div className='w-[375px] h-[234px] bg-[#F3F8FF] rounded-[22px] flex flex-col px-5 py-5'>
      <p className='font-bold text-5xl  w-[300px] text-[#797C80]'>{props.title1}</p>
      
      <div className='flex align-top justify-between'>
        <div>
          <p className='font-bold text-5xl w-[200px]  text-[#797C80]'>{props.title2}</p>
          <p  className='font-bold text-2xl w-[100px] mt-2 text-[#797C80]'>{props.subTitle}</p>
        </div>
        
        <div className='flex flex-col justify-top align-center mr-[20px] absolute ml-[200px] mb-[-75px]' >
          <img src={props.img} alt="" className='mt-2 '/>
          <div className='w-[170px] h-[64px] rounded-[22px] bg-[#9CAC8B] absolute mt-[70px] ml-[-39px] flex items-center justify-center'>
            <p className='font-bold text-2xl text-white '>{props.value}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
