import React, { useState } from "react";
import axios from "axios";
import pdfIcon from "../../../../../src/Assets/icons/png.png";

const Sample_Testing_Document_View = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [tenders, setTenders] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByTenderId/${query}`
        );
        setTenders(response.data);
        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50]">
        Sample Testing Committee View
      </p>

      <div className="grid grid-cols-2 mt-4">
        {/* Search Box */}
        <form className="form relative mt-4 ml-[10%]">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search By Tender ID"
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
        </form>
      </div>
      <div className="mt-4">
        <p className="text-[20px] text-[#7B7B7B] font-extrabold ml-6">
          **Click on the required PDF to download
        </p>
      </div>

      <div className="bg-white min-h-[286px] w-[95%] mt-10 ml-6 border-r-4">
        {tenders.length > 0 ? (
          <div className="p-4 grid grid-cols-4 ">
            {tenders.map((tender) => (
              <div key={tender._id} className="flex items-center mb-2">
                <div>
                  <a
                    href={`${REACT_APP_API_ENDPOINT}/v1/inventory/sample_testing/getFileByFileName/${tender.file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-blue-500"
                  >
                    <div className=" items-justify-center">
                      <div>
                        <img
                          src={pdfIcon}
                          alt="PDF Icon"
                          className="w-20 h-20"
                        />
                      </div>
                      <div>{tender.file}</div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="p-4 text-gray-500">No tenders found.</p>
        )}
      </div>
    </div>
  );
};

export default Sample_Testing_Document_View;
