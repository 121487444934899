import React, { useState } from "react";
import { FiFile } from "react-icons/fi";
import DialogBoxDocView from "./documentViewDialog";
import axios from "axios";

const TableDecisions = ({ data, refreshData }) => {
  const [showDialogIndex, setShowDialogIndex] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const openDialog = (index) => {
    setShowDialogIndex(index);
  };

  const closeDialog = () => {
    setShowDialogIndex(null);
  };

  const deleteItem = async (name) => {
    if (name) {
      try {
        const response = await axios.delete(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/decision/${name}`
        );
        console.log("Response:", response);
        alert("Successfully Deleted");
        refreshData();
      } catch (error) {
        console.error("Error deleting data:", error);
        alert("Failed to Delete");
      }
    }
  };

  const tableData = Array.isArray(data) ? data : data ? [data] : [];

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[97%] max-h-[500px] overflow-y-auto">
      <table className="w-full border-collapse border-none min-h-[250px] max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-4 py-6">Decision ID</th>
            <th className="px-4 py-6">Decision Name</th>
            <th className="px-4 py-6">Date</th>
            <th className="px-4 py-6">User ID</th>
            <th className="px-4 py-6">Upload</th>
            <th className="px-4 py-6"></th>
            <th className="px-4 py-6"></th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-4 py-2">{item.decision_id}</td>
              <td className="px-4 py-2">{item.decision_name}</td>
              <td className="px-4 py-2">{item.decision_updated_date}</td>
              <td className="px-4 py-2">{item.decision_updated_user_id}</td>
              <td className="px-4 py-2 relative">
                <div className="flex items-center bg-[#E7F1FF] rounded-lg">
                  <div className="flex items-center">
                    <FiFile className="text-gray-600" />
                  </div>
                  <div className="mr-2">{item.file}</div>
                  <div className="ml-4 text-[#071C50] text-opacity-20"></div>
                </div>
              </td>
              <td className="px-4 py-2">
                <button
                  className="text-primary hover:underline"
                  onClick={() => openDialog(index)}
                >
                  View
                  {showDialogIndex === index && (
                    <DialogBoxDocView onClose={closeDialog} file={item.file} />
                  )}
                </button>
              </td>
              <td className="px-6 py-2">
                <button
                  className="text-white hover:bg-primary bg-approveStattxt rounded-[22px] w-[70px]"
                  onClick={() => deleteItem(item.decision_id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableDecisions;
