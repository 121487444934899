import React, { useState, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import IdGenerate from "../../../../utils/id_generate";
import axios from "axios";
import { toWords } from "number-to-words";

const Purchase_Order_Create = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewscond] = useState(null);
  const [imagesecond, setImagesecond] = useState(null);
  const [imagePreviewthird, setImagePreviewthird] = useState(null);
  const [imagethired, setImagethired] = useState(null);
  const [forms, setForms] = useState([]);
  const location = useLocation();
  const tenderData = location.state;
  const [purchaseNumber, setPurchaseNumber] = useState(IdGenerate("PUR"));
  const { REACT_APP_API_ENDPOINT } = process.env;

  // Customers search variable part
  const [searchSupplierTerm, setSearchSupplierTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [supplierID, setSupplierID] = useState("");
  const [selectedSupplier, setSelectedSupplierTender] = useState([]);

  // Items search varia part
  const [searchItemTerm, setSearchItemTerm] = useState("");
  const [items, setItems] = useState([]);
  const [isItemDropDownOpen, setIsItemDropDownOpen] = useState(false);
  const [itemId, setItemId] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [unit, setUnit] = useState("");
  const [itemName, setItemName] = useState("");

  const [issueDate, setIssueDate] = useState(new Date());
  const [todayDate, setTodayDate] = useState(new Date());
  const [referenceNumber, setReferenceNumber] = useState("");
  const [deleveryDate, setDeleveryDate] = useState(new Date());
  const [address, setAddress] = useState("");
  const [selectedCurrentDate, setSelecteCurrentdDate] = useState(new Date());
  const [sampleData, setSampleData] = useState([]);

  const [quantity, setQuantity] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("");
  const [totalOrder, setTotalOrder] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [totalVat, setTotalVat] = useState("");
  const [totalAmountInWords, setTotalAmountInWords] = useState("");

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Second Signature drag and drop box.
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagesecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewscond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Thired Signature drag and drop box.
  const handleDragOverthird = (e) => {
    e.preventDefault();
  };

  const handleDropthird = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagethired(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthird(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleAddSample = () => {
    const newSample = {
      id: itemId,
      itemId,
      unit,
      quantity,
      rate,
      amount,
    };

    setSampleData([...sampleData, newSample]); // Add new sample to the array

    // Clear input fields after adding
    setItemId("");
    setUnit("");
    setQuantity("");
    setRate("");
    setAmount("");
    setSearchItemTerm("");
  };

  const removeForm = (index) => {
    const updatedForms = [...forms];
    updatedForms.splice(index, 1);
    setForms(updatedForms);
  };

  // Search bar request part for suppliers
  const handleSearchContractChange = async (event) => {
    const query = event.target.value;
    setSearchSupplierTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/supplier/search/${query}`
        );
        setCustomers(response.data);
        setIsCustomerDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setCustomers([]);
      }
    } else {
      setCustomers([]);
      setIsCustomerDropdownOpen(false);
    }
  };

  // Search bar request part for Items.
  const handleSearchItemChange = async (event) => {
    const query = event.target.value;
    setSearchItemTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/item/search/name-status/ACTIVE/${query}`
        );
        setItems(response.data);
        setIsItemDropDownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setItems([]);
      }
    } else {
      setItems([]);
      setIsItemDropDownOpen(false);
    }
  };

  // Suppliers drop donw and reset reset part functions
  const handleContractOptionClick = (result) => {
    setSearchSupplierTerm(result.supplier_id);
    setSelectedSupplierTender(result);
    setIsCustomerDropdownOpen(false);
    setSupplierID(result.supplier_id);
  };

  const handleSupplierReset = () => {
    setSearchSupplierTerm("");
    setSelectedSupplierTender([]);
    setIsCustomerDropdownOpen(false);
  };

  //Items drop donw and reset part functions.
  const handleItemOptionClick = (result) => {
    setSearchItemTerm(result.item_name);
    setSelectedItem(result);
    setIsItemDropDownOpen(false);
    setItemId(result.item_id);
    setItemName(result.item_name);
    setUnit(result.item_measure_unit);
  };

  const handleItemReset = () => {
    setSearchItemTerm("");
    setSelectedItem([]);
    setItemId("");
    setItemName("");
    setIsItemDropDownOpen(false);
    setImagePreview(null);
    setImage(null);
    setImagePreviewscond(null);
    setImagesecond(null);
    setImagePreviewthird(null);
    setImagethired(null);
    setForms([]);
    setPurchaseNumber(IdGenerate("PUR"));
    setSearchSupplierTerm("");
    setCustomers([]);
    setIsCustomerDropdownOpen(false);
    setSupplierID("");
    setSelectedSupplierTender([]);
    setSearchItemTerm("");
    setItems([]);
    setIsItemDropDownOpen(false);
    setItemId("");
    setSelectedItem([]);
    setUnit("");
    setItemName("");
    setIssueDate(new Date());
    setTodayDate(new Date());
    setReferenceNumber("");
    setDeleveryDate(new Date());
    setAddress("");
    setSampleData([]);
    setQuantity("");
    setRate("");
    setAmount("");
    setTotalOrder("");
    setTotalAmount("");
    setTotalDiscount("");
    setTotalVat("");
    setTotalAmountInWords("");
  };

  const handleRemoveSample = (id) => {
    const updatedSampleData = sampleData.filter((sample) => sample.id !== id);
    setSampleData(updatedSampleData);
  };

  useEffect(() => {
    if (quantity && rate) {
      setAmount(quantity * rate);
    } else {
      setAmount("");
    }
    if (sampleData.length > 0) {
      setTotalOrder(sampleData.length);
    } else {
      setTotalOrder("");
    }
  }, [quantity, rate, sampleData]);

  useEffect(() => {
    if (sampleData.length > 0) {
      setTotalOrder(sampleData.length);
      const total = sampleData.reduce(
        (acc, sample) => acc + Number(sample.amount),
        0
      );
      setTotalAmount(total);
    } else {
      setTotalOrder(0);
      setTotalAmount(0);
    }
  }, [sampleData]);

  useEffect(() => {
    if (sampleData.length > 0) {
      const totalDuduct = totalDiscount - totalVat;
      const newTotalAmount = sampleData.reduce(
        (acc, sample) => acc + Number(sample.amount),
        0
      );
      setTotalAmount(newTotalAmount - totalDuduct);
    } else {
      setTotalAmount(0);
    }
  }, [sampleData, totalVat, totalDiscount]);

  useEffect(() => {
    const numericAmount = parseFloat(totalAmount);
    if (!isNaN(numericAmount) && isFinite(numericAmount)) {
      setTotalAmountInWords(convertAmountToWords(numericAmount));
    } else {
      setTotalAmountInWords("");
    }
  }, [totalAmount]);

  // Function to convert total amount to words
  const convertAmountToWords = (amount) => {
    const integerPart = Math.floor(amount);
    const decimalPart = Math.round((amount - integerPart) * 100); // Get the cents part

    const integerPartInWords = toWords(integerPart);
    let decimalPartInWords = "";

    if (decimalPart > 0) {
      decimalPartInWords = toWords(decimalPart);
      return `${integerPartInWords} rupees and ${decimalPartInWords} cents`;
    } else {
      return `${integerPartInWords} rupees`;
    }
  };

  // Submitting data.
  const handleSubmit = async () => {
    console.log("Image:", image);
    try {
      // Create a FormData object
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("tender_id", tenderData.tender_id);
      formData.append("po_id", purchaseNumber);
      formData.append("supplier_id", supplierID);

      formData.append("po_issue_date", issueDate.toLocaleDateString("en-GB"));
      formData.append("reference_no", referenceNumber);
      formData.append(
        "delivery_date",
        deleveryDate.toLocaleDateString("en-GB")
      );
      formData.append("address", address);

      // Second Employee
      formData.append("total_order", totalOrder);
      formData.append("total_discount", totalDiscount);
      formData.append("total_vat", totalVat);
      formData.append("total", totalAmount);
      formData.append("amount_in_words", totalAmountInWords);

      //Prepeared User data
      formData.append("prepared_img", image);
      formData.append("prepared_user_id", "USER-49970751");
      formData.append("prepared_date", todayDate.toLocaleDateString("en-GB"));

      //Checked User data
      formData.append("checked_img", imagesecond);
      formData.append("checked_user_id", "USER-49970751");
      formData.append("checked_date", todayDate.toLocaleDateString("en-GB"));

      //Approved User data
      formData.append("approved_img", imagethired);
      formData.append("approved_user_id", "USER-49970751");
      formData.append("approved_date", todayDate.toLocaleDateString("en-GB"));

      formData.append("po_status", "ACTIVE");

      // Append members_in_attendance array
      sampleData.forEach((sampledata, index) => {
        formData.append(`pr_items[${index}][item_id]`, sampledata.itemId);
        formData.append(`pr_items[${index}][qty]`, sampledata.quantity);
        formData.append(`pr_items[${index}][rate]`, sampledata.rate);
        formData.append(`pr_items[${index}][amount]`, sampledata.amount);
      });

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/purchase_order/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response

      console.log("Response:", response.data);
      alert("Purchase Order Add successfully added");
      handleItemReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Purchase Order");
    }
  };

  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen ">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Create: Purchase Order
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2">
            Tender ID:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderData.tender_id}
            disabled
          ></input>

          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Tender Name:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2 "
            value={tenderData.tender_name}
            disabled
          ></input>
        </div>
      </div>

      {/* Second form part */}
      <div className=" flex">
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          Create a Purchase Order
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">
            Purchase Order ID:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={purchaseNumber}
            disabled
          ></input>
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Suppliers:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={searchSupplierTerm} // Bind the value to searchSupplierTerm
            onChange={handleSearchContractChange} // Handle change to update searchSupplierTerm
            placeholder="Search by supplier ID"
          ></input>
          <button
            type="button"
            className="btn btn-primary text-[#797C80] hover:bg-gray-100"
            onClick={handleSupplierReset}
          ></button>
          <div className="mt-4 relative">
            {isCustomerDropdownOpen && (
              <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                {customers.length > 0 ? (
                  customers.map((result) => (
                    <li
                      key={result._id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      value={selectedSupplier}
                      onClick={() => handleContractOptionClick(result)}
                    >
                      {result.supplier_id}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500">No results found</li>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end"></div>
        <div></div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Issue Date :
          </p>
        </div>
        <div>
          <DatePicker
            selected={issueDate}
            onChange={(date) => setIssueDate(date)}
            className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF]"
            placeholderText="dd-mm-yyyy"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Reference No :
          </p>
        </div>
        <div>
          <input
            className="rounded-[22px] border border-gray-300 ml-10 h-8"
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Delivery Date :
          </p>
        </div>
        <div>
          {" "}
          <DatePicker
            selected={deleveryDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setDeleveryDate(date)}
            className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF]"
            placeholderText="dd-mm-yyyy"
          />
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Address :
          </p>
        </div>
        <div className=" col-span-2">
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8 w-full mb-2 "
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></input>
        </div>
      </div>

      {/* Third form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">Item ID:</p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={searchItemTerm} // Bind the value to searchSupplierTerm
            onChange={handleSearchItemChange} // Handle change to update searchSupplierTerm
            placeholder="Search by Item ID"
          ></input>
          <div className="mt-4 relative">
            {isItemDropDownOpen && (
              <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                {items.length > 0 ? (
                  items.map((result) => (
                    <li
                      key={result._id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      value={selectedItem}
                      onClick={() => handleItemOptionClick(result)}
                    >
                      {result.item_name}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500">No results found</li>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">Unit:</p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Quantity :
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">Rate :</p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          ></input>
        </div>

        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Amount :
          </p>
        </div>
        <div className=" col-span-2">
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8 mb-2"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            disabled
          ></input>
        </div>
      </div>

      {/* Add button */}
      <div className="flex justify-end mt-6 mr-6">
        <button
          onClick={handleAddSample}
          className="bg-primary text-white font-bold py-2 px-4 rounded-[22px]"
        >
          Add
        </button>
      </div>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px] p-6">
        <h2 className="text-[22px] text-[#071C50] font-bold mb-4">
          Item Table
        </h2>
        {sampleData.length === 0 ? (
          <p className="text-gray-500">No items added yet.</p>
        ) : (
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Item ID</th>
                <th className="py-2 px-4 border-b">Unit</th>
                <th className="py-2 px-4 border-b">Quantity</th>
                <th className="py-2 px-4 border-b">Rate</th>
                <th className="py-2 px-4 border-b">Amount</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sampleData.map((sample) => (
                <tr key={sample.id}>
                  <td className="py-2 px-4 border-b">{sample.itemId}</td>
                  <td className="py-2 px-4 border-b">{sample.unit}</td>
                  <td className="py-2 px-4 border-b">{sample.quantity}</td>
                  <td className="py-2 px-4 border-b">{sample.rate}</td>
                  <td className="py-2 px-4 border-b">{sample.amount}</td>
                  <td className="py-2 px-4 border-b">
                    <button
                      className="bg-red-500 text-white px-4 py-1 rounded"
                      onClick={() => handleRemoveSample(sample.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Forth form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">
            Total Order:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 "
            value={totalOrder}
            disabled
          ></input>
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Total Discount:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={totalDiscount}
            onChange={(e) => setTotalDiscount(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Total VAT :
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8"
            value={totalVat}
            onChange={(e) => setTotalVat(e.target.value)}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">Total :</p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={totalAmount}
            onChange={(e) => setTotalAmount(e.target.value)}
          ></input>
        </div>

        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Amount In Words :
          </p>
        </div>
        <div className=" col-span-2">
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8 mb-2 w-full"
            value={totalAmountInWords}
            disabled
          ></input>
        </div>
      </div>

      <div className=" flex justify-between w-[95%] ml-6">
        {/* First Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Prepared By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Second Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOversecond}
            onDrop={handleDropsecond}
          >
            {imagePreviewsecond ? (
              <img
                src={imagePreviewsecond}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Checked By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Theird Drag and drop box */}
        <div className="">
          {/* Drag and drop area */}
          <div
            className="flex flex-col items-center justify-center mt-6"
            onDragOver={handleDragOverthird}
            onDrop={handleDropthird}
          >
            {imagePreviewthird ? (
              <img
                src={imagePreviewthird}
                alt="Preview"
                className="w-40 h-40 rounded-lg mb-4"
              />
            ) : (
              <div className="bg-white border border-gray-300 w-50 h-50 rounded-lg flex flex-col items-center justify-center mb-6">
                <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                  Approved By
                </p>
                <img
                  src={cloudIcon}
                  alt="Cloud Icon"
                  className="w-16 h-16 mb-2"
                />
                <p className="text-[#8691AB] font-bold ml-4">
                  Drag and Drop Digital Signature
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-6 mt-6 mr-10 flex justify-center">
        <button
          className="bg-[#5B6D49] text-[20px] font-bold text-white rounded-[33px] w-[8%] h-8"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Purchase_Order_Create;
