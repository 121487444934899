import React, { useState } from "react";
import { FaFilePdf } from "react-icons/fa";

const DialogTenderView = ({ onClose, document }) => {
  const [files, setFiles] = useState(document);
  console.log("files:", files);
  const handleDocumentClick = (fileUrl) => {
    console.log("file URL", fileUrl);
    window.open(fileUrl, "_blank");
  };
  const { REACT_APP_API_ENDPOINT } = process.env;

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[80%] h-[75%]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-[#071C50] font-poppins font-semibold text-[26px] ml-[5%] mt-4">
            View Documents
          </h2>
        </div>
        <div className="flex justify-center items-center mt-4 h-[80%]">
          <div className="bg-[#FFF] rounded-[19px] w-[90%] h-[80%] flex-shrink-0 overflow-hidden">
            <div className="overflow-y-auto h-full">
              {files ? (
                <div className="flex items-center justify-center h-full">
                  <FaFilePdf
                    className="text-red-500 cursor-pointer"
                    size={100}
                    onClick={() =>
                      handleDocumentClick(
                        `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_bidders/file/` +
                          files
                      )
                    }
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p>No document available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogTenderView;
