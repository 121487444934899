import React, { useEffect, useState } from "react";
import "./userRole.css";
import Arrow from "./../../../Assets/icons/down-arrow.png";
import axios from "axios";

export default function UserRole() {
  const [resultsShow, setResultsShow] = useState(true);

  const [userId, setUserId] = useState("");

  const [userSearchResults, setUserSearchResults] = useState([]);
  const UserSearchHandler = async (e) => {
    if (e.target.value !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/search/name/status/${e.target.value}/ACTIVE`
        );
        console.log(res.data);
        setUserSearchResults(res.data);
      } catch (error) {}
    }
  };

  //get user roles
  const [userRoles, setUserRoles] = useState([
    {
      status: "",
      role_id: "",
      user_id: "",
    },
  ]);
  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/userRoles/all`
        );
        console.log(res.data);

        const newData = res.data.map(async (element) => {
          return {
            status: false,
            role_id: element.role_id,
            role_name: element.role_name,
          };
        });

        const resolvedData = await Promise.all(newData);
        setUserRoles(resolvedData);
      } catch (error) {
        // Handle error here
        console.error(error);
      }
    };

    getRoles();
  }, []);

  //get user roles according to user id
  const GetRolesAccordingToId = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/userRoles/roles/${id}`
      );
      console.log(res.data);
      if (res.data.length > 0) {
        res.data.forEach((element) => {
          const Data = [...userRoles];
          Data.forEach((userRole) => {
            if (userRole.role_id === element.role_id) {
              userRole.status = true;
            }
          });
          setUserRoles(Data);
        });
      }
    } catch (error) {
      // Handle error here
      console.error(error);
    }
  };

  const SubmitHandler = async () => {
    // console.log(userRoles);
    let activeRoles = [];
    userRoles.forEach((element) => {
      if (element.status === true) {
        activeRoles.push(element);
      }
    });
    console.log(userId);
    console.log(activeRoles);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/userRoles/add`,
        {
          user: userId,
          roles: activeRoles,
        }
      );
      console.log(res.data);
    } catch (error) {}
  };
  return (
    <div className="p-10 bg-background overflow-y-auto">
      <p className="header-1">User Role</p>
      <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
        <div className="relative font-sans group hidden sm:block">
          <label className="label-1">Find user</label>
          <label className=" px-10 label-1">:</label>
          <input
            className="input-1 border border-[#00000036]"
            type="text"
            placeholder="Search by EMP NO, EPF NO, NIC"
            //value={searchQuery}
            //onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Select User Roles</p>
          <div className="UserRole-User-roles mt-5">
            {userRoles.length > 0
              ? userRoles.map((role, index) => {
                  return (
                    <div className="UserRole-User-roles-form-div" key={index}>
                      <label className="inline-flex items-center">
                        {" "}
                        {/* Use inline-flex for alignment */}
                        <span className="mr-2">{role.role_name}</span>{" "}
                        {/* Add spacing between label and checkbox */}
                        <input
                          className="form-checkbox h-5 w-5 border border-[#6cfa5f] rounded" // Adjust height (h-5) and width (w-5)
                          type="checkbox"
                          checked={role.status}
                          onChange={(e) => {
                            const updatedRoles = [...userRoles];
                            updatedRoles[index].status = e.target.checked;
                            setUserRoles(updatedRoles);
                          }}
                        />
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-10 px-[280px]">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4">
          <p className="text-white">CANCEL</p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4">
          <p className="text-white">Submit</p>
        </button>
      </div>
    </div>
  );
}
