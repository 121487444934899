import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiFile, FiCalendar } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const TableMember = ({ data }) => {
  const navigate = useNavigate();
  const [showDialogIndex, setShowDialogIndex] = useState(null);

  const openDialog = (index) => {
    setShowDialogIndex(index);
  };

  const closeDialog = () => {
    setShowDialogIndex(null);
  };
  // Dummy table data array
  const dummyData = [
    {
      employeeId: "Cat-001",
      employeeName: "Test Category",
      appointingDate: "2015-01-01",
      status: "Approved",
    },
    {
      employeeId: "Cat-002",
      employeeName: "Test2 Category",
      appointingDate: "2015-02-01",
      status: "Rejected",
    },
    {
      employeeId: "Cat-001",
      employeeName: "Test Category",
      appointingDate: "2015-01-01",
      status: "Approved",
    },
    {
      employeeId: "Cat-002",
      employeeName: "Test2 Category",
      appointingDate: "2015-02-01",
      status: "Rejected",
    },
    {
      employeeId: "Cat-001",
      employeeName: "Test Category",
      appointingDate: "2015-01-01",
      status: "Approved",
    },
    {
      employeeId: "Cat-002",
      employeeName: "Test2 Category",
      appointingDate: "2015-02-01",
      status: "Rejected",
    },
    {
      employeeId: "Cat-001",
      employeeName: "Test Category",
      appointingDate: "2015-01-01",
      status: "Approved",
    },
    {
      employeeId: "Cat-002",
      employeeName: "Test2 Category",
      appointingDate: "2015-02-01",
      status: "Rejected",
    },
    {
      employeeId: "Cat-001",
      employeeName: "Test Category",
      appointingDate: "2015-01-01",
      status: "Approved",
    },
    {
      employeeId: "Cat-002",
      employeeName: "Test2 Category",
      appointingDate: "2015-02-01",
      status: "Rejected",
    },
    {
      employeeId: "Cat-001",
      employeeName: "Test Category",
      appointingDate: "2015-01-01",
      status: "Approved",
    },
    {
      employeeId: "Cat-002",
      employeeName: "Test2 Category",
      appointingDate: "2015-02-01",
      status: "Rejected",
    },
    // Add more rows as needed
  ];

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data || dummyData;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[100%] ml-10 max-h-[100%] overflow-y-auto mb-8">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6 ">Employee ID</th>
            <th className="px-6 py-6 ">Employee Name</th>
            <th className="px-6 py-6 ">Appointing Date</th>
            <th className="px-6 py-6 ">
              Status
              <br />
              <p className="text-[10px]">(approved/Rejected)</p>
            </th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.employeeId}</td>
              <td className="px-6 py-2">{item.employeeName}</td>
              <td className="px-6 py-2">{item.appointingDate}</td>
              <td className="px-6 py-2">{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableMember;
