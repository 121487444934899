import React from "react";
import { Link } from "react-router-dom"; // Import for anchor tag if needed

const TableRecommendation = ({ data, navigate }) => {
  const getButtonStyle = (status) => {
    switch (status) {
      case "Suitable":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0 font-bold";
      case "Not Suitable":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0 font-bold";
      default:
        return ""; // Default style
    }
  };

  // Dummy table data array
  const dummyData = [
    {
      tenderId: "111",
      tenderName: "Tender 1",
      recommendedBider: "Apply 1",
      status: "Suitable",
    },
    {
      tenderId: "222",
      tenderName: "Tender 2",
      recommendedBider: "Apply 2",
      status: "Not Suitable",
    },
    // Add more rows as needed
  ];

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data || dummyData;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 ">
      <table className="w-full border-collapse border-none">
        <thead>
          <tr className="text-left bg-[#FFF] text-[#9CAC8B] font-bold">
            <th className="px-4 py-2 ">Tender ID</th>
            <th className="px-4 py-2 ">Tender Name</th>
            <th className="px-4 py-2 ">Recommended Bidder</th>
            <th className="px-4 py-2 ">Status</th>
          </tr>
        </thead>
        <tbody className="">
          {tableData.map((item, index) => (
            <tr key={index}>
              <td className="px-4 py-2">{item.tenderId}</td>
              <td className="px-4 py-2">{item.tenderName}</td>
              <td className="px-4 py-2">{item.recommendedBider}</td>
              <td className="px-4 py-2">
                <button
                  className={`inline-block ${getButtonStyle(
                    item.status
                  )} text-[#5B6D49]`}
                >
                  {item.status}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableRecommendation;
