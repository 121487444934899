 

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import boxLink from "../../../Assets/icons/Dashboard box.png";
import boxes from "../../../Assets/icons/boxes.png";
import emailvector1 from "../../../Assets/icons/emailvector1.png";
import documenticon from "../../../Assets/icons/documenticon.png";
import TableViewTender from "./homeTable";
import Tender_Recommndations_View_Dialog from "./Tender Recommendations/viewRecommendationsDialog";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

const Dashboard_Home = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const handleSendAMail = () => {
    navigate("/supply/sendAmail");
  };

  const handleCreaATender = () => {
    navigate("/supply/createTender");
  };

  const handleAcceptanceLetter = () => {
    navigate("/supply/acceptanceLetter");
  };

  const [showDialogIndex, setShowDialogIndex] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const openDialog = (index) => {
    setShowDialogIndex(index);
  };

  const closeDialog = () => {
    setShowDialogIndex(null);
  };

  useEffect(() => {
    // Define a function to make the GET request
    const fetchData = async () => {
      try {
        // Make the GET request with axios
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/search/tender/${searchTerm}`
        );
        // Set the search results based on the response data
        setSearchResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    // Call fetchData function only if searchTerm is not empty
    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]); // Trigger the effect whenever searchTerm changes

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update the searchTerm state when input changes
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="bg-white min-w-full min-h-16 flex justify-end items-center">
        <div className="bg-[#D6E5C6] min-w-[44px] min-h-[44px] rounded-full"></div>
        <p className=" text-[16px] text-[#071C50] font-bold ml-4 mr-4">
          Sahansa
        </p>
      </div>
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Dashboard
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-rows-2 grid-flow-col gap-4 mt-6 mx-6 ">
        {/* Card 1 */}

        {hasPermission(1950) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md justify-between card row-span-2">
            <div className=" min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Suppliers
              </h2>
            </div>
            <div className="flex items-center justify-between">
              <div className="bg-primary  text-white font-bold py-2 px-4 rounded-[22px] items-center text-[26px]">
                <p>26</p>
              </div>
              <div>
                <img src={boxLink} alt="BoxLink" className=" " />
              </div>
            </div>
          </div>
        )}

        {/* Card 2 */}

        {hasPermission(1951) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md justify-between card row-span-2">
            <div className="min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Purchase Request
              </h2>
            </div>
            <div className="flex items-center justify-between">
              <div className="bg-primary text-white font-bold py-2 px-4 rounded-[22px] items-center text-[26px]">
                <p>8</p>
              </div>
              <div>
                <img src={boxes} alt="BoxLink" className=" " />
              </div>
            </div>
          </div>
        )}

        {/* Card 4 */}

        {hasPermission(1952) && (
          <button
            className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card items-center mt-auto"
            onClick={openDialog}
          >
            <div className=" min-w-[150px] max-h-[150px] flex items-center">
              <div className=" bg-[#5B6D49] w-[45px] h-[45px] rounded-full flex items-center">
                <div className="bg-[#BFCEB0] w-[35px] h-[35px] rounded-full flex ml-[5px]"></div>
              </div>
              <h2 className="text-[25px] text-[#797C80] font-semibold ml-4">
                View <br /> Recommendations
              </h2>
            </div>
          </button>
        )}
        {showDialogIndex && (
          <Tender_Recommndations_View_Dialog onClose={closeDialog} />
        )}
        {/* Card 5 */}

        {hasPermission(1953) && (
          <button className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card items-center mt-auto">
            <div
              className=" min-w-[150px] max-h-[150px] flex items-center"
              onClick={handleSendAMail}
            >
              <img src={emailvector1} alt="EmailIcon" />
              <h2 className="text-[25px] text-[#797C80] font-semibold ml-4">
                Send <br /> a Mail
              </h2>
            </div>
          </button>
        )}

        {/* Card 6 */}
        {hasPermission(1954) && (
          <button
            className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card items-center mt-auto"
            onClick={handleAcceptanceLetter}
          >
            <div className="min-w-[150px] max-h-[150px] flex items-center">
              <img src={documenticon} alt="DocumentIcon" />
              <h2 className="text-[25px] text-[#797C80] font-semibold ml-4">
                Acceptance
                <br /> Letter
              </h2>
            </div>
          </button>
        )}
      </div>
      <div className="ml-6 mt-6">
        <p className="text-[#071C50] text-[26px] font-bold">View Tenders</p>
      </div>
      {/* Search Box */}
      <div className="flex font-poppins font-bold mt-6 ml-6 justify-between">
        <form class="form relative">
          <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              class="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            required
            placeholder="Search..."
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
            value={searchTerm} // Bind input value to searchTerm state
            onChange={handleSearchChange} // Call handleSearchChange when input changes
          />
          <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
            RESET
          </button>
        </form>
        <div className=" mr-10 bg-[#5B6D49] text-[23px] text-white rounded-[22px]">
          {hasPermission(1955) && (
            <button
              className=" flex items-center justify-center h-full w-ful w-[230px]"
              onClick={handleCreaATender}
            >
              Create a Tender
            </button>
          )}
        </div>
      </div>
      <div className="ml-6">
        <TableViewTender data={searchResults} />
      </div>
    </div>
  );
};

export default Dashboard_Home;
