import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import usePermissions from "../../../components/permissions/permission";

const View_Loan_Info = () => {
  const { hasPermission } = usePermissions();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loanData, setLoanData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8;
  const [formData, setFormData] = useState({
    amount: "",
    employee_no: "",
    years: "",
    granter1_name: "",
    granter1_contact: "",
    granter2_name: "",
    granter2_contact: "",
  });

  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    // Fetch loan data from the API
    const fetchLoanData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/lorn/lorn-data`
        ); // replace with your actual GET endpoint
        const data = await response.json();
        setLoanData(data);
      } catch (error) {
        console.error("Error fetching loan data:", error);
      }
    };

    fetchLoanData();
  }, [REACT_APP_API_ENDPOINT]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      amount: formData.amount,
      employee_no: formData.employee_no,
      years: formData.years,
      granter1: {
        name: formData.granter1_name,
        contact: formData.granter1_contact,
      },
      granter2: {
        name: formData.granter2_name,
        contact: formData.granter2_contact,
      },
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/lorn/add-lorn-data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const newLoanData = await response.json();
        setLoanData((prevData) => [...prevData, newLoanData]);
        togglePopup();
      } else {
        console.error("Error submitting loan data:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting loan data:", error);
    }
  };

  const handleDelete = (index) => {
    const updatedData = loanData.filter((_, i) => i !== index);
    setLoanData(updatedData);
  };

  const handleEdit = (index) => {
    console.log("Edit loan info at index:", index);
    togglePopup();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the number of pages
  const totalPages = Math.ceil(loanData.length / rowsPerPage);

  // Get the data to display on the current page
  const currentData = loanData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // CSV headers
  const headers = [
    { label: "Loan Amount", key: "amount" },
    { label: "Duration", key: "years" },
    { label: "Period", key: "next_due_date" },
    { label: "Employee Number", key: "employee_no" },
    { label: "Name", key: "employee_name" },
    { label: "Department", key: "department" },
    { label: "Designation", key: "designation" },
    { label: "Phone Number", key: "phone_number" },
    { label: "Name of Guarantee 1", key: "granter1_name" },
    { label: "Contact of Guarantee 1", key: "granter1_contact" },
    { label: "Name of Guarantee 2", key: "granter2_name" },
    { label: "Contact of Guarantee 2", key: "granter2_contact" },
  ];

  return (
    <div className="bg-background overflow-y-auto h-screen relative overflow-x-hidden">
      <div className="mt-6 ml-6">
        <p className="text-headerColor text-[30px] font-semibold">
          Loan Management
        </p>
      </div>
      <div className="flex justify-between gap-2">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <form className="form relative">
              <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  className="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeWidth="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                required=""
                placeholder="Search..."
                className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none placeholder-gray-400"
              />
            </form>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-2 grid-flow-col gap-4 mr-[50px]">
            <div className="ml-[93%]">
              <div className="bg-white rounded-[20px] w-[500px] p-4">
                <div className="font-sans mt-6 text-[30px] font-semibold text-headerColor">
                  <p>Create Loan</p>
                </div>
                <button
                  onClick={togglePopup}
                  className="bg-primary text-white p-2 font-semibold font-sans mt-[7px] w-[100px] rounded-[20px]"
                >
                  Click here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col font-sans font-bold ml-6 mt-[50px] rounded-xl mr-[5%] overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              <th className="px-6 py-3">
                <input type="checkbox" />
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Loan Amount
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Duration
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Period
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Employee Number
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Department
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Designation
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Phone Number
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Name of Guarantee 1
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Contact of Guarantee 1
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Name of Guarantee 2
              </th>
              <th className="px-6 py-3 text-left text-[16px] font-bold text-primary uppercase tracking-wider">
                Contact of Guarantee 2
              </th>
              {/* <th className="relative px-6 py-3">
                <span className="sr-only">Edit</span>
              </th> */}
              <th className="relative px-6 py-3">
                <span className="sr-only">Delete</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentData.map((loan, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input type="checkbox" />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{loan.amount}</td>
                <td className="px-6 py-4 whitespace-nowrap">{loan.years}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.next_due_date}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.employee_no}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.employee_name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.department}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.designation}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.phone_number}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.granter1_name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.granter1_contact}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.granter2_name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {loan.granter2_contact}
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleEdit(index)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </button>
                </td> */}
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleDelete(index)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between mt-4 ml-10">
        <div>
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
            }`}
          >
            Previous
          </button>
          <span className="mx-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className={`px-4 py-2 rounded-lg ${
              currentPage === totalPages
                ? "bg-gray-300"
                : "bg-blue-500 text-white"
            }`}
          >
            Next
          </button>
        </div>
        <div className=" mr-20">
          <CSVLink
            data={loanData}
            headers={headers}
            filename={"loan_data.csv"}
            className="px-4 py-2 bg-primary text-white rounded-lg"
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>

      {isPopupOpen && (
        <div className="fixed inset-0  flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative z-500">
            <button
              className="absolute top-0 right-0 m-4 text-red-800 font-bold"
              onClick={togglePopup}
            >
              Close
            </button>
            <h2 className="text-[30px] font-bold mb-4">Add Loan Info</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-[20px] w-[300px]  font-bold mb-2">
                  Loan Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded-[20px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-[20px] font-bold mb-2">
                  Employee Number
                </label>
                <input
                  type="text"
                  name="employee_no"
                  value={formData.employee_no}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded-[20px] w-[300px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-[20px] font-bold mb-2">
                  Duration (years)
                </label>
                <input
                  type="text"
                  name="years"
                  value={formData.years}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded-[20px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-[20px] font-bold mb-2">
                  Guarantee 1 Name
                </label>
                <input
                  type="text"
                  name="granter1_name"
                  value={formData.granter1_name}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded-[20px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-[20px] font-bold mb-2">
                  Guarantee 1 Contact
                </label>
                <input
                  type="text"
                  name="granter1_contact"
                  value={formData.granter1_contact}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded-[20px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-[20px] font-bold mb-2">
                  Guarantee 2 Name
                </label>
                <input
                  type="text"
                  name="granter2_name"
                  value={formData.granter2_name}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded-[20px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-[20px] font-bold mb-2">
                  Guarantee 2 Contact
                </label>
                <input
                  type="text"
                  name="granter2_contact"
                  value={formData.granter2_contact}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded-[20px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default View_Loan_Info;
