import React, { useState, useEffect } from "react";
import Retirement from "./retirement";
import { Link } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../../Assets/icons/csv.png";
import approvalpending from "../../../../Assets/icons/image.png";
import usePermissions from "../../../../components/permissions/permission";

const View_Export_Table = () => {
  const [employees, setEmployees] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { hasPermission } = usePermissions();

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/getemployeebasicdetails`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEmployees(data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees = employees.filter((employee) => {
    const statusFilterMatch =
      filterOption === "" ||
      (filterOption === "ACTIVE" &&
        employee.employee_active_status === "ACTIVE") ||
      (filterOption === "INACTIVE" &&
        employee.employee_active_status === "INACTIVE");

    const searchMatch =
      !searchQuery ||
      employee.employee_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.employee_epf_no
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      employee.employee_nic.toLowerCase().includes(searchQuery.toLowerCase());

    const monthMatch =
      !searchMonth ||
      new Date(employee.permanant_appoinment_date).getMonth() + 1 ===
        parseInt(searchMonth);

    const yearMatch =
      !searchYear ||
      new Date(employee.permanant_appoinment_date).getFullYear().toString() ===
        searchYear;

    return statusFilterMatch && searchMatch && monthMatch && yearMatch;
  });

  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExportCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      employees.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "employee_data.csv");
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      window.alert("Successfully Downloaded");
    }, 100);
  };

  const handleDownloadPDF = async (employeeNo) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadEmployeePDF?employee_no=${employeeNo}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${employeeNo}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View & Export Details
      </p>
      <div className="flex justify-between overflow-hidden">
        <div>
          <div>
            <div className="flex flex-col">
              <label
                htmlFor="nic_number"
                className="text-xl mb-2 font-bold text-[#797C80] font-sans"
              ></label>
              <select
                id="filter"
                name="filter"
                value={filterOption}
                onChange={handleFilterChange}
                className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
              >
                <option value="">All</option>
                <option value={"ACTIVE"}>Active</option>
                <option value={"INACTIVE"}>Inactive</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by EMP NO, EPF NO, NIC"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="monthFilter"
              name="monthFilter"
              value={searchMonth}
              onChange={handleMonthChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option
                className="placeholder-white sm:text-sm px-2 font-sans"
                value=""
              >
                Filter By Joined Month
              </option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="yearFilter"
              name="yearFilter"
              onChange={handleYearChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option value="">Filter By Joined Year</option>
              {Array.from(
                { length: 20 },
                (v, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        {hasPermission(2003) && (
          <div className="p-[50px]">
            <Retirement />
          </div>
        )}
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px]">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead className="sticky top-0">
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Emp NO</th>
              <th className="text-primary px-6 py-4">Epf NO</th>
              <th className="text-primary px-6 py-4">Full Name</th>
              <th className="text-primary px-6 py-4">Gender</th>
              <th className="text-primary px-6 py-4">Current Address</th>
              <th className="text-primary px-6 py-4">NIC</th>
              <th className="text-primary px-6 py-4">Joined Date</th>
              <th className="text-primary px-6 py-4">Date of Birth</th>
              <th className="text-primary px-6 py-4">
                Status (Active/Inactive)
              </th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentItems.map((employee) => (
              <tr key={employee.id}>
                <td className="bg-white px-6 py-4">{employee.employee_no}</td>
                <td className="bg-white px-6 py-4">
                  {employee.employee_epf_no}
                </td>
                <td className="bg-white px-6 py-4">
                  {employee.employee_name_initial}
                </td>
                <td className="bg-white px-6 py-4">
                  {employee.employee_gender}
                </td>
                <td className="bg-white px-6 py-4">
                  {employee.employee_permanent_address}
                </td>
                <td className="bg-white px-6 py-4">{employee.employee_nic}</td>
                <td className="bg-white px-6 py-4">
                  {employee.permanant_appoinment_date}
                </td>
                <td className="bg-white px-6 py-4">{employee.employee_dob}</td>
                <td className="bg-white px-6 py-4">
                  {employee.employee_active_status}
                </td>
                <td className="bg-white px-6 py-4">
                  {employee.approved_status === null ||
                  employee.approved_status === "PENDING" ? (
                    <div className="flex items-center justify-center">
                      <div className="bg-[#EEE0DF] rounded-[22px] w-[220px] h-[44.41px] flex items-center gap-2 justify-center">
                        <img
                          src={approvalpending}
                          alt="approvalpending"
                          className="w-8 h-8"
                        />{" "}
                        <span>Approval Pending</span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center gap-3 w-[220px]">
                      {hasPermission(2001) && (
                        <button
                          className="bg-primary p-2 rounded-md text-white w-[100px]"
                          onClick={() =>
                            handleDownloadPDF(employee.employee_no)
                          }
                        >
                          Download
                        </button>
                      )}
                      <Link to={`/user-profile/${employee.employee_no}`}>
                        {hasPermission(2000) && (
                          <button className="bg-gray-300 p-2 rounded-md w-[100px]">
                            View & Edit
                          </button>
                        )}
                      </Link>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      {hasPermission(2002) && (
        <div className="flex justify-end mb-4 mr-4">
          <button className="bg-primary text-white font-sans p-3 rounded-[20px]">
            <div className="flex items-center" onClick={handleExportCSV}>
              <div onClick={handleExportCSV} className="font-semibold">
                Export CSV
              </div>
              <div>
                <img className="h-8 w-8" src={CSV} alt="logo-csv" />
              </div>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default View_Export_Table;
