import React, { useState } from "react";

const EditDialog = ({ member, onClose, onEdit }) => {
  const [editedRow, setEditedRow] = useState({ ...member });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "committee_appointing_date") {
      // Convert from YYYY-MM-DD to DD/MM/YYYY
      const [year, month, day] = value.split("-");
      setEditedRow({ ...editedRow, [name]: `${day}/${month}/${year}` });
    } else {
      setEditedRow({ ...editedRow, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const today = new Date();
    const formattedToday = `${today.getDate().toString().padStart(2, "0")}/${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`;

    const updatedRow = {
      ...editedRow,
      committee_edit_date: formattedToday,
      taken_decision: "edit",
    };
    onEdit(updatedRow);
    onClose();
  };

  const formatDateForInput = (dateString) => {
    // Convert from DD/MM/YYYY to YYYY-MM-DD
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[876px] h-[647px]">
        <form onSubmit={handleSubmit}>
          <h2 className="text-addBtn font-poppins font-semibold text-[26px] ml-6 mt-4">
            Edit Member
          </h2>
          <hr className="w-1/6 border-t-4 border-[#CCCCCC] ml-12 " />
          <div className="ml-[97%]">
            <button
              onClick={onClose}
              className="absolute flex justify-center border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
            >
              <span className="text-white font-bold text-xl">X</span>
            </button>
          </div>
          <div className="mt-6">
            <label
              htmlFor="employeeId"
              className="block text-sm font-medium text-gray-700"
            >
              Employee ID
            </label>
            <input
              type="text"
              id="employeeId"
              name="committee_user_id"
              value={editedRow.committee_user_id}
              onChange={handleInputChange}
              className="input rounded-full w-[300px] h-10 px-4 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 font-bold"
            />
          </div>
          <div className="mt-6">
            <label
              htmlFor="appointingDate"
              className="block text-sm font-medium text-gray-700"
            >
              Appointing Date
            </label>
            <input
              type="date"
              id="appointingDate"
              name="committee_appointing_date"
              value={formatDateForInput(editedRow.committee_appointing_date)}
              onChange={handleInputChange}
              className="input rounded-full w-[300px] h-10 px-4 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400 font-bold"
            />
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="btn btn-secondary mr-4"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDialog;
