 

import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import usePermissions from "../../../../components/permissions/permission";

const PurchaseRequestView = () => {
  const { hasPermission } = usePermissions();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [prNo, setPrNo] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCurrentDate, setSelecteCurrentdDate] = useState(new Date());

  // Reset function
  const handleReset = () => {
    setFrom("");
    setTo("");
    setPrNo("");
    setSelectedDate(null);
    setSelecteCurrentdDate(new Date());
  };

  // Submit function
  const handleSubmit = () => {
    // Add your submit logic here
    console.log("Form submitted");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div className="flex items-center">
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          View Purchase Request
        </p>
        <label className="mr-7 text-black font-poppins font-bold text-xl ml-auto">
          Status:
        </label>
        <button
          className={`inline-block font-bold text-xl bg-approveStat border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0 text-approveStattxt`}
        >
          Approved
        </button>
      </div>
      <div className="square absolute ml-6 mt-[3%] w-full sm:w-4/5 rounded-[46px] border border-black bg-[#F3F8FF]">
        <div className="flex flex-wrap ml-6 mt-6">
          <div className="w-1/2 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              From:
            </label>
            <select
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6"
              disabled
            ></select>
          </div>

          <div className="w-1/2 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              To:
            </label>
            <select
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6"
              disabled
            ></select>
          </div>

          <div className="w-1/2 mt-6 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              PR NO:
            </label>
            <input
              type="text"
              value={213425}
              onChange={(e) => setPrNo(e.target.value)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/6"
            />
          </div>

          <div className="w-1/2 mt-6 flex items-center">
            <label className="mr-40 text-black font-poppins font-bold text-xl">
              Date:
            </label>
            <DatePicker
              selected={selectedCurrentDate}
              onChange={(date) => setSelecteCurrentdDate(date)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/2"
              disabled
            />
          </div>

          <div className="w-1/2 mt-6 flex items-center">
            <label className="mr-28 text-black font-poppins font-bold text-xl">
              Required Date:
            </label>
            <DatePicker
              selected={selectedCurrentDate}
              onChange={(date) => setSelecteCurrentdDate(date)}
              className="border border-gray-300 rounded-lg px-2 py-2 w-2/2"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="absolute ml-6 mr-6 mt-[20%] rounded-lg border border-black bg-[#F3F8FF] sm:h-1/2">
        <table className="rounded-lg">
          <thead className="table w-full bg-gray-800 text-[#656565] rounded-lg">
            <tr>
              <th className="border px-4 py-2 text-left font-bold ">Item No</th>
              <th className="border px-4 py-2 text-left font-bold ">
                Description
              </th>
              <th className="border px-4 py-2 text-left font-bold ">Unit</th>
              <th className="border px-4 py-2 text-left font-bold ">
                Quantity
              </th>
              <th className="border px-4 py-2 text-left font-bold ">Instock</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>

      <div className="mt-[50%] flex justify-center mb-6 ">
        <div className="w-[272px] h-[155px] bg-[#F3F8FF] rounded-[20px] flex-shrink-0">
          <div className="p-4">
            <h2 className="text-[#272525] font-poppins font-extrabold text-24">
              Order By
            </h2>
          </div>
          <div className="w-[169px] h-[41px] bg-[#FFF] mt-2 ml-12 rounded-lg"></div>
        </div>

        <div className="w-[272px] h-[155px] bg-[#F3F8FF] rounded-[20px] flex-shrink-0 ml-12">
          <div className="p-4">
            <h2 className="text-[#272525] font-poppins font-extrabold text-24">
              Approved By
            </h2>
          </div>
          <div className="w-[169px] h-[41px] bg-[#FFF] mt-2 ml-12 rounded-lg"></div>
        </div>

        <div className="w-[272px] h-[155px] bg-[#F3F8FF] rounded-[20px] flex-shrink-0 ml-12">
          <div className="p-4">
            <h2 className="text-[#272525] font-poppins font-extrabold text-24">
              Prepared By
            </h2>
          </div>
          <div className="w-[169px] h-[41px] bg-[#FFF] mt-2 ml-12 rounded-lg"></div>
        </div>
      </div>
      <div className="flex items-center mt-[6%] ml-[10%]">
        <label className="mr-7 text-black font-poppins font-bold text-xl">
          Cost Estimation:
        </label>
        <div className="w-[169px] h-[41px] bg-[#FFF] rounded-lg mr-10"></div>
        {hasPermission(2602) && (
          <Link
            to="/purchase/viewPurchaseRequest"
            className="ml-4 text-addBtn hover:underline font-extrabold"
          >
            View
          </Link>
        )}
      </div>
      <div>
        {hasPermission(2601) && (
          <button
            onClick={handleSubmit}
            className="bg-addBtn hover:bg-addBtnHOver text-white font-bold text-2xl py-2 px-4 rounded-[45px] mt-[6%] mb-[6%] ml-[10%]"
          >
            Send For Chairman Approval
          </button>
        )}
      </div>
    </div>
  );
};

export default PurchaseRequestView;
