/** @format */

import React, { useState, useEffect, useCallback, useMemo } from "react";

const Designation_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  //that shoulde change

  const permissionHierarchy = useMemo(
    () => ({
      // 13 sidebar id sub menu items 220-221
      13: { children: [220, 221] },
      220: { children: [1730] },
      221: { children: [1740, 1750] },

      // Add the rest of your permissions here
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
    console.log("Key Values", checkedValues);
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[13].children.includes(perm)
      ) &&
      !selectedPermissions.includes(13)
    ) {
      selectedPermissions.push(13);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline"
            Value="1"
          >
            Designations
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Add Designation
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1730"
                      checked={!!checkedValues[1730]}
                      onChange={() => handleCheckboxChange(1730)}
                    />
                    Add Designation
                  </label>
                </div>
              </div>
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  View & Update Designation
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1740"
                      checked={!!checkedValues[1740]}
                      onChange={() => handleCheckboxChange(1740)}
                    />
                    Update Designation Details
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1750"
                      checked={!!checkedValues[1750]}
                      onChange={() => handleCheckboxChange(1750)}
                    />
                    Delete Designation Details
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Designation_Permission;
