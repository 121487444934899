import React, { useState, useEffect, useRef } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import ReactToPrint from "react-to-print";
import axios from "axios";

const Purchase_Voucher_View = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tenderData = location.state;
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [VoucherData, setVoucherData] = useState({
    tender_voucher_amount: [],
  });

  console.log("Tender Data:", tenderData);

  const handleViewNavigate = () => {
    navigate("/voucher/edit", { state: { VoucherData, tenderData } });
  };

  useEffect(() => {
    if (tenderData && tenderData.tender_id) {
      VoucherGetData(tenderData.tender_id);
    } else {
      console.warn("Missing ID in tenderData. Please provide a valid ID.");
    }
  }, [tenderData]); // Added tenderData as a dependency

  const VoucherGetData = async (id) => {
    console.log("ID value:", id);
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/voucher/get/${id}`
      );
      setVoucherData(response.data[0]);
    } catch (error) {
      console.error("Error fetching voucher data:", error);
    }
  };

  console.log("Voucher Data:", VoucherData);

  const componentRef = useRef();
  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen " ref={componentRef}>
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Voucher
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2">
            Tender ID:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderData.tender_id}
          ></input>

          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Tender Name:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderData.tender_name}
          ></input>
        </div>
      </div>

      {/* Second form part */}
      <div className=" flex justify-between items-center">
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          View Voucher
        </p>
        <button
          className=" font-bold bg-[#797C80] rounded-[22px] text-[22px] text-white h-10 w-20 mt-4 mr-12 print:hidden"
          onClick={handleViewNavigate}
        >
          Edit
        </button>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">Date:</p>
        </div>
        <div className="mt-2">
          <DatePicker
            customInput={
              <div className="relative">
                <input
                  type="text"
                  className="rounded-lg border border-gray-300 h-8 pl-10 pr-4 text-gray-600 "
                  value={VoucherData.date}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                  <FaCalendarAlt className="text-gray-400" />
                </div>
              </div>
            }
            className="rounded-lg border border-gray-300 w-40 h-8 ml-10"
          />
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Service Station:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2"
            value={VoucherData.service_station}
          ></input>
        </div>
        <div className="flex items-center justify-end">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Voucher No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8"
            value={VoucherData.voucher_number}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Ledger Account:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={VoucherData.ledger_account}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Cost Ledger Pg No:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300 ml-10 h-8"
            value={VoucherData.cost_ledger_page}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Account Code No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={VoucherData.account_code}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Cash Book Voucher No:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={VoucherData.cash_voucher_number}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Nature of expenditure:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={VoucherData.expenditure_nature}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name of the Cheques are to be Written:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={VoucherData.cheque_name}
          ></input>
        </div>
        <div className="flex items-center justify-end ">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name and the Address of the Payee:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 "
            value={VoucherData.payee_name_address}
          ></input>
        </div>
      </div>

      {/* Third form part */}
      {VoucherData.tender_voucher_amount.map((amount, index) => (
        <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
          <div className="flex items-center justify-end mt-2">
            <p className="text-[22px] text-[#8691AB] font-bold ml-6">Date:</p>
          </div>
          <div>
            <DatePicker
              customInput={
                <div className="relative">
                  <input
                    type="text"
                    className="rounded-lg border border-gray-300 h-8 pl-10 pr-4 text-gray-600"
                    placeholder="Select Date"
                    value={amount.tender_voucher_amount_date}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                    <FaCalendarAlt className="text-gray-400" />
                  </div>
                </div>
              }
              className="rounded-lg border border-gray-300 w-40 h-8 ml-10 mt-2"
            />
          </div>
          <div className="flex items-center justify-end mt-2">
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
              Description:
            </p>
          </div>
          <div className=" row-span-2">
            {" "}
            <textarea
              className=" rounded-[22px] border border-gray-300  ml-10 h-20 mt-2"
              value={amount.tender_voucher_amount_description}
            ></textarea>
          </div>
          <div className="flex items-center justify-end">
            <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
              Quantity :
            </p>
          </div>
          <div>
            {" "}
            <input
              className=" rounded-[22px] border border-gray-300  ml-10 h-8"
              value={amount.tender_voucher_amount_amount}
            ></input>
          </div>
        </div>
      ))}
      {/* Forth form part */}

      <div className="bg-[#F3F8FF] w-[95%]  mt-10 ml-6 rounded-[46px] grid grid-cols-4 gap-4">
        <div className="flex items-center justify-end mt-2">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Total In Letters:
          </p>
        </div>
        <div>
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 bm-2"
            value={VoucherData.total_letters}
          ></input>
        </div>
        <div className="flex items-center justify-end mt-2">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mb-2">
            Total Amount:
          </p>
        </div>
        <div>
          {" "}
          <input
            className=" rounded-[22px] border border-gray-300  ml-10 h-8 mt-2 mb-2"
            value={VoucherData.total_amount}
          ></input>
        </div>
      </div>

      <div className=" flex justify-between w-[95%] ml-6">
        {/* First Drag and drop box */}
        <div className="">
          <div className="flex flex-col items-center justify-center mt-6">
            <p className="text-black font-bold ml-2 mt-2 text-[24px]">
              Approved By
            </p>
            <div className="bg-white border border-gray-300 w-40 h-40 rounded-lg flex flex-col items-center justify-center mb-6">
              <img
                src={`${REACT_APP_API_ENDPOINT}/v1/inventory/voucher/file/${VoucherData.approved_img}`}
                alt="Preview"
              />
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <div>
              <p className=" font-bold text-[#8691AB]">
                {VoucherData.approved_date}
              </p>
            </div>
          </div>
        </div>

        {/* Second Drag and drop box */}
        <div className="">
          <div className="">
            <div className="flex flex-col items-center justify-center mt-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Recommended By
              </p>
              <div className="bg-white border border-gray-300 w-40 h-40 rounded-lg flex flex-col items-center justify-center mb-6">
                <img
                  src={`${REACT_APP_API_ENDPOINT}/v1/inventory/voucher/file/${VoucherData.recommended_img}`}
                  alt="Preview"
                />
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <p className=" font-bold text-[#8691AB]">Date:</p>
              </div>
              <div>
                <p className=" font-bold text-[#8691AB]">
                  {VoucherData.recommended_date}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Theird Drag and drop box */}
        <div className="">
          <div className="">
            <div className="flex flex-col items-center justify-center mt-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Prepared By
              </p>
              <div className="bg-white border border-gray-300 w-40 h-40 rounded-lg flex flex-col items-center justify-center mb-6">
                <img
                  src={`${REACT_APP_API_ENDPOINT}/v1/inventory/voucher/file/${VoucherData.prepared_img}`}
                  alt="Preview"
                />
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <p className=" font-bold text-[#8691AB]">Date:</p>
              </div>
              <div>
                <p className=" font-bold text-[#8691AB]">
                  {VoucherData.prepared_date}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Fourth Drag and drop box */}
        <div className="">
          <div className="">
            <div className="flex flex-col items-center justify-center mt-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Checked By
              </p>
              <div className="bg-white border border-gray-300 w-40 h-40 rounded-lg flex flex-col items-center justify-center mb-6">
                <img
                  src={`${REACT_APP_API_ENDPOINT}/v1/inventory/voucher/file/${VoucherData.checked_img}`}
                  alt="Preview"
                />
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <p className=" font-bold text-[#8691AB]">Date:</p>
              </div>
              <div>
                <p className=" font-bold text-[#8691AB]">
                  {VoucherData.checked_date}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Fifth Drag and drop box */}
        <div className="">
          <div className="flex flex-col items-center justify-center mt-6">
            <p className="text-black font-bold ml-2 mt-2 text-[24px]">
              Certified By
            </p>
            <div className="bg-white border border-gray-300 w-40 h-40 rounded-lg flex flex-col items-center justify-center mb-6">
              <img
                src={`${REACT_APP_API_ENDPOINT}/v1/inventory/voucher/file/${VoucherData.certified_img}`}
                alt="Preview"
              />
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <p className=" font-bold text-[#8691AB]">Date:</p>
            </div>
            <div>
              <p className=" font-bold text-[#8691AB]">
                {VoucherData.certified_date}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6 mt-6 mr-10 flex justify-end">
        <ReactToPrint
          trigger={() => (
            <button className="bg-[#797C80] text-[20px] font-bold text-white rounded-[33px] w-40 h-8 print:hidden">
              Export as PDF
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default Purchase_Voucher_View;
