/** @format */

import "./App.css";
import SideMenu from "./components/sidebar/sidebar.jsx";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Login from "./components/login/login";

//pos
import Pos from "./modules/pos/pos/pos";
import Bill from "./modules/pos/bills/bill";
import PosDashboard from "./modules/pos/pos dashboard/posDashboard";
import PosItems from "./modules/pos/positems/positems.jsx";
import MobilePos from "./modules/pos/mobile-pos/mobile-pos.jsx";

//inventory
import InventoryDispose from "./modules/inventory/disposal/inventoryDispose";
import InventoryDashboard from "./modules/inventory/inventory dashboard/inventoryDashboard";
import InventoryPurchase from "./modules/inventory/inventory purchase/inventoryPurchase";
import InventoryStore from "./modules/inventory/inventory store/inventoryStore";
import StoreKeeperRelease from "./modules/inventory/release/store keeper/StoreKeeperRelease";
import InventoryRequest from "./modules/inventory/request/inventory request from store keeper/inventoryRequest";
import DisposedItems from "./modules/inventory/disposed items/disposedItems.jsx";
import InventoryReturns from "./modules/inventory/Inventory Returns/inventoryReturns.jsx";
import InventoryAdjustment from "./modules/inventory/Inventory Adjustment/inventoryAdjustment.jsx";
import InventoryRequestNav from "./modules/inventory/inventoryRequest_nav.jsx";
import InventoryBranchRequest from "./modules/inventory/request/branchInventoryRequest.jsx";
import ApproveInventoryBranchRequest from "./modules/inventory/request/approveItemReqFromBranch.jsx";
import ViewItemApprovedInventoryBranchRequest from "./modules/inventory/request/viewApprovedbranchInventoryRequest.jsx";
import ViewApprovedInventoryBranchRequest from "./modules/inventory/request/approvedItemReqFromBranch.jsx";
import AddItemManually from "./modules/inventory/addItemManually.jsx";

//customer
import AddCustomer from "./modules/customer/add customer/addCustomer";
import UpdateCustomer from "./modules/customer/update customer/updateCustomer";
import CreditClearance from "./modules/customer/credit clearance/creditClearance";

//item category
import AddItemCategory from "../src/modules/item category/add item category/addItemCategory";
import UpdateItemCategory from "../src/modules/item category/update item category/updateItemCategory";

//item
import CreateItem from "./modules/items/create item/createItem";
import UpdateItem from "./modules/items/update item/updateItem";

//branch
import AddBranches from "./modules/branches/add branch/addBranches";
import UpdateBranches from "./modules/branches/update branch/updateBranches";

//stores
import AddStores from "./modules/stores/addStores/addStores";
import UpdateStores from "./modules/stores/updateStores/updateStores";

//supplier
import AddSupplier from "./modules/supplier/add supplier/addSupplier";
import UpdateSupplier from "./modules/supplier/update supplier/updateSupplier";

//user
import AddUser from "./modules/users/add user/addUser";
import UpdateUser from "./modules/users/update user/updateUser";
import UserRole from "./modules/users/user role/userRole";

//*production planing-------------------------------------------------------------------------->

//recipe
import CreateRecipe from "./modules/production_planing/recipe/CreateRecipe";
import CreatedRecipe from "./modules/production_planing/recipe/CreatedRecipe";
import PendingRecipes from "./modules/production_planing/recipe/PendingRecipes";
import RejectedRecipe from "./modules/production_planing/recipe/RejectedRecipes";

//*job
import CreateJob from "./modules/production_planing/job/CreateJob";
import CreatedJob from "./modules/production_planing/job/CreatedJob";
import RejectedJobs from "./modules/production_planing/job/RejectedJob";
import PendingJobs from "./modules/production_planing/job/PendingJob";

//*unit
import Unit from "./modules/production_planing/unit/Unit";
import ChargePerHour from "./modules/production_planing/unit/ChargePerHour";

//*manage production
import Manage_Production from "./modules/production_planing/manage_production/Manage_Production";

//*production
import StartJob from "./modules/production_planing/production/StartJob";
import CreateProduction from "./modules/production_planing/production/CreateProduction";

//*warehouse
import WarehouseMangement from "./modules/production_planing/warehouse/WarehouseMangement";
import WarehouseCompleteTable from "./modules/production_planing/warehouse/WarehouseCompleteTable";
import WarehousePending from "./modules/production_planing/warehouse/WarehousePending";
import ManageReject from "./modules/production_planing/warehouse/WarehouseReject";
import ManageAdjustments from "./modules/production_planing/warehouse/WarehouseAdjustment";
import Job_Nav from "./modules/production_planing/job_nav.jsx";
import Recipe_Nav from "./modules/production_planing/recipe_nav.jsx";
import Warehouse_Nav from "./modules/production_planing/warehouse_nav.jsx";
//auth
import UserAuth from "./Auth/posUserAuth";

//Designation
import AddDesignation from "./modules/designation/add designation/adddesignation";
import UpdateDesignation from "./modules/designation/update designation/updatedesignation";

//Training Type
import AddTraningType from "./modules/training_type/add_training_type";
import UpdateTrainingType from "./modules/training_type/update_training_type";

//Hris
import Dashboard from "./modules/hris/dashboard/card.jsx";
import Onboard from "./modules/hris/employee/emp_onBoard.jsx";
import Emp_Nav from "./modules/hris/employee/emp_nav.jsx";
import View_Export_Table from "./modules/hris/employee/view_export/view_export_table.jsx";
import History_Log from "./modules/hris/employee/history_log.jsx";
import User_Profile from "./modules/hris/employee/user_profile.jsx";
import Attendance from "./modules/hris/attendance/Attendance.jsx";
import Inandout_Regular from "./modules/hris/attendance/in_out/in_and_out_time_regular.jsx";
import Inandout_Shift from "./modules/hris/attendance/in_out/in_and_out_time_shift.jsx";
import Leave from "./modules/hris/attendance/leave/leave.jsx";
import Lateness_Permission from "./modules/hris/attendance/late_permission/lateness_permission.jsx";
import Leave_Doc from "./modules/hris/attendance/late_permission/document_log.jsx";
import OT from "./modules/hris/attendance/OT/OT.jsx";
import Overtime from "./modules/hris/payrole/incentive/overtimr.jsx";
import leaveapproval1 from "./modules/hris/approval_process/approval1/Leave_approve.jsx";
// import Payments_Menu from "./modules/hris/payrole/payroll_nav.jsx";
import AllowancesandDeductions from "./modules/hris/payrole/month-end/allowance_and_deduction_table.jsx";
import Training from "./modules/hris/performance/trainings/training_cards.jsx";
import Completed_Training from "./modules/hris/performance/trainings/completed_training.jsx";
import Upcoming_Trainings from "./modules/hris/performance/trainings/upcoming_trainings.jsx";
import Examinations_Card from "./modules/hris/performance/examinations/examinations_card.jsx";
import Reports_Analytics from "./modules/hris/reports_analytics/reports_analytics.jsx";
import Performance_Nav from "./modules/hris/performance/performance_nav.jsx";
import Completed_Examinations from "./modules/hris/performance/examinations/completed/completed_examinations.jsx";
import Upcoming_Examinations from "./modules/hris/performance/examinations/upcoming/upcoming_examinations.jsx";
import Salary_Increments from "./modules/hris/performance/salary/salary_increments.jsx";
import Leave_Management from "./modules/hris/leave_management/leave-nav.jsx";
import Leave_Info from "./modules/hris/leave_management/leave-info.jsx";
import Loan_Nav from "./modules/hris/loan_information/loan_nav.jsx";
import View_Loan_Info from "./modules/hris/loan_information/view_loan_info.jsx";
import Review_Guarantors from "./modules/hris/loan_information/review_guarantors.jsx";
import Loan_Elibility from "./modules/hris/loan_information/loan_eligibility.jsx";
import Leave_Reports from "./modules/hris/reports_analytics/leave_reports_info.jsx";
import Approval1 from "../src/modules/hris/approval_process/approval1/approval1.jsx";
import Approval2 from "../src/modules/hris/approval_process/approval2/approval2.jsx";
import Approval1_Info from "./modules/hris/approval_process/approval1/Leave_approve.jsx";
import Approval2_Info from "../src/modules/hris/approval_process/approval2/approval2_table.jsx";
import Approval1user from "../src/modules/hris/approval_process/approval1/approve1_tableuser.jsx";
import Approval2user from "../src/modules/hris/approval_process/approval2/appoval_user_details.jsx";
import Allpayments from "./modules/hris/payrole/month-end/all_allowance.jsx";
import Timetable from "../src/modules/hris/attendance/timetable_attendance/timetable.jsx";
import Payments_Menu from "../src/modules/hris/payrole/payroll_nav.jsx";
import Allowances from "./modules/hris/payrole/month-end/allowances.jsx";
import Deductions from "./modules/hris/payrole/month-end/deduction.jsx";
import AllPayroll from "./modules/hris/payrole/month-end/all_payroll.jsx";
import AllDeductions from "./modules/hris/payrole/month-end/all_deduction.jsx";
import All_allowance from "./modules/hris/payrole/month-end/all_allowance.jsx";
import MonthendPayroll from "./modules/hris/payrole/month-end/monthendpayroll.jsx";

//suppychain
import Per_Request from "./modules/supply/Purchasing/Purchasing Request/request.jsx";
import Per_Request_Form from "./modules/supply/Purchasing/Purchase Request Form/requestform.jsx";
import PurchaseRequestView from "./modules/supply/Purchasing/View Purchase Request/view.jsx";
import PurchaseRequestViewChairman from "./modules/supply/Purchasing/Request Review by/view.jsx";
import Decisions_home from "./modules/supply/Decisions/Decisions Home/home.jsx";
import ActivityLog from "./modules/supply/Decisions/Activity Log/activitylog.jsx";
import SupplySuppliers from "./modules/supply/Suppliers/supply.jsx";
import Supply_Category from "./modules/supply/Suppliers/Category Instance/category.jsx";
import Supply_Edit from "./modules/supply/Suppliers/EditSupplier/addForm.jsx";
import Committees_Home from "./modules/supply/Committees/home.jsx";
import Appoint_Members from "./modules/supply/Committees/Appoint Members/members.jsx";
import Technical_Members from "./modules/supply/Committees/Technical Evaluation/evaluation.jsx";
import Tender_Opening_Committees_Home from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/mailComponent.jsx";
import Dashboard_Home from "./modules/supply/Dashboard/home.jsx";
import Send_A_Mail from "./modules/supply/Dashboard/Send A Mail/mailComponent.jsx";
import Create_A_Tender from "./modules/supply/Dashboard/Create Tender/createForm.jsx";
import Acceptance_Letter from "./modules/supply/Dashboard/Acceptance Letter/createLeter.jsx";
import Update_Acceptance_Letter from "./modules/supply/Dashboard/Update Tender/updateForm.jsx";
import Sent_For_Approvals from "./modules/supply/Committees/Sent For Approvals/home.jsx";
import Bid_Evaluation_Summary_Report from "./modules/supply/Committees/Technical Evaluation/Bid Evaluation Summary Report/createReport.jsx";
import Bid_Evaluation_Summary_Report_view from "./modules/supply/Committees/Technical Evaluation/Bid Evaluation Summary Report View/viewReport.jsx";
import Create_Commitee_Meeting_Report from "./modules/supply/Committees/Technical Evaluation/Commitee Meeting Report/createReport.jsx";
import View_Commitee_Meeting_Report from "./modules/supply/Committees/Technical Evaluation/Commitee Meeting Report/viewReport.jsx";
import Opening_Price_Report_Form from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/openingPriceForm.jsx";
import Opening_Price_Report_Form_View from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/viewOpeningPriceForm.jsx";
import Sample_Testing from "./modules/supply/Committees/Sample Testing/sampleTesting.jsx";
import Sample_Testing_View from "./modules/supply/Committees/Sample Testing/sampletestingView.jsx";
import Sample_Testing_Document_View from "./modules/supply/Committees/Sample Testing/sampleDocumentView.jsx";
import Sample_Commitee_Report from "./modules/supply/Committees/Sample Testing/sample_commitee_report.jsx";
import Purchase_Order from "./modules/supply/Purchase Order/home.jsx";
import Purchase_Order_Create from "./modules/supply/Purchase Order/Purchase Order/create.jsx";
import Purchase_Order_View from "./modules/supply/Purchase Order/Purchase Order/view.jsx";
import Purchase_Order_PDF from "./modules/supply/Purchase Order/Purchase Order/purchaseOrderPDF.jsx";
import Purchase_Voucher_Create from "./modules/supply/Purchase Order/Voucher/create.jsx";
import Purchase_Voucher_View from "./modules/supply/Purchase Order/Voucher/view.jsx";
import Purchase_Voucher_Edit from "./modules/supply/Purchase Order/Voucher/edit.jsx";
import TecBidEvaluationReport from "./modules/supply/Committees/Technical Evaluation/Bid Evaluation Summary Report View/tec_bid_evaluation_report.jsx";
import Tec_Meeting_Report from "./modules/supply/Committees/Technical Evaluation/Commitee Meeting Report/tec_meeting_report.jsx";
import OpeningPricing from "./modules/supply/Committees/Technical Evaluation/Tender Opening Committee/opening_price.jsx";
import Error from "../src/components/404error.jsx";
import User_Management from "./modules/settings/user_management/user_management.jsx";

//settings
import Settings_User from "../src/modules/settings/user.jsx";
import Setting_Permission from "../src/modules/settings/userpermission.jsx";
import Add_User from "./modules/settings/user_management/add_user.jsx";
import Approval_1 from "./modules/pos/approve/approve_1.jsx";
import Approval_1_inventory from "./modules/inventory/Approval/approval.jsx";
import Approval_1_supply from "./modules/supply/Approval/approve_1.jsx";

//newly added approvals module
import Approval_1_Modules from "./modules/approvals/approve_1/approve_1.jsx";
import Approve_2_Modules from "./modules/approvals/approve_2/approve_2.jsx";

// Branch Request
import BranchRequest from "./modules/BranchReq/ItemReqFromBranch.jsx";
import ViewBranchRequest from "./modules/BranchReq/viewItemReqFromBranch.jsx";
import BranchRequestNav from "./modules/BranchReq/ItemReqFromBranch_nav.jsx";
import CheckBranchRequest from "./modules/BranchReq/checkedItemReqFromBranch.jsx";
import VewApprovedBranchRequest from "./modules/BranchReq/viewApprovedItemReqFromBranch.jsx";
import ApprovedBranchRequest from "./modules/BranchReq/approvedItemReqFromBranch.jsx";
import ProductionValue from "./modules/hris/payrole/incentive/production_value.jsx";
import SectionIncentive from "./modules/hris/payrole/incentive/section_incentive.jsx";
import SLAB from "./modules/hris/payrole/incentive/SLAB.jsx";
import SpecialIncentive from "./modules/hris/payrole/incentive/special_incentive.jsx";
import Leave_amount from "./modules/hris/payrole/incentive/leave_amount.jsx";
import Incentive from "./modules/hris/payrole/incentive/incentive_payroll.jsx";
import GeneratedPayroll from "./modules/hris/payrole/Generated_payroll/generated_payroll.jsx";
const SidebarChecker = ({ children }) => {
  const location = useLocation();
  const validRoutes = [
    "/pos/pos-approval",
    "/pos/pos",
    "/pos/bill",
    "/pos/pos-dashboard",
    "/inventory/dispose",
    "/inventory/disposed",
    "/inventory/dashboard",
    "/inventory/purchase",
    "/inventory/store",
    "/inventory/adjust",
    "/inventory/return",
    "/inventory/store-keeper-release",
    "/inventory/request-nav", // card page for inventory requests
    "/inventory/request", //inventory Production Item Request
    "/inventory/branch-item-req", //inventory Branch Item Request
    "/inventory/view-approve-branch-req", // inventory Branch Item request to Approve
    "/inventory/view-approved-branch-req", // inventory Branch Requests Approved
    "/inventory/view-item-approved-branch-req", //inventory Branch Items Requests Approved
    "/inventory/add-item-manualy", // Inventory add Item Manually
    "/inventory/approval",
    "/branches/add",
    "/branches/update",
    "/customer/add",
    "/customer/update",
    "/customer/credit",
    "/itemcategory/add",
    "/itemcategory/update",
    "/items/create",
    "/items/update",
    "/stores/add",
    "/stores/update",
    "/supplier/add",
    "/supplier/update",
    "/users/add",
    "/users/update",
    "/users/role",
    "/designation/add",
    "/designation/update",
    "/trainingtype/add",
    "/trainingtype/update",
    "/create-recipe",
    "/pending-recipe",
    "/created-recipe",
    "/rejected-recipe",
    "/created-job",
    "/create-job",
    "/pending-job",
    "/rejected-job",
    "/make-units",
    "/chargeperhour",
    "/start-production",
    "/create-production",
    "/warehouse",
    "/warehouse-Complete",
    "/warehouse-pending",
    "/warehouse-Reject",
    "/warehouse-Adjustments",
    "/job-nav",
    "/recipe_nav",
    "/warehouse_nav",
    "/manage-production",
    "/dashboard",
    "/emp-onboarding",
    "/emp-nav",
    "/view-export-table",
    "/history-log",
    "/user-profile/:employee_no",
    "/attendance",
    "/in-and-out-regular",
    "/in-and-out-shift",
    "/leaves",
    "/lateness-permission",
    "/leave-doc",
    "/over-time",
    "/payments-menu",
    "/payment_details/:value/:name",
    "/training",
    "/completed-training",
    "/upcoming-training",
    "/examinations",
    "/reports-analytics",
    "/performance-nav",
    "/completed_examinations",
    "/upcoming-examinations",
    "/salary-increments",
    "/leave-management",
    "/leave-info",
    "/loan-nav",
    "/view-loan-info",
    "/review-guarantors",
    "/loan-eligible",
    "/supplychain",
    "/purchase/request",
    "/purchase/requestForm",
    "/purchase/viewPurchaseRequest",
    "/purchase/viewPurchaseRequestChairman",
    "/supply/decisions",
    "/supply/ActivityLog",
    "/suppliers/supply",
    "/suppliers/category",
    "/suppliers/edit",
    "/committees/home",
    "/committees/members",
    "/committees/opening",
    "/committees/techical",
    "/supply/dashboard",
    "/supply/sendAmail",
    "/supply/createTender",
    "/supply/acceptanceLetter",
    "/supply/updateTender",
    "/supply/approval",
    "/committess/sentforApprovals",
    "/committess/bidEvaluationSummaryReportCreate",
    "/committess/bidEvaluationSummaryReportView",
    "/committess/committeeMeetingReportCreate",
    "/committess/committeeMeetingReportView",
    "/committess/openingPriceForm",
    "/committess/openingPriceFormView",
    "/committess/sampleTesting",
    "/committess/sampleTestingView",
    "/committess/sampleTestingDocument",
    "/committess/sampleTestingDocument/PDF",
    "/approval1",
    "/approval1-info",
    "/approval2",
    "/approval2-info",
    "/approval1-user",
    "/purchaseOrder/home",
    "/voucher/create",
    "/purchaseOrder/view",
    "/purchaseOrder/pdf",
    "/purchaseOrder/create",
    "/voucher/view",
    "/voucher/edit",
    "/voucher/view/pdf",
    "/committees/committeeMeetingReportView/pdf",
    "/committees/openingPriceFormView/pdf",
    "/payments",
    "/settings_user",
    "/settings_permission",
    "/user_management",
    "/add-user",
    "/pos/mobile-pos",
    "/pos/pos-items",
    "/Timetable-management",
    "/approve-1-modules",
    "/approve-2-modules",
    "/branch-req",
    "/branch-req-nav",
    "/view-branch-req",
    "/view-check-branch-req",
    "/view-approved-branch-req",
    "/approved-branch-req",
    "/Timetable-management",
    "/allowances",
    "/dedutions",
    "/payment_details/PAY_TAX/Pay%20Tax/Deduction",
    "/payment_details/stamp_duty/Stamp%20Duty/Deduction",
    "/payment_details/salary_advance/Salary%20Advance/Deduction",
    "/payment_details/welfare_members/Welfare%20Members/Deduction",
    "/payment_details/festival_advance/Festival%20Advance/Deduction",
    "/payment_details/bank_loan_01/Bank%20Loan%2001/Deduction",
    "/payment_details/other_deductions_1/Other%20Deductions%201/Deduction",
    "/payment_details/welfare_advance/Welfare%20Advance/Deduction",
    "/payment_details/Union_1/Union%201/Deduction",
    "/payment_details/Union_2/Union%202/Deduction",
    "/payment_details/Union_3/Union%203/Deduction",
    "/payment_details/Union_4/Union%204/Deduction",
    "/payment_details/Union_5/Union%205/Deduction",
    "/payment_details/Buddha_Sangamaya_members/Buddha%20Sangamaya%20Members/Deduction",
    "/payment_details/Sanasa_loan/Sanasa%20Loan/Deduction",
    "/payment_details/Other_deductions_2/Other%20Deductions%202/Deduction",
    "/payment_details/Welfare_Loan/Welfare%20Loan/Deduction",
    "/payment_details/Saving_To_Bank/Saving%20to%20Bank/Deduction",
    "/payment_details/Sanasa_member/Sanasa%20Member/Deduction",
    "/payment_details/Sanasa_Advance/Sanasa%20Advance/Deduction",
    "/payment_details/Donations/Donations/Deduction",
    "/payment_details/other_deductions_3/Other%20Deductions%203/Deduction",
    "/payment_details/Bank_Loan_2/Bank%20Loan%202/Deduction",
    "/payment_details/Distress_Loan/Distress%20Loan/Deduction",
    "/payment_details/Distress_Loan_Interest/Distress%20Loan%20Interest/Deduction",
    "/payment_details/no_pay/No%20Pay/Deduction",
    "/payment_details/Allowance_TP/Allowance%20TP/Allowance",
    "/payment_details/AJT_Allowance/AJT%20Allowance/Allowance",
    "/payment_details/Cost_Of_Allowance/Cost%20Of%20Allowance/Allowance",
    "/payment_details/SLADC_Allowance/SLADC%20Allowance/Allowance",
    "/payment_details/Salary_Arrears/Salary%20Arrears/Allowance",
    "/payment_details/Traveling/Traveling/Allowance",
    "/payment_details/Spe_Allowance/Special%20Allowance/Allowance",
    "/payment_details/Difficult_Allowance/Difficult%20Allowance/Allowance",
    "/payment_details/Act_Allowance/Acting%20Allowance/Allowance",
    "/payment_details/Acct_Allo_Arre/Accounting%20Allowance%20Arrears/Allowance",
    "/payment_details/SPE_ALLOWANCE_02/Special%20Allowance%2002/Allowance",
    "/payment_details/cost_of_allowance_language/Cost%20of%20Allowance%20Language/Allowance",
    "/payment_details/Over_Time/Over%20Time/Allowance",
    "/payment_details/language_allowance/Language%20Allowance/Allowance",
    "/payment_details/Traveling_Fuel/Traveling%20and%20Fuel/Allowance",
    "/monthend-payroll",
    "/incentive-payroll",
    "/production_value",
    "/section-incentive",
    "/slab",
    "/all-deduction",
    "/special-incentive",
    "/all-allowance",
    "/overtime",
    "/leave-amount-management",
    "/incentive",
    "/generated-payroll"
  ];

  const isSidebarRequired = validRoutes.some((route) =>
    new RegExp(`^${route.replace(/:[^/]+/, "[^/]+")}$`).test(location.pathname)
  );
  return isSidebarRequired ? (
    <>
      <div className="SideBar">
        <SideMenu />
      </div>
      <div className="App-content">{children}</div>
    </>
  ) : (
    <div>{children}</div>
  );
};

function App() {
  return (
    <div>
      <Router>
        <SidebarChecker>
          <Routes>
            <Route element={<UserAuth ids={["ROLE-1"]} />}></Route>

            <Route path="/pos/pos" element={<Pos />} />

            <Route path="/pos/pos-approval" element={<Approval_1 />} />

            <Route path="/pos/bill" element={<Bill />} />
            <Route path="/pos/pos-dashboard" element={<PosDashboard />} />
            <Route path="/pos/mobile-pos" element={<MobilePos />} />
            <Route path="/pos/pos-items" element={<PosItems />} />
            <Route path="/inventory/dispose" element={<InventoryDispose />} />
            <Route path="/inventory/disposed" element={<DisposedItems />} />
            <Route
              path="/inventory/dashboard"
              element={<InventoryDashboard />}
            />
            <Route path="/inventory/purchase" element={<InventoryPurchase />} />
            <Route path="/inventory/store" element={<InventoryStore />} />
            <Route
              path="/inventory/store-keeper-release"
              element={<StoreKeeperRelease />}
            />
            <Route
              path="/inventory/request-nav"
              element={<InventoryRequestNav />}
            />
            <Route
              path="/inventory/branch-item-req"
              element={<InventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-approve-branch-req"
              element={<ApproveInventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-approved-branch-req"
              element={<ViewItemApprovedInventoryBranchRequest />}
            />
            <Route
              path="/inventory/view-item-approved-branch-req"
              element={<ViewApprovedInventoryBranchRequest />}
            />
            <Route
              path="/inventory/add-item-manualy"
              element={<AddItemManually />}
            />
            <Route path="/inventory/request" element={<InventoryRequest />} />
            <Route path="/inventory/adjust" element={<InventoryAdjustment />} />
            <Route path="/inventory/return" element={<InventoryReturns />} />
            <Route
              path="/inventory/approval"
              element={<Approval_1_inventory />}
            />

            <Route path="/branches/add" element={<AddBranches />} />
            <Route path="/branches/update" element={<UpdateBranches />} />
            <Route path="/customer/add" element={<AddCustomer />} />
            <Route path="/customer/update" element={<UpdateCustomer />} />
            <Route path="/customer/credit" element={<CreditClearance />} />
            <Route path="/itemcategory/add" element={<AddItemCategory />} />
            <Route
              path="/itemcategory/update"
              element={<UpdateItemCategory />}
            />
            <Route path="/items/create" element={<CreateItem />} />
            <Route path="/items/update" element={<UpdateItem />} />
            <Route path="/stores/add" element={<AddStores />} />
            <Route path="/stores/update" element={<UpdateStores />} />
            <Route path="/supplier/add" element={<AddSupplier />} />
            <Route path="/supplier/update" element={<UpdateSupplier />} />
            <Route path="/users/add" element={<AddUser />} />
            <Route path="/users/update" element={<UpdateUser />} />
            <Route path="/users/role" element={<UserRole />} />
            {/*designation*/}
            <Route path="/designation/add" element={<AddDesignation />} />
            <Route path="/designation/update" element={<UpdateDesignation />} />
            {/*Training Type*/}
            <Route path="/trainingtype/add" element={<AddTraningType />} />
            <Route
              path="/trainingtype/update"
              element={<UpdateTrainingType />}
            />
            {/* Recipe */}
            <Route path="/create-recipe" element={<CreateRecipe />} />
            <Route path="/pending-recipe" element={<PendingRecipes />} />
            <Route path="/created-recipe" element={<CreatedRecipe />} />
            <Route path="/rejected-recipe" element={<RejectedRecipe />} />
            <Route path="/recipe-nav" element={<Recipe_Nav />} />
            {/* Jobs */}
            <Route path="/job-nav" element={<Job_Nav />} />
            <Route path="/created-job" element={<CreatedJob />} />
            <Route path="/create-job" element={<CreateJob />} />
            <Route path="/pending-job" element={<PendingJobs />} />
            <Route path="/rejected-job" element={<RejectedJobs />} />
            {/* Unit */}
            <Route path="/make-units" element={<Unit />} />
            <Route path="/chargeperhour" element={<ChargePerHour />} />
            <Route path="/start-production" element={<StartJob />} />
            <Route path="/create-production" element={<CreateProduction />} />
            {/* Warehouse*/}
            <Route path="/warehouse-nav" element={<Warehouse_Nav />} />
            <Route path="/warehouse" element={<WarehouseMangement />} />
            <Route
              path="/warehouse-Complete"
              element={<WarehouseCompleteTable />}
            />
            <Route path="/warehouse-pending" element={<WarehousePending />} />
            <Route path="/warehouse-Reject" element={<ManageReject />} />
            <Route
              path="/warehouse-Adjustments"
              element={<ManageAdjustments />}
            />
            <Route path="/manage-production" element={<Manage_Production />} />

            {/*HRIS*/}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/emp-onboarding" element={<Onboard />} />
            <Route path="/emp-nav" element={<Emp_Nav />} />
            <Route path="/view-export-table" element={<View_Export_Table />} />
            <Route path="/history-log" element={<History_Log />} />
            <Route
              path="/user-profile/:employee_no"
              element={<User_Profile />}
            />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/in-and-out-regular" element={<Inandout_Regular />} />
            <Route path="/in-and-out-shift" element={<Inandout_Shift />} />
            <Route path="/leaves" element={<Leave />} />
            <Route path="/Timetable-management" element={<Timetable />} />

            <Route
              path="/lateness-permission"
              element={<Lateness_Permission />}
            />
            <Route path="/leave-doc" element={<Leave_Doc />} />
            <Route path="/over-time" element={<OT />} />
            <Route path="/payments-menu" element={<Payments_Menu />} />
            <Route path="/allowances" element={<Allowances />} />
            <Route path="/dedutions" element={<Deductions />} />
            <Route path="/allpayroll" element={<AllPayroll />} />
            <Route path="/monthend-payroll" element={<MonthendPayroll />} />
            <Route path="/all-deduction" element={<AllDeductions />} />
            <Route path="/all-allowance" element={<All_allowance />} />
            <Route
              path="/payment_details/:value/:name/:type"
              element={<AllowancesandDeductions />}
            />
            <Route path="/training" element={<Training />} />
            <Route path="/payments" element={<Allpayments />} />
            <Route path="/overtime" element={<Overtime />} />
            <Route
              path="/completed-training"
              element={<Completed_Training />}
            />
            <Route path="/upcoming-training" element={<Upcoming_Trainings />} />
            <Route path="/examinations" element={<Examinations_Card />} />
            <Route path="/reports-analytics" element={<Reports_Analytics />} />
            <Route path="/performance-nav" element={<Performance_Nav />} />
            <Route
              path="/completed_examinations"
              element={<Completed_Examinations />}
            />
            <Route
              path="/upcoming-examinations"
              element={<Upcoming_Examinations />}
            />
            <Route path="/production_value" element={<ProductionValue />} />
            <Route path="/salary-increments" element={<Salary_Increments />} />
            <Route path="/leave-management" element={<Leave_Management />} />
            <Route path="/leave-info" element={<Leave_Info />} />
            <Route path="/loan-nav" element={<Loan_Nav />} />
            <Route path="/view-loan-info" element={<View_Loan_Info />} />
            <Route path="/review-guarantors" element={<Review_Guarantors />} />
            <Route path="/loan-eligible" element={<Loan_Elibility />} />
            <Route path="/leave-reports" element={<Leave_Reports />} />
            <Route path="/approval1" element={<Approval1 />} />
            <Route path="/approval1-user" element={<Approval1user />} />
            <Route path="/approval2-user" element={<Approval2user />} />
            <Route path="/approval2" element={<Approval2 />} />
            <Route path="/approval1-info" element={<Approval1_Info />} />
            <Route path="/approval2-info" element={<Approval2_Info />} />
            <Route path="/section-incentive" element={<SectionIncentive />} />
            <Route path="/slab" element={<SLAB />} />
            <Route path="/special-incentive" element={<SpecialIncentive />} />
            <Route path="/incentive" element={<Incentive />} />
            <Route path="/generated-payroll" element={<GeneratedPayroll />} />
            <Route
              path="/leave-amount-management"
              // eslint-disable-next-line react/jsx-pascal-case
              element={<Leave_amount />}
            />

            {/*Supply Chain*/}
            <Route path="/purchase/request" element={<Per_Request />} />
            <Route
              path="/purchase/requestForm"
              element={<Per_Request_Form />}
            />
            <Route
              path="/purchase/viewPurchaseRequest"
              element={<PurchaseRequestView />}
            />
            <Route
              path="/purchase/viewPurchaseRequestChairman"
              element={<PurchaseRequestViewChairman />}
            />
            <Route path="/supply/decisions" element={<Decisions_home />} />
            <Route path="/supply/ActivityLog" element={<ActivityLog />} />
            <Route path="/suppliers/supply" element={<SupplySuppliers />} />
            <Route path="/suppliers/category" element={<Supply_Category />} />
            <Route path="/suppliers/edit" element={<Supply_Edit />} />
            <Route path="/committees/home" element={<Committees_Home />} />
            <Route path="/committees/members" element={<Appoint_Members />} />

            <Route
              path="/committees/opening"
              element={<Tender_Opening_Committees_Home />}
            />
            <Route
              path="/committees/techical"
              element={<Technical_Members />}
            />
            <Route path="/supply/dashboard" element={<Dashboard_Home />} />
            <Route path="/supply/sendAmail" element={<Send_A_Mail />} />
            <Route path="/supply/createTender" element={<Create_A_Tender />} />

            <Route
              path="/supply/acceptanceLetter"
              element={<Acceptance_Letter />}
            />
            <Route
              path="/supply/updateTender"
              element={<Update_Acceptance_Letter />}
            />
            <Route path="/supply/approval" element={<Approval_1_supply />} />

            <Route
              path="/committess/sentforApprovals"
              element={<Sent_For_Approvals />}
            />
            <Route
              path="/committess/bidEvaluationSummaryReportCreate"
              element={<Bid_Evaluation_Summary_Report />}
            />
            <Route
              path="/committess/bidEvaluationSummaryReportView"
              element={<Bid_Evaluation_Summary_Report_view />}
            />
            <Route
              path="/committess/committeeMeetingReportCreate"
              element={<Create_Commitee_Meeting_Report />}
            />
            <Route
              path="/committess/committeeMeetingReportView"
              element={<View_Commitee_Meeting_Report />}
            />
            <Route
              path="/committess/openingPriceForm"
              element={<Opening_Price_Report_Form />}
            />
            <Route
              path="/committess/openingPriceFormView"
              element={<Opening_Price_Report_Form_View />}
            />
            <Route
              path="/committess/sampleTesting"
              element={<Sample_Testing />}
            />
            <Route
              path="/committess/sampleTestingView"
              element={<Sample_Testing_View />}
            />
            <Route
              path="/committess/sampleTestingDocument"
              element={<Sample_Testing_Document_View />}
            />
            <Route
              path="/committess/sampleTestingDocument/PDF"
              element={<Sample_Commitee_Report />}
            />
            <Route path="/purchaseOrder/home" element={<Purchase_Order />} />
            <Route
              path="/purchaseOrder/create"
              element={<Purchase_Order_Create />}
            />
            <Route
              path="/purchaseOrder/view"
              element={<Purchase_Order_View />}
            />
            <Route path="/purchaseOrder/pdf" element={<Purchase_Order_PDF />} />
            <Route
              path="/voucher/create"
              element={<Purchase_Voucher_Create />}
            />
            <Route path="/voucher/edit" element={<Purchase_Voucher_Edit />} />
            <Route path="/voucher/view" element={<Purchase_Voucher_View />} />
            <Route
              path="/voucher/view/pdf"
              element={<TecBidEvaluationReport />}
            />
            <Route
              path="/committees/committeeMeetingReportView/pdf"
              element={<Tec_Meeting_Report />}
            />
            <Route
              path="/committees/openingPriceFormView/pdf"
              element={<OpeningPricing />}
            />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Error />} />

            {/* settings */}
            <Route path="/settings_user" element={<Settings_User />} />
            <Route
              path="/settings_permission"
              element={<Setting_Permission />}
            />
            <Route path="/user_management" element={<User_Management />} />
            <Route path="/add-user" element={<Add_User />} />

            {/* approvals */}

            <Route path="/approve-1-modules" element={<Approval_1_Modules />} />
            <Route path="/approve-2-modules" element={<Approve_2_Modules />} />

            {/* Branch Req */}
            <Route path="/branch-req" element={<BranchRequest />} />
            <Route path="/view-branch-req" element={<ViewBranchRequest />} />
            <Route path="/branch-req-nav" element={<BranchRequestNav />} />
            <Route
              path="/view-check-branch-req"
              element={<CheckBranchRequest />}
            />
            <Route
              path="/view-approved-branch-req"
              element={<VewApprovedBranchRequest />}
            />
            <Route
              path="/approved-branch-req"
              element={<ApprovedBranchRequest />}
            />
          </Routes>
        </SidebarChecker>
      </Router>
    </div>
  );
}

export default App;
