import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";

const Tender_Document_View_Dialog = ({ onClose, tenderFile }) => {
  const navigate = useNavigate();
  const [fileContent, setFileContent] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const baseUrl = `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/file/`;
  useEffect(() => {
    if (tenderFile) {
      fetchTenderFile(tenderFile);
    }
  }, [tenderFile]);

  const fetchTenderFile = async (fileUrl) => {
    try {
      const response = await fetch(baseUrl + fileUrl);
      if (response.ok) {
        const blob = await response.blob();
        setFileContent(URL.createObjectURL(blob));
      } else {
        console.error("Failed to fetch tender file");
      }
    } catch (error) {
      console.error("Error fetching tender file:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[80%] h-[85%] min-h-[650px]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <p className=" text-[#3A3F52] text-[41px] items-center mt-2">
          View Tender Document
        </p>
        <div className="bg-[#C4C4C4] h-[80%]">
          {fileContent && (
            <img
              src={fileContent}
              alt="Tender Document"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
        </div>
        <button
          className="bg-[#5B6D49] text-white text-[16px] items-center mt-6 w-20 rounded-lg h-8"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Tender_Document_View_Dialog;
