 

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import TableMember from "./membersTabel";
import DialogBox from "./Edit&Update/dialogBox";
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission";

const Appoint_Members = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const handleNavigatemember = () => {
    navigate("/suppliers/category");
  };

  const [selectedOption, setSelectedOption] = useState(""); // State to manage selected option
  const [tenderId, settenderId] = useState("");
  const [tenders, setTenders] = useState([]);
  const [tenderName, settenderName] = useState("");
  const [contractNo, setcontractNo] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentDate = new Date().toLocaleDateString("en-GB");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTender, setSelectedTender] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const handleOptionClick = (result) => {
    setSearchTerm(result.tender_id);
    setSelectedTender(result);
    setIsDropdownOpen(false);
    settenderId(result.tender_id);
    settenderName(result.tender_name);
  };

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/search/tender/${query}`
        );
        setTenders(response.data);

        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  const handleReset = () => {
    settenderId("");
    settenderName("");
    setcontractNo("");
    setMemberInputs([
      {
        id: 1,
        employeeId: "",
        employeeName: "",
        appointingDate: new Date(),
        role: "",
      },
    ]);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  // Handler function to update selected option
  const handleSelectOption = (event) => {
    setSelectedOption(event.target.value);
  };

  const [memberInputs, setMemberInputs] = useState([
    {
      id: 1,
      employeeId: "",
      employeeName: "",
      appointingDate: new Date(),
      role: "",
    },
  ]);

  const handleInputChange = (id, field, value) => {
    setMemberInputs(
      memberInputs.map((member) => {
        if (member.id === id) {
          return { ...member, [field]: value };
        }
        return member;
      })
    );
  };

  const handleAddInput = () => {
    setMemberInputs([...memberInputs, { id: memberInputs.length + 1 }]);
  };

  const handleRemoveInput = (idToRemove) => {
    setMemberInputs(memberInputs.filter((input) => input.id !== idToRemove));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender/search/tender/${tenderId}`
        );
        // Handle successful response
        if (response.status === 200) {
          const value = response.data[0]; // Access the first element of the array
          settenderId(value.tender_id);
          settenderName(value.contract_id);
          settenderName(value.tender_name);
          setcontractNo(value.contract_id);
          // Parse deadline_date into Date object

          console.log("Tender Name:", tenderName);
        } else {
          // Handle error response
          console.error("Failed to fetch data");
        }
      } catch (error) {
        // Handle Axios request error
        console.error("Error fetching data:", error);
      }
    };

    // Call fetchData function
    fetchData();
  }, [tenderId]); // Run effect whenever tenderId changes

  const postData = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/committee/members/add`,
        {
          tender_id: tenderId,
          committee_members: memberInputs.map((member) => ({
            committee_user_id: member.employeeId,
            committee_appointing_date:
              member.appointingDate.toLocaleDateString("en-GB"),
            committee_role: member.role,
          })),
          committee_edit_date: currentDate,
          committee_edit_user_id: "EMP-00003",
          committee_status: "ACTIVE",
        }
      );
      console.log("Response:", response.data);
      handleReset();
      alert("Tender successfully added");
      // You can handle the response here (e.g., show a success message)
    } catch (error) {
      console.error("Error:", error);
      alert("Fail to add Tender");
      // You can handle errors here (e.g., show an error message)
    }
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Appoint Committess Members
      </p>
      {hasPermission(2640) && (
        <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[400px] absolute top-6 right-6">
          <div className="min-h-[110px] min-w-[150px]">
            <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
              Edit & Update
            </h2>
            <h3 className="text-[28px] text-[#797C80] font-semibold mb-1">
              Members
            </h3>
          </div>
          <div className="flex items-center relative">
            <button
              onClick={() => openDialog(tenderId)}
              className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
            >
              Click Here
            </button>

            <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
          </div>
          {showDialog && (
            <DialogBox onClose={closeDialog} tenderId={tenderId} />
          )}
        </div>
      )}
      <div className="mt-6 ml-10">
        <select
          id="dropdown"
          value={selectedOption}
          onChange={handleSelectOption}
          className="border border-gray-300 rounded-[22px] px-[12%] py-3 text-[#BBC5DC] font-bold text-[18px] bg-[#F3F8FF]"
        >
          <option value="">Select Committee Type</option>
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </select>
        <button className="btn btn-primary text-[#BBC5DC] text-[20px]">
          Select
        </button>
      </div>
      <div className="mt-6">
        <label className="mt-[20px] ml-10 text-[22px] text-[#9CAC8B] font-family-[Poppins] font-bold">
          *This Committee should have minimum of 4 members
        </label>
      </div>
      <div className="mt-2">
        <div className="bg-[#F3F8FF] rounded-[46px] p-4 mt-6 ml-10 min-w-[80%] shadow-md justify-between card absolute grid grid-cols-2  gap-6 text-[20px] font-bold ">
          <div className="ml-4 flex">
            <label>Tender ID:</label>
            {/* Serach Box */}
            <form className="form">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className=" ml-8 input rounded-full w-[300px] h-10 px-8 py-3 border border-gray-300  focus:outline-none focus:border-blue-500 placeholder-gray-400"
              />
              <button
                type="button"
                className="btn btn-primary text-[#797C80] hover:bg-gray-100"
                onClick={handleReset}
              >
                RESET
              </button>
              <div className="mt-4 relative">
                {isDropdownOpen && (
                  <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                    {tenders.length > 0 ? (
                      tenders.map((result) => (
                        <li
                          key={result._id}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          value={selectedTender}
                          onClick={() => handleOptionClick(result)}
                        >
                          {result.tender_id}
                        </li>
                      ))
                    ) : (
                      <li className="p-2 text-gray-500">No results found</li>
                    )}
                  </ul>
                )}
              </div>
            </form>
          </div>
          <div className="ml-4">
            <label>Tender Name:</label>
            <input
              className="input ml-8 border border-gray-300 rounded-[22px] text-[#656565] text-center"
              placeholder="Enter Tender Name"
              disabled
              value={tenderName}
              onChange={(e) => settenderName(e.target.value)}
            ></input>
          </div>
          <div className="ml-4">
            <label>Contract ID:</label>
            <input
              className="input ml-8 border border-gray-300 rounded-[22px] text-[#656565] text-center"
              placeholder="Contact Number"
              disabled
              value={contractNo}
              onChange={(e) => setcontractNo(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="mt-[12%]">
        <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
          Add Members
        </p>
      </div>
      <div className="ml-10">
        {memberInputs.map((input) => (
          <div
            key={input.id}
            className="bg-[#F3F8FF] rounded-[46px] p-4 mt-6 shadow-md grid grid-cols-4 gap-6 text-[20px] font-bold"
          >
            <div>
              <label>Employee ID:</label>
            </div>
            <div>
              <input
                className="input border border-gray-300 rounded-[22px] text-[#656565] text-center"
                placeholder="Enter Employee ID"
                value={input.employeeId}
                onChange={(e) =>
                  handleInputChange(input.id, "employeeId", e.target.value)
                }
              ></input>
            </div>
            <div>
              <label>Employee Name:</label>
            </div>
            <div>
              <input
                className="input border border-gray-300 rounded-[22px] text-[#656565] text-center"
                placeholder="Enter Employee Name"
                value={input.employeeName}
                onChange={(e) =>
                  handleInputChange(input.id, "employeeName", e.target.value)
                }
              ></input>
            </div>
            <div>
              <label>Appointing Date:</label>
            </div>
            <div>
              <DatePicker
                className="input border border-gray-300 rounded-[22px] text-[#656565] text-center"
                placeholderText="Enter Appointing Date"
                selected={input.appointingDate}
                onChange={(date) =>
                  handleInputChange(input.id, "appointingDate", date)
                }
              />
            </div>
            <div>
              <label>Role:</label>
            </div>
            <div>
              {" "}
              <select
                className="input border border-gray-300 rounded-[22px] text-[#656565] text-center"
                value={input.role}
                onChange={(e) =>
                  handleInputChange(input.id, "role", e.target.value)
                }
              >
                <option value="">Select Role</option>
                <option value="Chairman">Chairman</option>
                <option value="Member">Member</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {hasPermission(2641) && (
              <button
                className="bg-red-500 text-white font-bold py-2 px-4 rounded-[22px] mt-6"
                onClick={() => handleRemoveInput(input.id)}
              >
                Remove
              </button>
            )}
          </div>
        ))}
        {hasPermission(2642) && (
          <button
            className="bg-primary w-[5%] mt-6 h-12 text-white font-bold  rounded-[22px] ml-[90%]"
            onClick={handleAddInput}
          >
            Add
          </button>
        )}
      </div>
      <div className="mt-[2%]">
        <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
          View Members
        </p>
      </div>
      <div className="flex flex-between font-poppins font-bold  ml-10 ">
        <div>
          {" "}
          <form class="form relative">
            <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
              <svg
                class="w-5 h-5 text-gray-700"
                aria-labelledby="search"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="16"
                width="17"
              >
                <path
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  stroke-width="1.333"
                  stroke="currentColor"
                  d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              required=""
              placeholder="Enter Tender ID"
              class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
            />
            <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
              RESET
            </button>
          </form>
        </div>
        <div className="bg-[#F3F8FF] rounded-[46px] p-4 min-w-[40%] right-20 shadow-md card absolute grid grid-cols-2 gird-row-2 gap-6 text-[20px] font-bold ">
          <label>Tender Name:</label>
          <input
            className="input ml-[17%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            placeholder="ELANKAG12"
            disabled
            value={tenderName}
            onChange={(e) => settenderName(e.target.value)}
          ></input>

          <label>Contact ID:</label>
          <input
            className="input ml-[8%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            placeholder="7644"
            disabled
            value={contractNo}
            onChange={(e) => setcontractNo(e.target.value)}
          ></input>
        </div>
      </div>
      {hasPermission(2643) && (
        <button
          className="bg-primary w-[5%] mt-20 h-12 text-white font-bold  rounded-[22px] ml-[90%]"
          onClick={postData}
        >
          Submit
        </button>
      )}
      <div className="mt-20">
        <TableMember />
      </div>
    </div>
  );
};

export default Appoint_Members;
