 

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import New from "../../../../../Assets/icons/New.png";
import Send from "../../../../../Assets/icons/Side Nav icons.png";
import Trash from "../../../../../Assets/icons/Trash.png";

import TableMail from "./mailTabel";
import DialogNewMail from "./newMessageDialog";
import DialogBoxUploadDocuments from "./uploadBidderDocmentsDialog";
import DialogBoxViewDocuments from "./viewBidderDocumentsDialog";
import usePermissions from "../../../../../components/permissions/permission";
const Tender_Opening_Committees_Home = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const handleOpeningPriceForm = () => {
    navigate("/committess/openingPriceForm");
  };

  const handleOpeningPriceFormView = () => {
    navigate("/committess/openingPriceFormView");
  };

  const [showDialog, setShowDialog] = useState(false);
  const [documentShowDialog, setDocumentShowDialog] = useState(false);
  const [viewbidDocumentDialog, setviwbidDocumentDialog] = useState(false);
  // /committess/openingPriceForm
  const openDocDialog = () => {
    setDocumentShowDialog(true);
  };

  const closeDocDialog = () => {
    setDocumentShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openViewBidDialog = () => {
    setviwbidDocumentDialog(true);
  };

  const closeViewBidDialog = () => {
    setviwbidDocumentDialog(false);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-10 mt-6 font-bold text-[#071C50] ">
        Tender Opening Committee
      </p>
      <div className="bg-white rounded-[60px] h-[70%] w-[90%] ml-10 mt-6">
        {hasPermission(2670) && (
          <div className="h-[100%] flex justify-between">
            <div className=" w-[15%] h-[100%] border-r-2 border-[#9CAC8B]">
              <p className=" text-[20px] font-bold mt-12 ml-4 text-[#071C50]">
                Colling for Prices
              </p>

              <button className="shadow-lg border-2 border-gray-400 w-[95%] h-12 rounded-lg mt-4 ml-2 flex items-center">
                <img
                  src={New}
                  alt="Image"
                  className="ml-1 mr-1"
                  onClick={openDialog}
                />{" "}
                Compose and Email
              </button>

              {showDialog && <DialogNewMail onClose={closeDialog} />}
              <div>
                <div className="flex ml-6 mt-8">
                  <img src={Send} alt="Send" />
                  <button className="ml-2">Send</button>
                </div>
                <div className="flex ml-6 mt-8">
                  <img src={Trash} alt="Trash" />
                  <button className="ml-2">Trash</button>
                </div>
              </div>
            </div>
            <div className=" w-[85%] h-[100%] right-0 ">
              <TableMail />
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[2%] ml-10">
        {hasPermission(2671) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card mb-8">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Bidder
              </h2>

              <h2 className="text-[25px] text-[#797C80] font-semibold mb-1">
                Documents
              </h2>
            </div>
            <div className="flex items-center relative">
              {hasPermission(10061) && (
                <button
                  className="bg-primary w-[35%] mt-2 mr-auto text-white font-bold py-1 px-4 rounded-[22px]"
                  onClick={openDocDialog}
                >
                  Create
                </button>
              )}
              {documentShowDialog && (
                <DialogBoxUploadDocuments onClose={closeDocDialog} />
              )}

              {hasPermission(10060) && (
                <button
                  className="bg-[#656565] w-[35%] mt-2 mr-auto text-white font-bold py-1 px-4 rounded-[22px]"
                  onClick={openViewBidDialog}
                >
                  View
                </button>
              )}
              {viewbidDocumentDialog && (
                <DialogBoxViewDocuments onClose={closeViewBidDialog} />
              )}

              <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
            </div>
          </div>
        )}
        {/* Card 2 */}
        {hasPermission(2672) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card mb-8">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold">
                Opening Price
              </h2>

              <h2 className="text-[25px] text-[#797C80] font-semibold mb-1">
                From
              </h2>
            </div>
            <div className="flex items-center relative">
              {hasPermission(10071) && (
                <button
                  className="bg-primary w-[35%] mt-2 mr-auto text-white font-bold py-1 px-4 rounded-[22px]"
                  onClick={handleOpeningPriceForm}
                >
                  Create
                </button>
              )}

              {hasPermission(10070) && (
                <button
                  className="bg-[#656565] w-[35%] mt-2 mr-auto text-white font-bold py-1 px-4 rounded-[22px]"
                  onClick={handleOpeningPriceFormView}
                >
                  View
                </button>
              )}
              <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tender_Opening_Committees_Home;
