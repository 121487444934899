/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AddUser = ({ onClose }) => {
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/user/getRoles`)
      .then((response) => response.json())
      .then((data) => setRoles(data))
      .catch((error) => console.error("Error fetching roles:", error));
  }, []);

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/employees/getemployeebasicdetails`)
      .then((response) => response.json())
      .then((data) => setEmployees(data))
      .catch((error) => console.error("Error fetching empoyees:", error));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBody = {
      employee_no: selectedEmployee,
      username: username,
      password: password,
      user_role: parseInt(selectedRole, 10),
      employee_status: "Active",
    };

    console.log("Request Body:", requestBody);

    if (requestBody) {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/user/createUser`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Response Data:", data);
          window.alert("User Added Successfully");
          handlecancel();
        } else {
          const errorData = await response.json();
          console.error("Failed to save data", errorData);
          window.alert(errorData.message || "Failed to save data");
        }
      } catch (error) {
        console.error("Error:", error);
        window.alert("An unexpected error occurred");
      }
    }
  };

  const handlecancel = async () => {
    setUsername("");
    setPassword("");
    setSelectedRole("");
    setSelectedEmployee("");
    onClose();
  };

  return (
    <div className="bg-white p-2 rounded-6">
      <div className="mb-8">
        <p className="text-[26px] font-semibold">Create</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 grid-flow-row gap-x-4 gap-y-6">
          <div>
            <div>
              <label className="font-semibold">Employee ID*</label>
            </div>
            <div>
              <select
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                <option value="" disabled>
                  Select Employee ID
                </option>
                {employees.map((employee) => (
                  <option
                    key={employee.employee_no}
                    value={employee.employee_no}
                  >
                    {employee.employee_no} - {employee.employee_name_initial}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mr-10">
            <div>
              <label className="font-semibold">User Name*</label>
            </div>
            <div>
              <input
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                placeholder="User Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div>
              <label className="font-semibold">Password*</label>
            </div>
            <div>
              <input
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div>
              <label className="font-semibold">Role*</label>
            </div>
            <div>
              <select
                className="rounded-[20px] h-[40px] w-[300px] p-2 border border-black"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="" disabled>
                  Select Role
                </option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.role_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="bg-primary p-3 rounded-[15px] text-white font-semibold mt-6"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <button
        className="mt-4 bg-red-500 text-white p-2 rounded-[15px]"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
};

export default AddUser;
