/** @format */

import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import { useReactToPrint } from "react-to-print";
import Printer from "../../Assets/icons/print 1.png";
import "./approvedItemReqFromBranch.css";
import axios from "axios";
import IdGenerate from "../../utils/id_generate";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function ApprovedItemReqFromBranch() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [userName, setUserName] = useState("");
  const [branch, setBranch] = useState("Navinna");
  const [dateFrom, setDateFrom] = useState("");
  const location = useLocation();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState(selectedRecord.items || []);
  const [releasedItem, setReleasedItem] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const Data = [...tableData]; // Create a copy of tableData
  Data.push({
    inventory_request_id: selectedRecord.inventory_request_id,
  });
  const [stores, setStores] = useState([]);

  useEffect(() => {
    const fetchTotalCostOfReleasedItems = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/inventoryRequest/getTotalCostOfReleasedRequest/${selectedRecord.inventory_request_id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setReleasedItem(data);
        console.log("released items:", releasedItem);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    if (selectedRecord.inventory_request_id) {
      fetchTotalCostOfReleasedItems();
      console.log("Inventory request ID:", selectedRecord.inventory_request_id);
    }
  }, [selectedRecord.inventory_request_id]);
  const SubmitHandler = async () => {
    console.log(tableData);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/add`,
        tableData
      );
      console.log(res.data);
      if (res.status === 200 || res.status === 201) {
        alert("Inventory Request Successfully");
        handlePrint();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("Inventory Already Exist");
      } else if (error.response.status === 400) {
        alert("Inventory Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Inventory Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Approved Branch Item Request</p>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Request Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Request Order Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">RO Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_date}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Request Order Items</p>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Requested Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Release Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Price</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Units</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Total</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {releasedItem.length > 0 && releasedItem[0].batches.length > 0
              ? releasedItem[0].batches.map((batch, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3"
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {batch.item_id}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {batch.added_qty}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {batch.batch_price}
                    </p>
                    <p className="w-[170px] p1 text-[#656565] text-center">
                      <textarea
                        className="w-[170px] border border-[#656565] my-2 rounded-2xl"
                        value={batch.inventory_release_item_description || ""}
                        onChange={(e) => {
                          const updatedReleasedItem = [...releasedItem];
                          updatedReleasedItem[0].batches[
                            index
                          ].inventory_release_item_description = e.target.value;
                          setReleasedItem(updatedReleasedItem);
                        }}
                      />
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 pt-3 ">
        <div className="w-[585px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center justify-between gap-10">
            <p className="p1 text-[#656565] w-[500px]">Branch Owner Name:</p>
            <div className="relative w-[213px]">
              <p className="p1 text-[#656565] w-70 border-2 border-[#00000059] h-9 rounded-[100px] px-7 bg-white overflow-hidden truncate hover:overflow-visible group-hover:whitespace-nowrap"></p>
            </div>
          </div>
        </div>
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Sub Total</p>
              <p className="p1 text-[#656565]">0.00</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Discount</p>
              <p className="p1 text-[#656565]"> 0</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Net Total</p>
              <p className="p1 text-[#656565]">0.00</p>
            </div>
          </div>
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Balance</p>
              <p className="p1 text-[#656565]">0.00</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print
          </p>
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
