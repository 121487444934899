 

import React, { useState, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import usePermissions from "../../../components/permissions/permission";

function Unit() {
  const { hasPermission } = usePermissions();
  const [showPopup, setShowPopup] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [shortName, setShortName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [baseUnits, setBaseUnits] = useState([]); // State to store base units
  const [selectedBaseUnitId, setSelectedBaseUnitId] = useState(""); // State to store selected base unit ID
  const [operatorValue, setOperatorValue] = useState(""); // State to store operator value
  const [currentPage, setCurrentPage] = useState(1);
  const [unitToDelete, setUnitToDelete] = useState(null); // State to store the unit to delete
  const itemsPerPage = 5; // Change the number of items per page as needed
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchData();
    fetchBaseUnits();
  }, []);

  const fetchData = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/getSecondary`)
      .then((response) => response.json())
      .then((data) => {
        const mappedData = data.map(
          ({
            unit_name,
            unit_symbol,
            unit_base_unit_id,
            unit_operator_value,
          }) => ({
            unitName: unit_name,
            shortName: unit_symbol,
            unitId: unit_base_unit_id, // Assuming _id is the unique identifier for units
            Operation: unit_operator_value,
          })
        );
        setTableData(mappedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchBaseUnits = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/get`)
      .then((response) => response.json())
      .then((data) => {
        setBaseUnits(data);
      })
      .catch((error) => console.error("Error fetching base units:", error));
  };

  useEffect(() => {
    if (tableData.length && baseUnits.length) {
      const updatedTableData = tableData.map((item) => {
        const baseUnit = baseUnits.find((unit) => unit._id === item.unitId);
        return {
          ...item,
          baseUnitSymbol: baseUnit ? baseUnit.unit_symbol : "",
        };
      });
      setTableData(updatedTableData);
    }
  }, [tableData, baseUnits]);

  const handleCreateClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const submitForm = () => {
    const formData = {
      unit_name: unitName,
      unit_symbol: shortName,
      unit_create_by: "EMP-005",
      unit_base_unit_id: selectedBaseUnitId,
      unit_operator_value: operatorValue,
    };

    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/unit/addSecondary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Unit added successfully");
          setUnitName("");
          setShortName("");
          setSelectedBaseUnitId("");
          setOperatorValue("");
          setShowPopup(false);
          fetchData();
        } else {
          console.error("Failed to add unit");
          alert("Failed to add unit");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error occurred. Please try again.");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  const handleDelete = (index, unitId) => {
    setUnitToDelete({ index, unitId });
  };

  const confirmDelete = () => {
    if (unitToDelete) {
      const { index, unitId } = unitToDelete;
      fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/unit/delete?unit_id=${unitId}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => {
          if (response.ok) {
            console.log("Unit deleted successfully");
            const updatedData = [...tableData];
            updatedData.splice(index, 1);
            setTableData(updatedData);
          } else {
            console.error("Failed to delete unit");
            alert("Failed to delete unit");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Error occurred. Please try again.");
        })
        .finally(() => {
          setUnitToDelete(null); // Reset unitToDelete state after delete operation
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    const maxPageNumbers = 5;
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = tableData.slice(startIndex, endIndex);

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Make Units
      </p>
      {hasPermission(1400) && (
        <button
          onClick={handleCreateClick}
          className="bg-[#BFD8AF] py-2 px-4 rounded ml-5 mt-5"
        >
          Create
        </button>
      )}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg relative">
            <h2 className="text-lg font-bold mb-4">Create Unit</h2>
            <button
              onClick={handleClosePopup}
              className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700"
            >
              Close
            </button>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Enter Unit Name
                </label>
                <input
                  type="text"
                  value={unitName}
                  onChange={(e) => setUnitName(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Enter Short Name
                </label>
                <input
                  type="text"
                  value={shortName}
                  onChange={(e) => setShortName(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Select Base Unit
                </label>
                <select
                  value={selectedBaseUnitId}
                  onChange={(e) => setSelectedBaseUnitId(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                  required
                >
                  <option value="" disabled>
                    Select a base unit
                  </option>
                  {baseUnits.map((unit) => (
                    <option key={unit._id} value={unit._id}>
                      {unit.unit_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Enter Operator Value
                </label>
                <input
                  type="text"
                  value={operatorValue}
                  onChange={(e) => setOperatorValue(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                  required
                />
              </div>
              <div className="flex justify-center">
                <button type="submit" className="bg-[#E1F0DA] rounded-md p-3">
                  Create Unit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {unitToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg relative">
            <h2 className="text-xl font-bold mb-4 text-center text-red-700 ">
              Delete Unit
            </h2>
            <p className="mb-3">Are you sure, you want to delete this unit?</p>
            <div className="flex justify-center">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
              >
                Confirm
              </button>
              <button
                onClick={() => setUnitToDelete(null)}
                className="bg-gray-300 text-black px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-5 ml-5">
        <table className="border-collapse border border-gray-200">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-[220px] text-center">
                Unit Name
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[220px] text-center">
                Short Name
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[40px] text-center">
                Actions
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[40px] text-center">
                Base unit
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={index} className="border border-gray-200">
                <td className="px-4 py-2 text-center">{item.unitName}</td>
                <td className="px-4 py-2 text-center">{item.shortName}</td>
                <td className="px-4 py-2 text-center">{item.Operation}</td>
                <td className="px-4 py-2 text-center">{item.baseUnitSymbol}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200"
          disabled={currentPage === Math.ceil(tableData.length / itemsPerPage)}
        >
          {">"}
        </button>
      </div>
    </div>
  );
}

export default Unit;
