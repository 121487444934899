 

import React, { useState, useEffect } from "react";
import Add_Examination from "../completed/add_examination";
import Edit_Upcoming_Exams from "./edit_upcoming_exams";
import fileIcon from "../../../../../Assets/icons/png.png";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../../../components/permissions/permission";

const Upcoming_Examinations = () => {
  const { hasPermission } = usePermissions();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [examData, setExamData] = useState([]);
  const [fetchKey, setFetchKey] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteIndexEmployee, setDeleteIndexEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedExamData, setSelectedExamData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const toggleEditPopup = () => {
    setIsEditPopupOpen(!isEditPopupOpen);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleEditClick = (exam) => {
    setSelectedExamData(exam);
    toggleEditPopup();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/performance/examination/getexaminations`
        );
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };

    fetchData();
  }, [fetchKey]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredExams = examData.filter(
    (exam) =>
      exam.examination_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      exam.examination_purpose.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDownload = (filePath) => {
    console.log("Attempting to download file at path:", filePath);
    if (!filePath) {
      alert("No file available for download.");
      return;
    }
    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${encodeURIComponent(
      filePath
    )}`;
    window.location.href = downloadUrl;
  };

  const handleDeleteEmployee = (index) => {
    console.log("Deleting exam at index:", index);
    setDeleteIndexEmployee(null);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredExams.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-headerColor font-sans ml-6 mt-6 text-[30px] font-semibold">
        Upcoming Examinations
      </p>
      <div className="flex justify-between items-center">
        <div className="mt-6 ml-6">
          <div>
            <div className="flex flex-col font-poppins font-bold ml-1 mt-4">
              <div className="relative font-sans group hidden sm:block">
                <input
                  type="text"
                  placeholder="Search by Name or Purpose"
                  className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        </div>
        {hasPermission(10012) && (
          <div className="bg-white rounded-[22px] h-[189px] w-[430px] mr-[5%]">
            <div className="text-[#797C80] font-bold ml-4 mt-4 text-[29px]">
              <p>Create an </p>
              <p>Upcoming Examination</p>
            </div>

            <div className="flex items-center justify-between">
              <button
                onClick={togglePopup}
                className="bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white font-semibold"
              >
                Click Here
              </button>
              {isPopupOpen && (
                // eslint-disable-next-line react/jsx-pascal-case
                <Add_Examination
                  onClose={() => {
                    togglePopup();
                    setFetchKey((prevKey) => prevKey + 1);
                  }}
                  fetchKey={fetchKey}
                />
              )}

              <div className="mr-5">
                <div className="bg-primary rounded-[32px] h-[37px] w-[13px]"></div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
          <table className="table-auto border-none w-[95%]">
            <thead>
              <tr className="bg-white">
                <th className="text-primary px-4 py-2">Examination Name</th>
                <th className="text-primary px-4 py-2">Date</th>
                <th className="text-primary px-4 py-2">Time</th>
                <th className="text-primary px-4 py-2">Purpose</th>
                <th className="text-primary px-4 py-2">Candidates</th>

                <th className="text-primary px-4 py-2">
                  Progress of Candidates
                </th>
                <th className="text-primary px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((exam, index) => (
                <tr key={exam.id}>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_name}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_date}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_time}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_purpose}
                  </td>
                  <td className="bg-white px-4 py-2">
                    {exam.examination_candidates}
                  </td>

                  <td
                    className="bg-white cursor-pointer flex items-center w-[100%] h-[70px] text-[12px] gap-2"
                    onClick={() =>
                      handleDownload(exam.examination_progress_file_path)
                    }
                  >
                    {exam.examination_progress_file_path ? (
                      <>
                        <img
                          src={fileIcon}
                          alt="File Icon"
                          className="w-7 h-7 cursor-pointer"
                        />
                        <div>{exam.examination_progress_file_name}</div>
                      </>
                    ) : (
                      <span>No file available</span>
                    )}
                  </td>
                  <td className="bg-white px-4 py-2">
                    <div className="flex justify-between gap-4">
                      {hasPermission(10013) && (
                        <div>
                          <button
                            onClick={() => handleEditClick(exam)}
                            className="bg-[#ADB4BE] rounded-[28px] p-2 w-[95px] text-white"
                          >
                            Edit
                          </button>
                          {isEditPopupOpen && selectedExamData === exam && (
                            // eslint-disable-next-line react/jsx-pascal-case
                            <Edit_Upcoming_Exams
                              examData={selectedExamData}
                              onClose={() => {
                                toggleEditPopup();
                                setFetchKey((prevKey) => prevKey + 1);
                              }}
                              fetchKey={fetchKey}
                            />
                          )}
                        </div>
                      )}{" "}
                      {hasPermission(10014) && (
                        <div>
                          <button className="bg-[#5B6D49] text-white p-2 w-[95px] rounded-[26px]">
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-4">
        {/* Pagination */}
        <div className="flex justify-center mt-4 ">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrPrevious className="w-3 h-3" />
          </button>
          {[
            ...Array(Math.ceil(filteredExams.length / itemsPerPage)).keys(),
          ].map((pageNumber) => (
            <button
              key={pageNumber + 1}
              onClick={() => paginate(pageNumber + 1)}
              className={`px-4 py-2 mx-1 rounded-md ${
                currentPage === pageNumber + 1
                  ? "bg-primary text-white"
                  : "bg-gray-200"
              }`}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredExams.length / itemsPerPage)
            }
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrNext className="w-3 h-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Upcoming_Examinations;
